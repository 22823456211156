import React, { useState } from 'react';
import IntimacyEnhacement from './IntimacyEnhancement';
import OrganicProducts from './OrganicProducts';
import BabyCareProducts from './BabyCareproducts';
import AnimalFeedPremix from './AnimalFeedPremix';
import OralCare from './OralCare';
import NootropicProducts from './NootropicProducts';

const SpecialityProductTabs = () => {

    const tabs = {
        "NOOTROPIC PRODUCTS": <NootropicProducts />,
        "INTIMACY ENHANCEMENT": <IntimacyEnhacement />,
        "ORAL CARE": <OralCare />,
        "ORGANIC PRODUCTS": <OrganicProducts />,
        "BABY CARE PRODUCTS": <BabyCareProducts />,
        "ANIMAL FEED PREMIX": <AnimalFeedPremix />
    }

    const [ctab, setCtab] = useState("NOOTROPIC PRODUCTS");

    return (
        <>
            {/* //speciality product tab section */}
            <div className="">
                <div className="">
                    <div className="">
                        <div className='tabhead '>
                            <div className='container'>
                                <div className='row tabbutton-wrapper'>
                                    <div className='col-lg-12'>
                                        <div className='tabbutton'>
                                            {Object.keys(tabs).map((title, i) => (
                                                <div key={i} className='tabbutton-item'>
                                                    <div
                                                        className={'text-center speciality-product-names ' + (ctab === title ? 'active' : '')}
                                                        onClick={() => { setCtab(title); }}
                                                    >
                                                        {title}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='contentbutton'>
                                {tabs[ctab]}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
export default SpecialityProductTabs