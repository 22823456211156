import React from "react"
import NavigationBar from "../../../commonComponents/NavigationBar/NavigationBar"
import Footer from "../../../commonComponents/Footer/Footer"
import NDAComponent from "../../../components/NDAComponent/NDAComponent"

const NDA = () => {
    return (
        <>
            <NavigationBar customclassname="feasibility-navsection" />
            <NDAComponent />
            <Footer />
        </>
    )
}
export default NDA