import React from "react"
import NavigationBar from "../../commonComponents/NavigationBar/NavigationBar"
import Banner from "../../commonComponents/Banner/Banner"
import Footer from "../../commonComponents/Footer/Footer"
import Images from "../../commonComponents/Images/Images"
import ImgWithDescription from "../../commonComponents/ImgWithDescription/ImgWithDescription"
import PrivateLabelComponent from "../../components/PrivateLabelComponent/PrivateLabelComponent"

const PrivateLabelPage = () => {

    const data = {
        image: Images.PrivateLabelBanner,
        title: "your brand",
    }

    const data2 = {
        video: Images.PrivateLabelVideo,
        heading: "Manufacturing Capabilites",
        description: <>
            <p>Your success measures our success. We were created to bring a higher level of quality and service to the Natural Product Manufacturing Industry. Whether you want to start you own line of natural products or are seeking to expand you current stock, turn to Superior Products for a range of formulations. We are natural formulation manufactures committed to providing you with superior quality products, exceptional customer service and competitive pricing.</p>
        </>,
    }
    return (
        <>
            <NavigationBar />
            <Banner bannerImg={data.image} title={data.title} content={data.content} customClassName="privatelabel-page-banner-css" />
            <ImgWithDescription {...data2} />
            <PrivateLabelComponent />
            <Footer />
        </>
    )
}
export default PrivateLabelPage