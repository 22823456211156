import React, { useEffect, useState } from "react"
import NavigationBar from "../../commonComponents/NavigationBar/NavigationBar"
import Footer from "../../commonComponents/Footer/Footer"
import Images from "../../commonComponents/Images/Images"
import { NavLink, useLocation } from "react-router-dom"
import axios from "axios"
import { Helmet, HelmetProvider } from 'react-helmet-async';
import parse, { domToReact } from 'html-react-parser';


const VirtualProductReviewComponent = () => {

    const [product, setProduct] = useState({});
    const [bannerImages, setBannerImages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const location = useLocation();

    const { id } = location.state || { id: 1 };

    const customParser = (node, index) => {
        if (node.type === 'tag' && node.name === 'img') {
            // Custom rendering for images
            return <img key={index} src={node.attribs.src} alt={node.attribs.alt} />;
        } else if (node.type === 'tag' && node.name === 'li') {
            // Custom rendering for list items
            return <li key={index}>{domToReact(node.children, { index })}</li>;
        } else if (node.type === 'tag' && node.name === 'ul') {
            // Custom rendering for unordered lists with class name
            return <ul key={index} className="list-items">{domToReact(node.children, { index })}</ul>;
        }
        // You can add more custom parsing logic for other elements as needed
        return domToReact([node], { index });
    };

    //get single review product api integration from cms
    const apiUrl = process.env.REACT_APP_API_BASE_URL;

    const fetchSingleProduct = (id) => {
        const sinlgeReviewProductEndpoint = `/single_virtual_product/${id}`;
        const singleReviewProductUrl = `${apiUrl}${sinlgeReviewProductEndpoint}`;

        axios.get(singleReviewProductUrl).then((res) => {
            if (res.data.status === 1) {
                const productData = res.data.data.product;
                const bannerData = res.data.data.banner_images.flatMap(banner => banner.feature_images.filter(image => image.file_category === 'feature'));
                setProduct(productData);
                setBannerImages(bannerData);
                setLoading(false);
            } else {
                setProduct({});
                setBannerImages([]);
                setLoading(false);
            }
        })
            .catch((error) => {
                setError("Error fetching Single Virtual Products data.");
                setLoading(false);
            })
    };

    useEffect(() => {
        fetchSingleProduct(id);
    }, [id]);

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>{product.title}</title>
                    {product.meta_description && <meta name="description" content={product.meta_description} />}
                    {product.meta_keyword && <meta name="keywords" content={product.meta_keyword} />}
                </Helmet>
                <NavigationBar />
                <div className="product-review-page header-bottom-line-left">
                    <div className="container">
                        <div className="pxy-40">
                            <div className="">
                                <div className="row row-25">
                                    <div className="col-lg-2 col-md-3 col-sm-3">
                                        <div className="row">
                                            {loading ? (
                                                <p>Loading...</p>
                                            ) : error ? (
                                                <p>{error} </p>
                                            ) : (
                                                bannerImages.map((products, index) => (
                                                    <div key={index} className="card mb-3 otherimg-card"
                                                        onClick={() => fetchSingleProduct(products.product_id)}
                                                    >
                                                        <div className="card-body other-image-div">
                                                            <div className="virtual-img-container">
                                                                {products.file_path ? (
                                                                    <img src={products.file_path} alt={products.title} />
                                                                ) : (
                                                                    <p>No Image Available</p>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-9 col-sm-9">
                                        <div className="card product-img-card">
                                            <div className="card-body product-img-div d-flex align-items-center overflow-hidden">
                                                <div className="">
                                                    <img src={product.file_path} alt={product.name} width={"100%"} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12 col-sm-12 scroll-behaviour2">
                                        <h3 className="text-left py-20 ">{product.name}<br />
                                            <span className="text-left">{product.category_name}</span>
                                        </h3>
                                        <div className="mt-40">
                                            {product.short_description && <p>{product.short_description} </p>}
                                            {product.product_description && <p>{parse(product.product_description, { replace: customParser })}</p>}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-3 redirection-link">
                        <NavLink className="" to="/product/virtual-products">VIRTUAL PRODUCT REVIEW</NavLink>
                    </div>
                </div >
                <Footer />
            </HelmetProvider>
        </>
    )
}
export default VirtualProductReviewComponent