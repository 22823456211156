import React from "react"
import Images from "../../commonComponents/Images/Images"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import AccordianSection from "../../commonComponents/AccordianSection/AccordianSection";
import { NavLink } from "react-router-dom"

const ProductComponent = ({ customClassName, customClassName2 }) => {

    const data = {
        image1: Images.VirtualProduct,
        image2: Images.StockProduct,
        image3: Images.OralSupplement,
        image4: Images.CosmeticProduct,
        image5: Images.SpecialityProduct,

    }
    const accordianHeading = "expertise that sets us apart:"

    const accordionData = [
        {
            question: "Consistency and Standardization",
            answer: "Achieving consistency in herbal and nutraceutical formulations is a cornerstone of our expertise. We employ state-of-the-art processes and quality control measures to ensure every batch meets the highest standards. Our commitment to standardization guarantees that your private label products maintain uniformity and effectiveness."
        },
        {
            question: "Shelf Life Assurance",
            answer: "Achieving consistency in herbal and nutraceutical formulations is a cornerstone of our expertise."
        },
        {
            question: "Sustainability in Every Step",
            answer: "Achieving consistency in herbal and nutraceutical formulations is a cornerstone of our expertise. We employ state-of-the-art processes and quality control measures to ensure every batch meets the highest standards."
        },
        {
            question: "Hoslistic Support Beyond",
            answer: "Achieving consistency in herbal and nutraceutical formulations is a cornerstone of our expertise."
        },
        {
            question: "Packaging Soutions",
            answer: "Achieving consistency in herbal and nutraceutical formulations is a cornerstone of our expertise."
        },
        {
            question: "Regulatory Compliance",
            answer: "Achieving consistency in herbal and nutraceutical formulations is a cornerstone of our expertise."
        },
        {
            question: "Proof of Claims",
            answer: "Achieving consistency in herbal and nutraceutical formulations is a cornerstone of our expertise. We employ state-of-the-art processes and quality control measures to ensure every batch meets the highest standards."
        },
        {
            question: "Driving Your Brand Success",
            answer: "Achieving consistency in herbal and nutraceutical formulations is a cornerstone of our expertise."
        },

    ];

    return (
        //product module submenu card section
        <>
            <div className="card-section header-bottom-line">
                <div className={`pxy-75 ${customClassName}`}>
                    {/* <p className="mx-100 text-center">{description}</p> */}
                    <div className="row card-section-full-img virtual-product">
                        <div className="col-lg-8 col-md-12 col-sm-12">
                            <div className="row row-50 text-center card-section-full-img">
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <NavLink to="/product/virtual-products">
                                        <div className={`card  ${customClassName2}`}>
                                            <div>
                                                <div className="img-container-full">
                                                    <img src={data.image1} alt="image" width={"100%"} />
                                                </div>
                                                <div className="card-body">
                                                    <h6 className="py-20">virtual product review</h6>
                                                    <div>
                                                        <p>Quality control management procedures for supplements confirm that a finished supplements meets or exceeds our client's high - quality standards Quality control management procedures for supplements</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </NavLink>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <NavLink to="/product/stock-products">
                                        <div className={`card  ${customClassName2}`}>
                                            <div>
                                                <div className="img-container-full">
                                                    <img src={data.image2} alt="image" width={"100%"} />
                                                </div>
                                                <div className="card-body">
                                                    <h6 className="py-20 ">stock products</h6>
                                                    <div>
                                                        <p>Quality control management procedures for supplements confirm that a finished supplements meets or exceeds our client's high - quality standards Quality control management procedures for supplements</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </NavLink>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <NavLink to="/product/oral-supplements">
                                        <div className={`card  ${customClassName2}`}>
                                            <div>
                                                <div className="img-container-full">
                                                    <img src={data.image3} alt="image" width={"100%"} />
                                                </div>
                                                <div className="card-body">
                                                    <h6 className="py-20 ">oral supplements</h6>
                                                    <div>
                                                        <p>Quality control management procedures for supplements confirm that a finished supplements meets or exceeds our client's high - quality standards Quality control management procedures for supplements</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </NavLink>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <NavLink to="/product/cosmetic-products">
                                        <div className={`card  ${customClassName2}`}>
                                            <div>
                                                <div className="img-container-full">
                                                    <img src={data.image4} alt="image" width={"100%"} />
                                                </div>
                                                <div className="card-body">
                                                    <h6 className="py-20 ">cosmetic products</h6>
                                                    <div>
                                                        <p>Quality control management procedures for supplements confirm that a finished supplements meets or exceeds our client's high - quality standards Quality control management procedures for supplements</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12 col-sm-12">
                            {/* //search bar section */}
                            <div className="search-bar mb-55">
                                <label className="text-bold" htmlFor="searchInput">SEARCH</label>
                                <form class="d-flex search-form" role="search">
                                    <input class="form-control" type="search" placeholder="Enter a Keyword..." aria-label="Search" />
                                    <button class="btn search-button" type="submit">
                                        <FontAwesomeIcon className="fa-search" icon={faSearch} />
                                    </button>
                                </form>
                            </div>
                            {/* //welcome to deltas section */}
                            <div>
                                <h6 className="mb-25 text-left">welcome to deltas - your trusted partner in herbal and nutraceutical manufacturing</h6>
                                <p>
                                    At Deltas, we take pride in being recognized as one of the premier herbal and nutraceutical companies in India, specializing in private label solutions. Our commitment to excellence is evident in our ability to address the unique challenges of herbal and nutraceutical product manufacturing setting us apart as a trusted B2B partner.
                                </p>
                            </div>
                            {/* //accordian section */}
                            <AccordianSection accordionData={accordionData} heading={accordianHeading} />
                        </div>
                        {/* <div className="row">
                            <div className="col-lg-12"> */}
                        <div className="row last-card-speciality-box">
                            <NavLink to="/product/speciality-products">
                                <div className={`card px-0 ${customClassName2}`}>
                                    <div className="speciality-card-flex">
                                        <div className="col-lg-7 col-md-12 col-sm-12">
                                            <div className="img-container-full">
                                                <img className="img-fluid" src={data.image5} alt="image" width={"100%"} />
                                            </div>
                                        </div>
                                        <div className="col-lg-5 col-md-12 col-sm-12 row align-items-center">
                                            <div className="card-body">
                                                <h6 className="py-20 text-center">speciality products</h6>
                                                <div>
                                                    <p>Quality control management procedures for supplements confirm that a finished supplements meets or exceeds our client's high - quality standards Quality control management procedures for supplements</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                        {/* </div>
                        </div> */}

                    </div>
                </div>
            </div >
        </>
    )
}
export default ProductComponent