import React, { useState } from 'react'
import { NootropicContent, GainContent, Losscontent, Endurancecontent, Aphrodisiacscontent, Femininecontent, Ayurvedcontent, Adjuvantscontent, Liposomalcontent, Test } from './OralProducts'


function NootropicProducts({ customClassName }) {

  const [ctab, setCtab] = useState("Nootropic Products");
  // const [isExpanded1, setIsExpanded1] = useState(false);
  // const [isExpanded2, setIsExpanded2] = useState(false);
  // const [isExpanded3, setIsExpanded3] = useState(false);
  // const [isExpanded4, setIsExpanded4] = useState(false);
  // const [isExpanded5, setIsExpanded5] = useState(false);
  // const [isExpanded6, setIsExpanded6] = useState(false);
  // const [isExpanded7, setIsExpanded7] = useState(false);
  // const [isExpanded8, setIsExpanded8] = useState(false);
  // const [isExpanded9, setIsExpanded9] = useState(false);


  // const toggleExpanded1 = () => {
  //   setIsExpanded1(!isExpanded1);
  // };
  // const toggleExpanded2 = () => {
  //   setIsExpanded2(!isExpanded2);
  // };
  // const toggleExpanded3 = () => {
  //   setIsExpanded3(!isExpanded3);
  // };
  // const toggleExpanded4 = () => {
  //   setIsExpanded4(!isExpanded4);
  // };
  // const toggleExpanded5 = () => {
  //   setIsExpanded5(!isExpanded5);
  // };
  // const toggleExpanded6 = () => {
  //   setIsExpanded6(!isExpanded6);
  // };
  // const toggleExpanded7 = () => {
  //   setIsExpanded7(!isExpanded7);
  // };
  // const toggleExpanded8 = () => {
  //   setIsExpanded8(!isExpanded8);
  // };
  // const toggleExpanded9 = () => {
  //   setIsExpanded9(!isExpanded9);
  // };

  // setIsExpanded(false)


  const tabs = {
    // "Products Name": "",
    "Nootropic Products": <NootropicContent />,
    "Weight Gain": <GainContent />,
    "Weight Loss": <Losscontent />,
    "Endurance": < Endurancecontent />,
    "Aphrodisiacs": <Aphrodisiacscontent />,
    "Feminine Care": <Femininecontent />,
    "Advanced Ayurved": <Ayurvedcontent />,
    "Natural Adjuvants": <Adjuvantscontent />,
    "Liposomal Supplement": <Liposomalcontent />,
    "  ": <Test />,
    "   ": <Test />
  }



  return (
    <>
      {/* //oral products, product names and tab section */}
      <div className="title-description header-bottom-line">
        <div className="container">
          <div className={`pxy-40 px-0 ${customClassName}`}>
            <h3 className="text-center py-20 ">ORAL PRODUCTS</h3>
            <div className="tabsection-margin">
              <div className='contentflex row'>
                <div className='col-lg-3 col-md-12 col-sm-12 mb-3'>
                  <div className='nootropic-product-names text-left product-names bg-white pt-0'>Products Name</div>
                  <div className='tabbutton-scroll'>
                    {Object.keys(tabs).map((title, i) => {
                      const isTestComponent = title === "  " || title === "   ";
                      return (
                        <div className={`nootropic-product-names text-left ${isTestComponent ? 'product-names bg-white ' : " "}` + (ctab === title ? 'active' : '')}
                          // onClick={() => { setCtab(title) }} >{title}
                          onClick={() => {
                            if (!isTestComponent) {
                              setCtab(title);
                            }
                          }}
                        >
                          {title}
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className='col-lg-9 col-md-12 col-sm-12'>
                  {tabs[ctab]}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    </>
  )
}

export default NootropicProducts