import React from "react"
import NavigationBar from "../../../commonComponents/NavigationBar/NavigationBar"
import Banner from "../../../commonComponents/Banner/Banner"
import Footer from "../../../commonComponents/Footer/Footer"
import Images from "../../../commonComponents/Images/Images"
import ImgWithDescription from "../../../commonComponents/ImgWithDescription/ImgWithDescription"
import CosmeticProductComponent from "../../../components/ProductModuleComponents/CosmeticProductComponent"


const CosmeticProducts = () => {

    const data = {
        image: Images.CosmeticBanner,
        title: "Cosmetic product",

    }

    const data2 = {
        video: Images.CosmeticVideo,
        heading: "advance skin care & cosmetic products",
        description: "At Deltas, we specialise in developing something beyond regular cosmetics. Our range includes advanced cosmeceuticals and neurocosmetics, formulated using the latest research and cosmetic technology. Our products contain highly concentrated, active ingredients like hyaluronic acid, peptides, ceramids, and lycopene, known for their proven effectiveness. They are designed to be both effective and safe, providing immediate and noticeable results.",
    }

    return (
        <>
            <NavigationBar />
            <Banner bannerImg={data.image} title={data.title} content={data.content} customClassName="cosmeticproduct-page-banner-css" />
            <ImgWithDescription {...data2} customClassName="product-description" customClassName4="cosmetic-video"/>
            <CosmeticProductComponent />
            <Footer />
        </>
    )
}
export default CosmeticProducts