//home page
import HomePage from "../pages/HomePage/HomePage"

//company module
import AboutUsPage from "../pages/CompanyModule/AboutUsPage/AboutUsPage"
import OurTeamPage from "../pages/CompanyModule/OurTeamPage/OurTeamPage"
import RnDPage from "../pages/CompanyModule/RnDPage/RnDPage"
import FormulationExperiencePage from "../pages/CompanyModule/FormulationExperiencePage/FormulationExperiencePage"
import QualityControl from "../pages/CompanyModule/QualityControl/QualityControl"
import ScientificPublicationsPage from "../pages/CompanyModule/ScientificPublicationsPage/ScientificPublicationsPage"
import PatentsCertificationsPage from "../pages/CompanyModule/PatentsCertificationsPage/PatentsCertificationsPage"

//services module
import ServicePage from "../pages/ServiceModule/ServicePage/ServicePage"
import ResearchProjectsPage from "../pages/ServiceModule/ResearchProjectsPage/ResearchProjectsPage"
import FulfillmentServices from "../pages/ServiceModule/FulfillmentServices/FulfillmentServices"
import ClinicalTrialManufacturing from "../pages/ServiceModule/ClinicalTrialManufacturing/ClinicalTrialManufacturing"
import GeneticsTestingPage from "../pages/ServiceModule/GeneticsTestingPage/GeneticsTestingPage"

//product module
import ProductPage from "../pages/ProductModule/ProductPage/ProductPage"
import VirtualProductReviewPage from "../pages/ProductModule/VirtualProductReviewPage/VirtualProductReviewPage"
import VirtualProductReviewComponent from "../components/VirtualProductReviewComponent/VirtualProductReviewComponent"
import StockProducts from "../pages/ProductModule/StockProducts/StockProducts"
import OralSupplements from "../pages/ProductModule/OralSupplements/OralSupplements"
import CosmeticProducts from "../pages/ProductModule/CosmeticProcucts/CosmeticProcucts"
import SpecialityProducts from "../pages/ProductModule/SpecialityProducts/SpecialityProducts"
import SkinCareProdctDescription from "../components/ProductModuleComponents/StockProductComponent/SkinCareProductDescription"

//private label page
import PrivateLabelPage from "../pages/PrivateLabelPage/PrivateLabelPage"

//knowledge center module manufacturing page
import Manufacturing from "../pages/KnowledgeCenterModule/ManufacturingPage/ManufacturingPage"
import ManufacturingBlogs from "../components/ManufacturingComponent/ManufacturingBlogs"

//feasibility module
import VendorEnquiry from "../pages/Feasibility/VendorEnquiry/VendorEnquiry"
import RFQ from "../pages/Feasibility/RFQ/RFQ"
import NDA from "../pages/Feasibility/NDA/NDA"
import FAQ from "../pages/Feasibility/FAQ/FAQ"
import SampleRequest from "../pages/Feasibility/SampleRequest/SampleRequest"

//private labels
import OralSupplementsPage from "../pages/PrivateLabelProducts/OralSupplementsPage"





const routes = [
    //home page
    { path: "/", name: "homepage", element: HomePage, exact: true, },

    //company module
    { path: "/company/aboutus", name: "aboutpage", element: AboutUsPage, exact: true, },
    { path: "/company/our-team", name: "ourtemapage", element: OurTeamPage, exact: true, },
    { path: "/company/rnd", name: "r&d", element: RnDPage, exact: true, },
    { path: "/company/formulation-experience", name: "formulation experience", element: FormulationExperiencePage, exact: true, },
    { path: "/company/quality-management", name: "quality management", element: QualityControl, exact: true, },
    { path: "/company/scientific-publications", name: "scientific publication", element: ScientificPublicationsPage, exact: true, },
    { path: "/company/patents-certifications", name: "patents & certifications", element: PatentsCertificationsPage, exact: true, },

    //services module
    { path: "/service", name: "service", element: ServicePage, exact: true, },
    { path: "/service/research-projects", name: "research projects", element: ResearchProjectsPage, exact: true, },
    { path: "/service/clinical-trial-manufacturing", name: "clinical manufacturing", element: ClinicalTrialManufacturing, exact: true, },
    { path: "/service/fulfillment-services", name: "fulfillement services", element: FulfillmentServices, exact: true, },
    { path: "/service/genetics-testing", name: "genetics testing", element: GeneticsTestingPage, exact: true, },

    //product module
    { path: "/product", name: "product", element: ProductPage, exact: true, },
    { path: "/product/virtual-products", name: "virtual product review", element: VirtualProductReviewPage, exact: true, },
    { path: "/product/product-review", name: "product review", element: VirtualProductReviewComponent, exact: true, },
    { path: "/product/stock-products", name: "stock products", element: StockProducts, exact: true, },
    { path: "/product/oral-supplements", name: "oral supplemets", element: OralSupplements, exact: true, },
    { path: "/product/cosmetic-products", name: "cosmetic products", element: CosmeticProducts, exact: true, },
    { path: "/product/speciality-products", name: "speciality products", element: SpecialityProducts, exact: true, },
    { path: "/product/product-description/:id", name: "stock products", element: SkinCareProdctDescription, exact: true, },

    //private label page
    { path: "/private-label", name: "private label", element: PrivateLabelPage, exact: true, },

    //feasibility module
    { path: "/feasibility/vendor-enquiry", name: "vendor enquiry", element: VendorEnquiry, exact: true, },
    { path: "/feasibility/RFQ", name: "RFQ", element: RFQ, exact: true, },
    { path: "/feasibility/NDA", name: "NDA", element: NDA, exact: true, },
    { path: "/feasibility/Faq", name: "Faq", element: FAQ, exact: true, },
    { path: "/feasibility/sample-request", name: "sample request", element: SampleRequest, exact: true, },

    //knowledge center module manufacturing page
    { path: "/manufacturing", name: "manufacturing", element: Manufacturing, exact: true, },
    { path: "/manufacturing/blogs/:id", name: "manufacturing blogs", element: ManufacturingBlogs, exact: true, },

    //private labels products
    { path: "/oral-supplement", name: "oralsupplementspage", element: OralSupplementsPage, exact: true, },

]
export default routes