import React from "react"
import NavigationBar from "../../../commonComponents/NavigationBar/NavigationBar"
import Banner from "../../../commonComponents/Banner/Banner"
import Footer from "../../../commonComponents/Footer/Footer"
import Images from "../../../commonComponents/Images/Images"
import ImgWithDescription from "../../../commonComponents/ImgWithDescription/ImgWithDescription"
import KeyPointComponent from "../../../commonComponents/KeyPointComponent/KeyPointComponent"

const ClinicalTrialManufacturing = () => {

    const data = {
        image: Images.ClinicalManufacturingBanner,
        title: "clinical trial manufacturing",
    }

    const data2 = {
        image: Images.ClinicalTrial,
        heading: <> clinical trial manufacturing at deltas: <br /> a comprehensive approach</>,
        description: "Clinical trial product manufacturing is a critical component in the development of investigational medicinal products (IMPs). At Deltas, we understand the significance of this process in assessing the safety and efficacy of these products. Our manufacturing adheres to stringent quality control and regulatory guidelines, including AYUSH and FSSAI standards, ensuring that every product meets the highest quality benchmarks. ",
    }

    const data3 = {
        title: "Our Manufacturing Process:",
        description1: <>
            <h6 className="text-capitalize">
                Formulation Development:
            </h6>
            <div className="mb-2">
                <p><strong >Objective: </strong>Designing the formulation based on sponsor specifications. </p>
                <p><strong >Approach: </strong>Creating study drugs and matching placebos following GMP guidelines, incorporating necessary excipients, stabilizers, and other ingredients to achieve the desired properties and dosage forms in line with the sponsor's protocol.</p>
            </div>

            <h6 className="text-capitalize">
                Process Development:
            </h6>
            <div className="mb-2">
                <p><strong >Consistency and Reproducibility: </strong>Developing a manufacturing process that ensures consistent and reproducible production of the clinical trial product.</p>
            </div>
            <div className="mb-2">
                <p><strong >Optimization: </strong>Fine - tuning process parameters like mixing, granulation, drying, and packaging for optimal results.</p>
            </div>
            <h6 className="text-capitalize">
                Quality Assurance:
            </h6>
            <div className="mb-2">
                <p><strong >GMP Compliance: </strong>Adhering to Good Manufaturing Practice regulations to guarantee consistent production and control, covering facility design, equipment qualification, personnel training, documentation, and quality control.</p>
            </div>
            <h6 className="text-capitalize">
                Batch Manufacturing:
            </h6>
            <div className="mb-2">
                <p><strong >Documentation and Monitoring: </strong>Manufacturing clinical trial products in batches as per the established formulation and process, with thorough documentation and sample collection for quality control testing.</p>
            </div>
        </>,
        description2: <>
            <h6 className="text-capitalize">
                Quality Control Testing:
            </h6>
            <div className="mb-2">
                <p><strong >Evaluation: </strong>Conducting extensive tests to assess the quality, safety, and identity of the clinical trial products, including physical, wet chemistry, and microbiological tests.</p>
            </div>
            <h6 className="text-capitalize">
                Packaging and Labelling:
            </h6>
            <div className="mb-2">
                <p><strong >Regulatory Compliance: </strong>Ensuring that packaging and labelling meet study protocol and regulatory requirements.</p>
                <p><strong >Documentation and Regulatory Submission:</strong></p>
                <p><strong >Record Keeping: </strong>Manufacturing detailed documentation of the maufacturing process, product approvals, batch records.</p>
                <p><strong >Regulatory Communication: </strong>Submitting this information to regulatory authorities as part of the clinical trial application.</p>
                <p>At Deltas, we are dedicated to a comprehensive process that not only ensures the highest standards of product quality but also contributes significantly to the scientific community's understanding of medicinal efficacy and safety.</p>
            </div>
        </>,
    }

    return (
        <>
            <NavigationBar />
            <Banner bannerImg={data.image} title={data.title} content={data.content} customClassName="clinicaltrial-page-banner-css" />
            <ImgWithDescription {...data2} />
            <KeyPointComponent {...data3} customClassName="pt-0" />
            <Footer />
        </>
    )
}
export default ClinicalTrialManufacturing