import React, { useEffect, useState } from "react"
import { useNavigate, NavLink } from "react-router-dom"
import Images from "../../commonComponents/Images/Images"
import axios from "axios"

const OurProducts = () => {

    const [stockProducts, setStockProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showAllProducts, setShowAllProducts] = useState(false)
    const navigate = useNavigate();



    // const stockProducts = [
    //     {
    //         id: 1,
    //         category_name: "DIETARY SUPPLEMENTS",
    //         product_image: Images.ProductImg1,
    //         name: "Slimwin Tablet",
    //         count: <span className="font-family-latolight">60 Tablets</span>,
    //         button: "READ MORE...",
    //     },
    //     {
    //         id: 2,
    //         category_name: "PAIN RELIEVERS",
    //         product_image: Images.ProductImg2,
    //         name: "Somiwin Tablet",
    //         count: <span className="font-family-latolight">60 Tablets</span>,
    //         button: "READ MORE...",

    //     },
    //     {
    //         id: 3,
    //         category_name: "HERBAL FORMULAS",
    //         product_image: Images.ProductImg3,
    //         name: "Diawon Tablet",
    //         count: <span className="font-family-latolight">60 Tablets</span>,
    //         button: "READ MORE...",

    //     },
    //     {
    //         id: 4,
    //         category_name: "DIGESTIVE ENZYMES",
    //         product_image: Images.ProductImg4,
    //         name: "Gynowin Tablet",
    //         count: <span className="font-family-latolight">60 Tablets</span>,
    //         button: "READ MORE...",

    //     },
    //     {
    //         id: 5,
    //         category_name: "WEIGHT LOSS",
    //         product_image: Images.ProductImg5,
    //         name: "Somiwin Tablet",
    //         count: <span className="font-family-latolight">60 Tablets</span>,
    //         button: "READ MORE...",

    //     },
    //     {
    //         id: 6,
    //         category_name: "PAIN RELIEVERS",
    //         product_image: Images.ProductImg6,
    //         name: "Psorawin Tablet",
    //         count: <span className="font-family-latolight">60 Tablets</span>,
    //         button: "READ MORE...",

    //     },

    // ]






    //api integration of our stock products homepage

    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    const stockProductsEndPoint = '/fetch_products_home';
    const stockProductsUrl = `${apiUrl}${stockProductsEndPoint}`;

    const fetchStockProducts = () => {
        axios.get(stockProductsUrl).then((res) => {

            //     if (res.data.status === 0 || res.data.message === "No records found") {
            //         setStockProducts([]);
            //     } else {
            //         setStockProducts(res.data.data);
            //     }
            //     setLoading(false);
            // })
            if (res.data.status === 1) {
                // Filter the data to include only thumbnails
                const filteredData = res.data.data.filter(item => item.master_type === "product" && item.file_category === "thumbnail");
                // Set the filtered thumbnails to state
                setStockProducts(filteredData);
                setLoading(false);
            } else {
                setStockProducts([]);
                setLoading(false);
            }
        })
            .catch((error) => {
                setError("Error fetching stock products data.");
                setLoading(false);
            })
    }

    useEffect(() => {
        fetchStockProducts();
    }, []);


    const handleButtonClick = () => {
        navigate('/product/stock-products');
    };

    // const toggleShowAllProducts = () => {
    //     setShowAllProducts(!showAllProducts);
    // };

    // const calculateRows = () => {
    //     const productsPerRow = 3;
    //     return Math.ceil(stockProducts.length / productsPerRow);
    // };
    {/* {stockProducts.slice(0, showAllProducts ? stockProducts.length : 3).map((product, index) => ( */ }

    return (
        <>
            {/* //our stock products */}
            <div className="our-products header-bottom-line">
                <div className="pxy-40">
                    <div className="">
                        <h3 className="text-center py-20 ">OUR STOCK PRODUCTS</h3>
                        <div className="mt-50 container">
                            <div className="row row-30">
                                {loading ? (
                                    <p>Loading...</p>
                                ) : error ? (
                                    <p>{error}</p>
                                ) : stockProducts.length > 0 ? (
                                    stockProducts.map((product) => (
                                        <div className="col-lg-3 col-md-4 col-sm-6 mb-30" >
                                            <NavLink to={`/product/product-description/${product.id}`}>
                                                <div className='card-carousel-main our-products-hover product'>
                                                    <div className='services-carousel ' key={product.id}>
                                                        <div className="card ">
                                                            <h6 className="text-center products-title">{product.category_name}</h6>
                                                            <div className="card-body">
                                                                <div className='card-nav font-family-regular'>
                                                                    <div className="img-div product-img-div">
                                                                        <img src={product.file_path} className='carousel-card-img' width={"100%"} />
                                                                    </div>
                                                                    <p className='carousel-title text-center product-title-ht'>{product.name}</p>
                                                                    <p className='carousel-title text-center'>{product.count}</p>
                                                                    <div className="d-flex justify-content-center mt-3 product-button">
                                                                        <button className='btn'>READ MORE...</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </NavLink>
                                        </div>
                                    ))
                                ) : (
                                    <p>No stock products available.</p>
                                )}
                            </div>
                        </div>
                        {/* {calculateRows() > 1 && !showAllProducts && ( */}
                        <div className="text-center view-all mt-50">
                            <button className="btn" onClick={handleButtonClick}>VIEW ALL PRODUCTS</button>
                        </div>
                        {/* )} */}
                    </div>
                </div>
            </div >
        </>
    )
}
export default OurProducts