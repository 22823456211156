import React from "react"
import TitleWithDescription from "../../commonComponents/TitleWithDescriptionLayout/TitleWithDescriptionLayout"
import Table from "react-bootstrap/Table"

const FormulationExperience = () => {

    const data1 = {
        title: "formulation experience",
        description1: <ul> <li>Our experts, with their deep understanding of plant-derived nutraceuticals and phytoceuticals, are dedicated to leveraging the power of plants to boost health and wellness.
        </li>
            <li> Deltas offers formulations with a wide range plant-sourced phytoceuticals, encompassing herbal supplements, antioxidants polyphenols, and omega-3 fatty acids.</li>
        </ul>,
        description2: <ul>

            <li>   Our experts focus on unique ingredients combined with synergistic excipients to optimize health and nutritional benefits. Below is a list of these key functional ingredients:</li>
        </ul >

    }


    const data2 = {
        title: "organic plantation",
        description1: <ul> <li>Deltas Group seamlessly integrates pharmaceutical production with the cultivation of medicinal plants sourced from the wild, ensuring they meet organic certification criteria. We harvest these plants following strict government regulations and reforestation efforts to support ecosystem sustainability.
            Our commitment to organic medicinal plant standards is rigorous.
        </li>
        </ul>,
        description2: <ul>
            <li> Our certification, approved by APEDA, confirms our adherence to organic practices, qualifying our herbal materials for the European market. We maintain a strict protocol for harvesting and processing plants, emphasising environmental sustainability and the exclusion of synthetic chemicals.
            </li>
        </ul>

    }


    return (
        <>
            {/* //formulation experience description */}
            <TitleWithDescription title={data1.title} description1={data1.description1} description2={data1.description2} />

            {/* //table */}
            <div className="formulation-experience-table header-bottom-line">
                <div className="container">
                    <div className="pxy-40 pt-0 pb-0 px-0 tabel-div">
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Product name - Anthem</th>
                                    <th>Functional activity</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>Alpha GPC</td>
                                    <td>Neurological health</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>Bioavailable ResArgin</td>
                                    <td>Antioxidant</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>Bovine colostrum</td>
                                    <td>Gastrointestinal health</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>Capsaicin</td>
                                    <td>Anti-inflammatory</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>Fucoxanthin</td>
                                    <td>Weight Management</td>
                                </tr>
                                <tr>
                                    <td>6</td>
                                    <td>Ergothioneine</td>
                                    <td>Anti-aging</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td>Anti-diabetic</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td>Cardiovascular health</td>

                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td>Liver health</td>
                                </tr>
                                <tr>
                                    <td>7</td>
                                    <td>Ginseng extract</td>
                                    <td>General physical and mental health</td>
                                </tr>
                                <tr>
                                    <td>8</td>
                                    <td>L - Methylfolate Calcium</td>
                                    <td>Prevention of low folate levels</td>
                                </tr>
                                <tr>
                                    <td>9</td>
                                    <td>1 - Methylnicotinamide chloride</td>
                                    <td>Anti-inflammatory Anti-thrombotic</td>
                                </tr>
                                <tr>
                                    <td>10</td>
                                    <td>Nicotinamide Riboside mononucleotide</td>
                                    <td>Anti-aging Cardiovascular health Cholesterol balance General physical and mental health</td>
                                </tr>
                                <tr>
                                    <td>11</td>
                                    <td>1-Nicotinamide Mononucleotide Chloride (NMN,NAMN, (3-NMN))</td>
                                    <td>Anti-aging</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td>Cardiovascular health Cholesterol balance General physical and mental health</td>
                                </tr>
                                <tr>
                                    <td>12</td>
                                    <td>Pyridoxal-5-Phosphate</td>
                                    <td>Anti-inflammatory General physical and mental health</td>
                                </tr>
                                <tr>
                                    <td>13</td>
                                    <td>Pyrroloquinoline quinone disodium (PQQ)</td>
                                    <td>General mental health</td>
                                </tr>
                                <tr>
                                    <td>14</td>
                                    <td>Resveratrol</td>
                                    <td>Antioxidant</td>
                                </tr>
                                <tr>
                                    <td>15</td>
                                    <td>S - Equol</td>
                                    <td>Anti-aging Bone health Hormonal health and wellness</td>
                                </tr>
                                <tr>
                                    <td>16</td>
                                    <td>Tocotrienol (Alpha, Beta, Gamma, Delta)</td>
                                    <td>Antioxidant Anti-inflammatory Cardiovascular health Gastrointestinal health</td>
                                </tr>
                                <tr>
                                    <td>17</td>
                                    <td>Ubiquinol Acetate (EnQ10)</td>
                                    <td>Cardiovascular health Neuromuscular health</td>
                                </tr>
                                <tr>
                                    <td>18</td>
                                    <td>Vitamin K2-4 /Menatetrenone -4</td>
                                    <td>Bone health</td>
                                </tr>
                                <tr>
                                    <td>19</td>
                                    <td>Vitamin K2-7/ Menaquinone-7 (Microencapsulated, powder, oil)</td>
                                    <td>Arterial health</td>
                                </tr>
                                <tr>
                                    <td>20</td>
                                    <td>Vitamin K2-7/ Menaquinone-7 (Microencapsulated, powder, oil)</td>
                                    <td>Bone health</td>
                                </tr>
                                <tr>
                                    <td>21</td>
                                    <td>D- Chiroinositol</td>
                                    <td>Brain Health, panic disorder, depression, and OCD. Adjuvant to insulin function.</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td>Myo-Inositol/D-Chiro-inositol for PCOS adjuvant.</td>
                                </tr>
                                <tr>
                                    <td>22</td>
                                    <td>Andrographis paniculata and Arabinogalactan</td>
                                    <td>Kidney</td>
                                </tr>
                                <tr>
                                    <td>23</td>
                                    <td>Moringa</td>
                                    <td>Kidney</td>
                                </tr>
                                <tr>
                                    <td>24</td>
                                    <td>N-acetylcysteine (NAC)</td>
                                    <td>Kidney</td>
                                </tr>
                                <tr>
                                    <td>25</td>
                                    <td>Resveratrol</td>
                                    <td>Kidney</td>
                                </tr>
                                <tr>
                                    <td>26</td>
                                    <td>Achillea millefolium extract</td>
                                    <td>Kidney function</td>
                                </tr>
                                <tr>
                                    <td>27</td>
                                    <td>Boerhavia diffusa</td>
                                    <td>Kidney function</td>
                                </tr>
                                <tr>
                                    <td>28</td>
                                    <td>Fennel</td>
                                    <td>Anti-oxidant</td>
                                </tr>
                                <tr>
                                    <td>29</td>
                                    <td>Phyllanthus amarus</td>
                                    <td>Hepatoprotective</td>
                                </tr>
                                <tr>
                                    <td>30</td>
                                    <td>Rosmarinus officinalis</td>
                                    <td>Anti-oxidant, preservative</td>
                                </tr>
                                <tr>
                                    <td>31</td>
                                    <td>Ruta graveolens</td>
                                    <td>Anti-aging</td>
                                </tr>
                                <tr>
                                    <td>32</td>
                                    <td>Melatonin</td>
                                    <td>Sleep supplements</td>
                                </tr>
                                <tr>
                                    <td>33</td>
                                    <td>Garlic</td>
                                    <td>Immunity modulation</td>
                                </tr>
                                <tr>
                                    <td>34</td>
                                    <td>Curcumin</td>
                                    <td>Anti-inflammatory</td>
                                </tr>
                                <tr>
                                    <td>35</td>
                                    <td>Rutin</td>
                                    <td>Anti-cancer</td>
                                </tr>
                                <tr>
                                    <td>36</td>
                                    <td>Bromelin</td>
                                    <td>Anti-inflammatory</td>
                                </tr>
                                <tr>
                                    <td>37</td>
                                    <td>Urtica dioica</td>
                                    <td>Kidney function</td>
                                </tr>
                                <tr>
                                    <td>38</td>
                                    <td>Nicotinamide / Sodium Bicarbonate</td>
                                    <td>Anti-aging</td>
                                </tr>
                                <tr>
                                    <td>39</td>
                                    <td>Sylimarin, quercetin, Green Tea ECGC</td>
                                    <td>Hepatoprotective</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>


            {/* //organic plantation description */}
            <TitleWithDescription title={data2.title} description1={data2.description1} description2={data2.description2} />

        </>
    )
}
export default FormulationExperience