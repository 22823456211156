import React from "react"
import NavigationBar from "../../../commonComponents/NavigationBar/NavigationBar"
import Banner from "../../../commonComponents/Banner/Banner"
import Footer from "../../../commonComponents/Footer/Footer"
import Images from "../../../commonComponents/Images/Images"
import ManufacturingComponent from "../../../components/ManufacturingComponent/ManufacturingComponent"

const Manufacturing = () => {

    const data = {
        image: Images.ManufacturingPageBanner,
        title: "unlocking discoveries:",
        content: "exploring the frontiers of science",
    }

    return (
        <>
            <NavigationBar />
            <Banner bannerImg={data.image} title={data.title} content={data.content} customClassName="manufacturing-page-banner-css" />
            <ManufacturingComponent />
            <Footer />
        </>
    )
}
export default Manufacturing