import React, { useState } from "react"
import Images from "../../../commonComponents/Images/Images"

const data = {
    image1: Images.NootropicProduct,
    image2: Images.WeightGain,
    image3: Images.WeightLoss,
    image4: Images.Endurance,
    image5: Images.Aphrodisiacs,
    image6: Images.FeminineCare,
    image7: Images.AdvancedAyurved,
    image8: Images.NaturalAdjuvants,
    image9: Images.LiposomalFormulation,
}

export const NootropicContent = () => {
    return (
        <>
            <div className="row">

                <div className="col-lg-6 col-md-12 col-sm-12">
                    <div className="mb-20 oral-products-div">
                        <img alt="rectangle" src={data.image1} width={"100%"} />
                    </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 scroll-behaviour">
                    <h4 className="text-capitalize py-20">Innovative Nootropic Manufacturing for Distinctive Brands</h4>
                    <div className="">
                        <p >At our facility, we specialise in crafting custom Nootropic products that stand out in the market. Our formulations are designed to boost cognitive functions, including conscious awareness, mood enhancement, mental focus, and memory support, both short and long term. We take pride in creating Nootropics tailored to deliver specific benefits, enabling your brand to distinguish itself from competitors. </p>
                        <div className="mb-2">
                            <p className="text-bold">Customised Nootropic Formulations:</p>
                            <p>Our approach is to custom manufacture Nootropic products that align with your brand's unique vision and market needs.This customization ensures that your products offer distinct advantages and appeal to your target audience.</p>
                        </div>

                        <div className="mb-2">
                            <p className="text-bold">Evidence-Based Approach:</p>
                            <p>To support your product claims, we provide a technical White Paper at no additional cost. This document will include substantiated research and clinical studies, ensuring that your product is backed by scientific evidence.We carefully select the right forms and dosages of raw ingredients based on this research to ensure optimal efficacy.</p>
                        </div>
                        <div className="mb-2">
                            <p className="text-bold">Nootropic Supplement Manufacturing Expertise:</p>
                            <p>Creating Nootropic supplements is our forte. We collaborate with you to choose the most effective ingredients that resonate with your brand identity and market goals. Additionally, we offer guidance in preparing your packaging and labels to reflect the quality and uniqueness of your product.</p>
                        </div>
                        <div className="mb-2">
                            <p className="text-bold">Stand Out with Novel Nootropic Products:</p>
                            <p>Our ability to create novel Nootropic products is unmatched. This expertise provides you with an opportunity to set your brand apart in a competitive market. Contact us today to explore how our specialised Nootropic manufacturing services can elevate your brand and offer a unique proposition to your customers.</p>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export const GainContent = () => {

    return (
        <>
            <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12">
                    <div className="mb-20 oral-products-div">
                        <img alt="rectangle" src={data.image2} width={"100%"} />
                    </div>

                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 scroll-behaviour">
                    <h4 className="text-capitalize py-20">Weight gain</h4>
                    <div>
                        <div className="mb-2">
                            <p className="text-bold">Understanding Weight Gain:</p>
                            <p>Weight gain is a complex process influenced by a multitude of physiological factors. These include the energy balance equation, which encompasses dietary and caloric intake, and the management of higher basal metabolic rates (BMRs). Hormonal functions, particularly those of insulin, leptin, and ghrelin, also play significant roles, affecting appetite and fat storage. Additionally, the gut microbiota is crucial for nutrient assimilation, while psychological factors like stress, emotional eating, and conditions such as bulimia nervosa can impact eating habits and body weight. Chronic immunological diseases, including IBS, rheumatoid arthritis, and tuberculosis, further complicate this picture.<br />
                                In the realm of natural supplements, plant-based steroids and compounds are gaining attention for their potential benefits in weight gain and fitness.</p>
                        </div>

                        <div className="mb-2">
                            <p className="text-bold">Plant-Based Solutions for Weight Gain and Fitness:</p>
                            <ul className="list-items">
                                <li>Saponins: Compounds such as Dioscine, Diosgenin, and Protodioscin are notable for their effectiveness in enhancing muscle mass and physical fitness.</li>
                                <li>Phytosterols: Beta-Sitosterols, a type of phytosterol, are known for supporting endurance. They exhibit testosterone-like effects and aid in recovery.</li>
                                <li>Shilajit: This unique substance, found in the high-altitude rock crevices of the Himalayas and Caucasus mountains, is formed from the long-term decomposition of certain plants. It is believed to enhance strength, recovery, and muscle hypertrophy.</li>
                            </ul>
                        </div>
                        <div className="mb-2">
                            <p className="text-bold">At Deltas, we're at the forefront of creating innovative weight gain supplements that harness the power of these natural compounds. Our new-age solutions include:</p>
                            <ul className="list-items">
                                <li>Probiotics: Beneficial for gut health, which is crucial for proper nutrient absorption and assimilation.</li>
                                <li>Plant Peptides: These naturally occurring compounds can play a role in muscle building and overall physical health.</li>
                                <li>Phytosomes: A cutting-edge approach to increase the bioavailability of plant compounds, enhancing their effectiveness.</li>
                            </ul>
                        </div>
                        <div className="mb-2">
                            <p>Our comprehensive understanding of weight gain and the physiological factors involved, combined with our expertise in natural supplement formulation, positions us to provide effective and innovative solutions in weight gain supplements. Contact us to learn more about how our products can support your journey to improved health and fitness.
                            </p>
                        </div>


                    </div>
                </div>
            </div>
        </>
    )
}

export const Losscontent = () => {
    return (
        <>
            <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12">
                    <div className="mb-20 oral-products-div">
                        <img alt="rectangle" src={data.image3} width={"100%"} />
                    </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 scroll-behaviour">
                    <h4 className="text-capitalize py-20">Weight Loss</h4>
                    <div>
                        {/* <div className="mb-2"> */}
                        <p className="text-bold">Key Components of Our Weight Loss Supplements:</p>
                        <p>Phytochemicals and Dietary Flavonoids: Compounds like quercetin, kaempferol, and catechin, commonly found in vegetables, fruits, green tea, and herbs, play a crucial role in down-regulating adipogenesis-related transcriptional factors such as PPAR-γ, C/EBP-α, and SREBP-1. This helps inhibit adipocyte differentiation at an early stage.</p>
                        <p>Apoptosis-Inducing Phytochemicals: Phytochemicals such as esculetin, resveratrol, quercetin, genistein, capsaicin, and conjugated linoleic acids are known to induce apoptosis in maturing 3T3-L1 preadipocytes. They work by suppressing ERK1/2 phosphorylation, activating the mitochondrial pathway, stimulating AMPK, or through antioxidant activities.</p>

                        <p>Innovative Ingredients for Weight Loss: We are utilising ingredients like Fucoxanthin, ergothioneine, salicinol, and hesperidin in our weight loss formulations. These components have been selected for their potential in effectively supporting weight loss and overall metabolic health.</p>
                        {/* </div> */}

                        <p>At Deltas, we are committed to harnessing the power of natural ingredients to create effective weight loss supplements. Our approach combines cutting-edge research with traditional wisdom to develop products that not only aid in weight loss but also promote overall health and well-being. Contact us to explore our range of weight loss solutions and how they can benefit your product line.</p>


                    </div>
                </div>
            </div>
        </>
    )
}

export const Endurancecontent = () => {
    return (
        <>
            <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12">
                    <div className="mb-20 oral-products-div">
                        <img alt="rectangle" src={data.image4} width={"100%"} />
                    </div>

                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 scroll-behaviour">
                    <h4 className="text-capitalize py-20">Endurance</h4>
                    <div>
                        <div className="mb-2">
                            <p className="text-bold">Revolutionising Sports Nutrition with Natural Supplements:</p>
                            <p>Natural supplements have been integral in enhancing various health aspects, especially in the realm of endurance and sports performance. These supplements offer vital nutrients essential for energy production, recovery, and athletic excellence. Despite ongoing research into the effectiveness of herbal supplements for endurance, they can be effectively integrated into sports nutrition as pre-workout, intra-workout, and post-workout supplements.</p>
                        </div>
                        <div className="mb-2">
                            <p className="text-bold">Our Sports Nutrition Formulations:</p>
                            <p className="text-bold">Pre-Workout Essentials:</p>
                            <ul className="list-items">
                                <li>Caffeine, Beta-Alanine, Beetroot Extract, Creatine, BCAAs: These ingredients are known for boosting energy levels, enhancing endurance, and improving overall workout performance.</li>
                                <li>Tart Cherry Extract, Tribulus Terrestris, Shilajit: These compounds help increase stamina and reduce fatigue, setting the stage for an effective workout.</li>
                            </ul>
                        </div>

                        <div className="mb-2">
                            <p className="text-bold">Intra-Workout Support:
                            </p>
                            <ul className="list-items">
                                <li>Natural Salts, Electrolytes, Mixed Carbohydrates: Essential for maintaining hydration and energy levels during intense workouts.</li>
                                <li>Chia Seeds, Royal Honey, Bee Pollen, Sattu: Provide sustained energy, helping athletes perform at their best throughout their training sessions.</li>
                            </ul>
                        </div>
                        <div className="mb-2">
                            <p className="text-bold">Post-Workout Recovery:</p>
                            <ul className="list-items">
                                <li>Natural Proteins, Omega-3 Fatty Acids: Crucial for muscle recovery and reducing inflammation.</li>
                                <li>Curcumin, Adaptogens (Ashwagandha, Musli): Aid in reducing post-workout stress and boosting recovery, ensuring the body is ready for the next challenge.</li>
                            </ul>
                        </div>
                        <div className="mb-2">
                            <p>Our dedication to incorporating these natural ingredients is rooted in their historical usage and the increasing scientific evidence that underscores their benefits in boosting endurance and sports performance.</p>
                            <p>By choosing our sports nutritional formulations, you're not only embracing nature's wisdom but also leveraging modern scientific insights to enhance athletic performance and recovery. Contact us to discover how our natural sports supplements can elevate your product offerings and support the needs of athletes and fitness enthusiasts.</p>
                        </div>


                    </div>
                </div>
            </div>
        </>
    )
}

export const Aphrodisiacscontent = () => {
    return (
        <>
            <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12">
                    <div className="mb-20 oral-products-div">
                        <img alt="rectangle" src={data.image5} width={"100%"} />
                    </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 scroll-behaviour">
                    <h4 className="text-capitalize py-20">Aphrodisiacs</h4>
                    <div>

                        <div className="mb-2">
                            <p className="text-bold">Exploring the World of Herbal Aphrodisiacs:</p>
                            <p>Aphrodisiacs, substances believed to enhance sexual desire, libido, or pleasure, have been a topic of intrigue throughout history. Various natural ingredients have been heralded for their aphrodisiacal properties, though the extent of scientific backing for their effectiveness varies.</p>
                        </div>
                        <div className="mb-2">
                            <p className="text-bold">Current Research on Herbal Aphrodisiacs:</p>
                            <p>The field of scientific study surrounding herbal aphrodisiacs is continuously evolving. This research aims to understand the potential physiological mechanisms behind the traditional uses of these natural substances. Key herbal ingredients such as Withania somnifera (Ashwagandha), Panax ginseng, Tribulus terrestris, and Maca root have long been associated with enhancing sexual function.</p>
                        </div>

                        <div className="mb-2">
                            <p><span className="text-bold">Withania somnifera:</span> Known for its adaptogenic properties, this herb is studied for its role in modulating stress hormones, which could indirectly affect sexual health.</p>
                            <p><span className="text-bold">Tribulus terrestris:</span> Research into this plant focuses on its potential effects on testosterone levels, a hormone closely linked with libido.</p>
                            <p> <span className="text-bold">Maca Root:</span>This herb is being explored for its impact on hormonal balance and energy metabolism, both of which are vital for sexual health.</p>
                        </div>
                        <div className="mb-2">
                            <p>While some studies indicate these herbs may positively affect sexual function and libido, the scientific community underscores the necessity for more comprehensive research. This includes conducting well-designed clinical trials to confirm the safety and efficacy of these herbal aphrodisiacs.</p>
                            <p>As research continues to unfold, the potential of these natural substances in enhancing sexual wellness is an exciting and developing area of study. Their historical significance, coupled with emerging scientific insights, makes them a captivating subject in both traditional and modern contexts of sexual health.</p>
                        </div>


                    </div>
                </div>
            </div>
        </>
    )
}

export const Femininecontent = () => {
    return (
        <>
            <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12">
                    <div className="mb-20 oral-products-div">
                        <img alt="rectangle" src={data.image6} width={"100%"} />
                    </div>

                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 scroll-behaviour">
                    <h4 className="text-capitalize py-20">Feminine Care</h4>
                    <div>
                        <div className="mb-2">
                            <p className="text-bold">The Evolution of Feminine Care Towards Natural Solutions :</p>
                            <p>The realm of feminine care has seen a significant shift towards natural products that focus on the health and well-being of women's intimate areas. The understanding of the microbiome's crucial role in intimate health has been a game-changer, leading to the inclusion of probiotics in feminine care products. Known for their positive impact on gut health, probiotics are now being recognized for their ability to support the delicate balance of the vaginal microbiome.</p>
                        </div>
                        {/* <div className="mb-2"> */}
                        <p className="text-bold">Herbal Ingredients in Feminine Care:</p>
                        <p><span className="text-bold">Gentle and Holistic Approach:</span> Feminine care products, formulated with plant-derived ingredients, offer a gentle yet effective approach to intimate health.</p>


                        <p><span className="text-bold">Soothing Herbs:</span> Ingredients like chamomile, calendula, and aloe vera are popular choices for intimate washes and wipes. These herbs are celebrated for their soothing, anti-inflammatory properties, making them ideal for maintaining the health of sensitive intimate areas.</p>
                        <p> <span className="text-bold">Balanced pH Support: </span>These herbal products are specifically designed to preserve a balanced pH level, which is vital for the health and comfort of the intimate area.</p>
                        <p> <span className="text-bold">Antimicrobial Properties: </span>Herbs such as lavender and tea tree oil, known for their antimicrobial qualities, are incorporated to promote hygiene and protect against irritants.</p>
                        {/* </div> */}
                        <div className="mb-2">
                            <p>As awareness grows and the preference for natural alternatives gains momentum, herbal feminine care products are increasingly sought after. These products offer women a plant-based choice that is not only effective but also prioritizes the health and wellness of the female body. In an era where clean and natural solutions are more valued than ever, herbal feminine care stands out as a testament to the harmony between nature and personal health.</p>
                        </div>


                    </div>
                </div>
            </div>
        </>
    )
}

export const Ayurvedcontent = () => {
    return (
        <>
            <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12">
                    <div className="mb-20 oral-products-div">
                        <img alt="rectangle" src={data.image7} width={"100%"} />
                    </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 scroll-behaviour">
                    <h4 className="text-capitalize py-20">Advanced Ayurved</h4>
                    <div>
                        <div className="mb-2">
                            <p className="text-bold">Advanced Ayurveda: Merging Traditional Wisdom with Modern Innovation :</p>
                            <p>Advanced Ayurveda represents a blend of traditional Ayurvedic principles with the latest scientific advancements and technology. This approach enhances the precision, efficacy, and accessibility of Ayurvedic practices. Our commitment to advanced Ayurvedic manufacturing reflects a modern evolution in the production of Ayurvedic products, combining the time-honoured wisdom of ancient practices with modern technologies and strict quality standards.</p>
                        </div>

                        {/* <div className="mb-2"> */}
                        <p className="text-bold">Highlights of Advanced Ayurvedic Manufacturing:</p>
                        <p><span className="text-bold">Potency and Purity Enhancement:</span> Our manufacturing processes employ cutting-edge techniques to increase the potency and purity of Ayurvedic formulations.</p>

                        <p><span className="text-bold">Modern Facilities:</span>  We use innovative extraction methods and sophisticated equipment, underpinned by rigorous quality control, in our state-of-the-art facilities, ensuring we meet the highest standards.</p>
                        <p> <span className="text-bold">Quality Raw Materials and Traditional Principles:</span>Our focus on premium raw materials and accurate herb identification, along with adherence to traditional Ayurvedic principles, ensures both authenticity and high efficacy.</p>
                        <p> <span className="text-bold">Sustainable and Eco-Friendly Approaches: </span>Our manufacturing processes are designed to be sustainable and environmentally friendly, aligning with global environmental standards.</p>
                        {/* </div> */}
                        <div className="mb-2">
                            <p>We offer a range of Ayurvedic products that respect ancient Ayurvedic traditions while satisfying the contemporary consumer’s expectations for safety, consistency, and effectiveness. As Ayurveda gains international recognition, our advanced manufacturing plays a crucial role in preserving its authenticity and fulfilling the demands of a modern, quality-aware market. Experience the best of both ancient tradition and contemporary innovation with our Advanced Ayurveda products.</p>
                        </div>


                    </div>
                </div>
            </div>
        </>
    )
}

export const Adjuvantscontent = () => {
    return (
        <>
            <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12">
                    <div className="mb-20 oral-products-div">
                        <img alt="rectangle" src={data.image8} width={"100%"} />
                    </div>

                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 scroll-behaviour">
                    <h4 className="text-capitalize py-20">Natural Adjuvants</h4>
                    <div>
                        <div className="mb-2">
                            <p className="text-bold">The Role of Natural Adjuvants in Enhancing Health and Wellness</p>
                            <p>Natural adjuvants, including herbal extracts, vitamins, and phospholipids, are gaining recognition for their significant role in supporting various aspects of health and well-being. These elements, sourced from nature, are valued for their beneficial impact on health and as supportive components in therapeutic regimes.</p>
                        </div>

                        {/* <div className="mb-2"> */}
                        <p className="text-bold">Key Natural Adjuvants and Their Benefits:</p>
                        <p><span className="text-bold">Herbal Adjuvants:</span> Adaptogenic herbs like ashwagandha and immune-boosting echinacea represent a holistic, plant-based approach to enhancing the body's resilience. These herbs help the body adapt to stress and bolster immune system functioning.</p>

                        <p><span className="text-bold">Vitamins:</span> As essential micronutrients found in fruits and vegetables, vitamins play critical roles in numerous physiological processes, including immune function and energy metabolism.</p>
                        <p> <span className="text-bold">Phospholipids: </span>These are crucial components of cell membranes, obtained from sources like soybeans and sunflower seeds. They contribute to the cellular structure and function, playing a vital role in maintaining cell integrity.</p>

                        {/* </div> */}
                        <div className="mb-2">
                            <p>The incorporation of these natural adjuvants into health and wellness practices reflects a shift towards more holistic approaches that utilise nature's offerings. As people increasingly seek comprehensive and sustainable health solutions, the combination of herbal extracts, vitamins, and phospholipids offers a multi-dimensional foundation for promoting health, vitality, and equilibrium.</p>
                            <p>Whether used in dietary supplements or as part of a nutrient-dense diet, these natural adjuvants are key to a proactive and all-encompassing approach to health maintenance and enhancement. This trend underscores the growing appreciation for nature's potency and the desire for a more balanced, integrated approach to personal health and well-being.</p>
                        </div>


                    </div>
                </div>
            </div>
        </>
    )
}

export const Liposomalcontent = () => {
    return (
        <>
            <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12">
                    <div className="mb-20 oral-products-div">
                        <img alt="rectangle" src={data.image9} width={"100%"} />
                    </div>

                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 scroll-behaviour">
                    <h4 className="text-capitalize py-20">Liposomal Supplement</h4>
                    <div>
                        <div className="mb-2">
                            <p className="text-bold">Liposomal Formulations: Revolutionizing Nutrient Absorption and Bioavailability :</p>
                            <p>Liposomal formulations represent a breakthrough in the way nutrients are delivered to the body. By using liposomes – tiny lipid bilayer structures – these supplements encapsulate vital compounds like vitamins, antioxidants, and other bioactive substances, revolutionizing their absorption and efficacy.</p>
                        </div>
                        <div className="mb-2">
                            <p className="text-bold">How Liposomal Formulations Work:</p>
                            <ul className="list-items">
                                <li>Enhanced Protection: The liposomal encapsulation guards these nutrients as they travel through the digestive system, ensuring that they are not broken down or degraded.</li>
                                <li>Improved Absorption: Once these encapsulated nutrients reach the small intestine, they are absorbed more efficiently, leading to higher bioavailability.</li>
                                <li>Direct Cellular Entry: The liposomal design facilitates easier entry of the nutrients into cells, which is crucial for their optimal utilisation by the body.</li>
                            </ul>
                        </div>

                        <div className="mb-2">
                            <p className="text-bold">Benefits and Applications:</p>
                            <ul className="list-items">
                                <li>Versatile Delivery System: This advanced system is particularly useful for addressing issues that traditional supplements face, like poor solubility or degradation in the gastrointestinal tract.</li>
                                <li>Convenient and Efficient: Often available in liquid form, liposomal supplements offer an easy-to-consume option that enhances the body's ability to utilise the nutrients effectively.</li>
                                <li>Wide Range of Uses: From boosting immune health with vitamin C to enhancing the absorption of various essential nutrients, liposomal supplements cover a broad spectrum of health needs.
                                </li>
                            </ul>
                        </div>
                        <div className="mb-2">
                            <p>Liposomal supplements are at the forefront of nutritional science and innovation. They open new possibilities for optimising nutrient intake and maximising the health benefits of supplements, making them a valuable tool for those looking to support their health and wellness in an effective and efficient manner.
                            </p>
                        </div>


                    </div>
                </div>
            </div>
        </>
    )
}

export const Test = () => {
    return (
        <>
            <div className="row ">
                <div className="col-lg-6 col-md-12 col-sm-12">
                    <div className="mb-20 oral-products-div">
                        {/* <img alt="rectangle" src={data.image} width={"100%"} /> */}
                    </div>

                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                    {/* <h4>Liposomal Formulation</h4>
                    <p>Tabs, a common web design element, mimic the look and feel of file folders, allowing users to easily switch between different sections of content within a single page. This helps organize information, improve navigation, and enhance user experience by reducing the need to scroll through long pages or click through multiple links. By strategically using tabs, developers can create a more intuitive and efficient user interface.</p> */}
                </div>
            </div>
        </>
    )
}

