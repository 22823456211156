import React, { useState, useEffect, useRef } from "react"
import Images from "../../commonComponents/Images/Images"
import axios from "axios"
import { useLocation } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'


const paragraphStyles = {
    WebkitLineClamp: 7,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    display: '-webkit-box',
}

const GetinTouch = () => {
    const [expandedLeaders, setExpandedLeaders] = useState({});
    const [formData, setFormData] = useState({
        name: "",
        mail: "",
        message: "",
    })
    const [submitting, setSubmitting] = useState(false);
    const [errors, setErrors] = useState({});
    const location = useLocation();


    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
        clearError(name);
    };

    const clearError = (name) => {
        const newErrors = { ...errors };
        delete newErrors[name];
        setErrors(newErrors);
    };

    // handle submit of get in touch form 
    const handleSubmit = (event) => {
        event.preventDefault();
        if (validateForm()) {
            setSubmitting(true);

            // Prepare data to be sent
            const requestData = {
                name: formData.name,
                mail: formData.mail,
                message: formData.message
            };

            // API integration of vendor form submit
            const apiUrl = process.env.REACT_APP_API_BASE_URL;
            const getInTouchEndPoint = '/get_in_touch';
            const getInTouchUrl = `${apiUrl}${getInTouchEndPoint}`;

            axios.post(getInTouchUrl, requestData)
                .then((res) => {
                    if (res.data.status === 1 && res.data.code === 200) {
                        // Reset form after successful submission
                        setFormData({
                            name: "",
                            mail: "",
                            message: "",
                        });
                        resetForm();
                        toast.success('Form submitted successfully!');
                    } else {
                        console.error("Error:", res.data.message);
                        toast.error('Form submission failed.');
                    }
                })
                .catch((error) => {
                    console.error("Error:", error);
                    toast.error('Form submission failed.');
                })

                .finally(() => {
                    setSubmitting(false);
                });
        }
    };

    const validateForm = () => {
        let formIsValid = true;
        const newErrors = {};

        // Check if any field is empty
        Object.keys(formData).forEach((key) => {
            if (!formData[key]) {
                newErrors[key] = "This field is required.";
                formIsValid = false;
            }
        });

        // Check email format
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(formData.mail)) {
            newErrors.mail = "Please enter a valid email address.";
            formIsValid = false;
        }

        setErrors(newErrors);
        return formIsValid;
    };

    const resetForm = () => {
        setFormData({
            name: "",
            mail: "",
            message: "",
        });
    };

    const ref = useRef(null)

    // useEffect(() => {
    //     if (ref.current) {
    //         console.log(ref.current.scrollHeight, ref.current.clientHeight)
    //         setShowReadMoreBtn(ref.current.scrollHeight !== ref.current.clientHeight)
    //     }
    // }, [ref.current])

    const toggleReadMore = (id) => {
        setExpandedLeaders((prevState) => ({
            ...prevState,
            [id]: !prevState[id],
        }));
    };

    const leaders = [
        {
            id: 1,
            image: Images.Chairman,
            leadership: "CHAIRMAN",
            name: "mr. Babuulal Jain",
            describe: "The visionary behind Deltas Group of companies, Mr. Babuulal Jain, founded the conglomerate in 2001. Under his leadership, the company expanded its horizons, delving into diverse fields from Herbal Plantations and Pharmaceutical Marketing to Real Estate and Direct Selling. Apart from his business pursuits, Mr. Jain's philanthropic spirit shines through as a trustee of the 68 Akshar Jinalaya Temple and a Goshala, which has been operating since 2009 under the Srimati Sundaribai w/o Kundanmal Ji Charitable Trust. His contributions to the business world have earned him numerous national accolades.",
            showButton: false,
        },
        {
            id: 2,
            image: Images.ManagingDirector,
            leadership: "Managing Director",
            name: "Dr. Aasin Maurya",
            describe: <>Dr. Aasin Maurya, a recognized Ayurvedic Medicine practitioner, embarked on his research journey in 2001 with the Department of Endocrinology at Seth GS Medical College and Government Hospital, Mumbai. His interdisciplinary approach soon led him to delve into cancer compounds research in Eastern Europe. Throughout his career, Dr. Maurya has been at the forefront of phytochemical research, accomplishing 75 clinical research projects and over 150 product developments. With several patents and over 30 research publications under his belt, he has cemented his reputation in the pharmaceutical world.<br />
                Since 2012, Dr. Maurya has spearheaded the R&D team and the private label division of the Deltas Group. He has developed organic-certified animal nutritional products for European markets. Dr. Maurya firmly believes in the untapped potential of natural products and their impact on our wellbeing.<br /> Dr. Aasin Maurya's passion for Ayurvedic medicine led him to significant research roles and, eventually, a leadership position at Deltas Group. As a pivotal player in several start-ups, Dr. Maurya honed his ability to communicate effectively with investors, understand market dynamics, and craft compelling value propositions from product inception to go-to-market strategies.<br />Dr. Maurya reflects, “Natural products hold a vast scope in therapeutics and nutrition. They interact with our body's inherent intelligence, laying pathways for enhanced wellbeing. As science advances, we delve deeper into these natural wonders and their profound impact on us.
            </>,
            showButton: true,
        },
        {
            id: 3,
            image: Images.Director1,
            leadership: "Director",
            name: "Mr. Suresh Jain",
            describe: "Mr. Suresh Jain has been an integral part of Deltas Pharma, serving as Director of Finance since 2002. His leadership acumen is evident in the staggering growth of Toptime, a direct selling start-up he founded. Under his guidance, Toptime has instilled a culture of personalised leadership development, marking its impressive ascent in the industry.",
            showButton: false,

        },
        {
            id: 4,
            image: Images.Director2,
            leadership: "Director",
            name: "Mr. Milan Jain",
            describe: "Since joining Deltas Pharma in 2014, Mr. Milan Jain has been instrumental in streamlining operations at the pharmaceutical manufacturing unit in Haridwar. With a knack for supply chain management, compliance, and public relations, he offers invaluable support to our private label clientele, ensuring their needs are met with precision and efficiency.",
            showButton: false,

        },
    ]

    useEffect(() => {
        const id = location.hash.replace('#', '');
        if (id) {
            const element = document.getElementById(id);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);

    return (
        <>
            {/* //chairman description */}
            <div className="container">
                <div className="pxy-40">
                    {/* //our team description */}
                    {/* <div>
                        <div className="header-bottom-line">
                            <h3 class="text-center py-20">Our Team:</h3>
                        </div>
                        <div className="mt-70">
                            <div className="header-bottom-line-left">
                                <div className="row row-50">
                                    <div className="col-12">
                                        <h4 className="text-left py-20">Hello and Welcome to Deltas!</h4>
                                        <p className="mt-28 text-justify">Greetings! I genuinely appreciate you taking the time to explore our website. Whether this is your inaugural visit or you're an old companion, I trust you'll find our content both relevant and straightforward.</p>
                                        <p>At the heart of Deltas, we are deeply passionate about natural product development and the vast realm of the white label business. Through this platform, I aim to offer you a clear perspective on our services and to share the diligent strides we're making in our domain.</p>
                                        <p>Dive in, explore our offerings, familiarise yourself with our recent projects, meet the dedicated folks behind Deltas, and should you have questions, our FAQ section is well-equipped to assist.</p>
                                        <p>Building meaningful relationships is fundamental to us. If there's anything you'd like to discuss, seek clarity on, or just share, please don’t hesitate to get in touch. My team and I prioritise ensuring that your experience with us is both informative and seamless.</p>
                                        <p>Thank you for your interest and time. Stay in the loop for regular updates, and I personally look forward to fostering a fruitful association with you.</p>
                                        <p>With sincere appreciation, </p>
                                        <p>Aasin Maurya<br />
                                            Managing Director
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    {/* //leader description */}
                    <div>
                        <div className="header-bottom-line">
                            <h3 class="text-center py-20">Meet Our Leadership</h3>
                        </div>
                        <div className="mt-70">
                            <div className="chairman-description header-bottom-line-left">
                                <div className="">
                                    {leaders.map((leader, index) => (
                                        <div className="row row-75" id={leader.id}>
                                            <div className="col-lg-6 col-md-12 col-sm-12">
                                                <div className="img-container mb-20">
                                                    <img src={leader.image} alt="chairmanImg" width={"100%"} />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12">
                                                <h4 className="">{leader.leadership}</h4>
                                                <h3 className="text-left py-20 ">{leader.name}</h3>
                                                <p className="mt-28 text-justify"
                                                    // ref={index === 0 ? ref : null} style={(index === 0 && !isOpen) ? paragraphStyles : null}>
                                                    //     {leader.describe}
                                                    style={expandedLeaders[leader.id] ? {} : paragraphStyles}>
                                                    {leader.describe}
                                                </p>
                                                {leader.showButton && <div className="mt-3">
                                                    {/* <button className="btn" onClick={() => setIsOpen(!isOpen)}>{isOpen ? "READ LESS..." : "READ MORE..."}</button> */}
                                                    <button onClick={() => toggleReadMore(leader.id)} className="btn">{expandedLeaders[leader.id] ? 'READ LESS...' : 'READ MORE...'}</button>
                                                </div>}

                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* //get in touch section */}
                    <div className="get-in-touch mt-70">
                        <form className="px-35"
                            onSubmit={handleSubmit}
                        >
                            <h4 className="py-20">Get IN TOUCH</h4>
                            <div className="row row-5">
                                <div className="col-lg-6 col-md-12 col-sm-12">
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        <label>First Name</label>
                                        <input
                                            type="text"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleChange}
                                        />
                                        {errors.name && <p className="error-message mx-3">{errors.name}</p>}
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        <label>Email </label>
                                        <input
                                            type="email"
                                            name="mail"
                                            value={formData.mail}
                                            onChange={handleChange}
                                        />
                                        {errors.mail && <p className="error-message mx-3">{errors.mail}</p>}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12 col-sm-12">
                                    <label>Message</label>
                                    <textarea
                                        type="text"
                                        name="message"
                                        value={formData.message}
                                        onChange={handleChange}
                                    />
                                    {errors.message && <p className="error-message mx-3">{errors.message}</p>}
                                </div>
                            </div>
                            <div className="mt-30 text-left">
                                <button className="btn" type="submit" disabled={submitting}> {submitting ? "Submitting..." : "SUBMIT"}</button>
                            </div>
                        </form>
                    </div>
                </div>
                <ToastContainer />
            </div>
        </>
    )
}
export default GetinTouch