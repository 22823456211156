import React from "react"
import Images from "../../commonComponents/Images/Images"
import Slider from "react-slick"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { NavLink } from "react-router-dom"

const LeadershipTeam = () => {
    const leadershipImages = [
        {
            id: 1,
            Image: Images.LeadershipTeam1,
            title: "Mr. Babulalji Jain",
            description: "Chairmen",
            news: "READ MORE...",
        },
        {
            id: 2,
            Image: Images.LeadershipTeam2,
            title: "Dr. Aasin Maurya",
            description: "Managing Director",
            news: "READ MORE...",
        },
        {
            id: 3,
            Image: Images.LeadershipTeam3,
            title: "Mr. Suresh Jain",
            description: "Director",
            news: "READ MORE...",
        },
        {
            id: 4,
            Image: Images.LeadershipTeam4,
            title: "Mr. Milan Jain",
            description: "Director",
            news: "READ MORE...",
        },

    ]


    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: false,        // Enable autoplay
        autoplaySpeed: 1500,
        centerMode: false,
        // prevArrow: (
        //     <img src={Images.leftarrowShort} id='leftArrow' />
        // ),
        // nextArrow: (
        //     <img src={Images.rightarrowShort} id='rightArrow' />
        // )
        responsive: [
            {
                breakpoint: 1030,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 2,
                    prevArrow: <FontAwesomeIcon icon={faChevronLeft} />,
                    nextArrow: <FontAwesomeIcon icon={faChevronRight} />,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    prevArrow: <FontAwesomeIcon icon={faChevronLeft} />,
                    nextArrow: <FontAwesomeIcon icon={faChevronRight} />,
                }
            },
        ],
    };

    return (
        <>
            {/* //team leaders */}
            <div className="services header-bottom-line">
                <div className="pxy-40">
                    <div className="">
                        <h3 className="text-center py-20 ">LEADERSHIP TEAM</h3>
                        <div className="py-40">
                            <div className='card-carousel-main container'>
                                <Slider {...settings}>
                                    {
                                        leadershipImages.map((obj) => (
                                            <div className='carousel-card-main' key={obj.id}>

                                                <div className='carousel-card-nav'>
                                                    <div className="">
                                                        <img src={obj.Image} className='carousel-card-img m-auto' width={"80%"} />
                                                    </div>
                                                    <div className="carousel-content text-center leaderships">
                                                        <h6 className='carousel-title mb-0 doctors-names'>{obj.title}</h6>
                                                        <span className='carousel-title'>{obj.description}</span>
                                                        <NavLink to={`/company/our-team#${obj.id}`}>
                                                            <div className="mt-3">
                                                                <button className='btn'>{obj.news}</button>
                                                            </div>
                                                        </NavLink>

                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        </>
    )
}
export default LeadershipTeam