import React from "react"
import NavigationBar from "../../../commonComponents/NavigationBar/NavigationBar"
import Banner from "../../../commonComponents/Banner/Banner"
import Footer from "../../../commonComponents/Footer/Footer"
import Images from "../../../commonComponents/Images/Images"
import ImgWithDescription from "../../../commonComponents/ImgWithDescription/ImgWithDescription"
import KeyPointComponent from "../../../commonComponents/KeyPointComponent/KeyPointComponent"

const FulfillmentServices = () => {

    const data = {
        image: Images.FulfillmentServicesBanner,
        title: "fulfillment services",
    }

    const data2 = {
        image: Images.FulfillmentService,
        heading: <>  deltas fulfillment services: <br /> where expertise meets integrity</>,
        description: "Deltas stands out in the industry, not just for our extensive expertise but also for our commitment to traditional values and integrity. We are renowned for our personal, hands-on approach, always prioritizing what is best for our clients.",
    }

    const data3 = {
        title: <>tailored fulfillment solutions for your<br /> dietary and cosmetic brands.</>,
        description1: <>
            <h6 className="text-capitalize">
                Our Promise to You:
            </h6>
            <div className="mb-2">
                <p>Choosing Deltas for your fulfillment needs comes with a guarantee of satisfaction. In an industry often marred by subpar customer service, we differentiate ourselves by genuinely caring about your success. Our team is dedicated to ensuring that every interaction and every service we provide aligns with your business goals and contributes positively to your growth.</p>
            </div>
            <h6 className="text-capitalize">
                Why Choose Deltas?
            </h6>
            <div className="mb-2">
                <p><strong >Personalised Service: </strong>Experience a hands-on approach that values each client's unique needs.</p>
            </div>
            <div className="mb-2">
                <p><strong >Industry Expertise: </strong>Benefit from our in-depth knowledge and experience in fulfillment services.</p>
            </div>
            <div className="mb-2">
                <p><strong >Commitment to Integrity: </strong>Trust in a partner that upholds traditional values and ethical practices.</p>
            </div>
            <div className="mb-2">
                <p><strong >Focus on Your Success: </strong>Collaborate with a team that is as invested in your success as you are.</p>
            </div>
        </>,
        description2: <>
            <h6 className="text-capitalize">
                Our fulfillment Services Include:
            </h6>
            <div className="">
                <ul className="list-items">
                    <li>Comprehensive handling of your products from warehousing to delivery.</li>
                    <li>Efficient inventory management ensures timely stock availability.</li>
                    <li>Managing with your transport partner or providing our set of transporters of your competitive selection.</li>
                    <li>Providing necessary documents for transport and compliances purpose.</li>
                    <li>Advanced order processing systems for accurate and prompt dispatch.</li>
                    <li>Seamless integration with your sales channels for a smooth operation.</li>
                </ul>
            </div>
            <div className="mb-2">
                <p>In choosing Deltas, you're not just selecting service provider; you're gaining a partner committed to seeing your business thrive. Our dedication to excellence in customer service and our deep-rooted values make us the ideal choice for business seeking a reliable and caring fulfillment partner.</p>
            </div>
        </>,
    }

    return (
        <>
            <NavigationBar />
            <Banner bannerImg={data.image} title={data.title} content={data.content} customClassName="fulfillmentservice-page-banner-css" />
            <ImgWithDescription {...data2} />
            <KeyPointComponent {...data3} customClassName="pt-0" />
            <Footer />
        </>
    )
}
export default FulfillmentServices