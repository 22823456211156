import React, { useRef, useEffect, useState } from 'react';
import Images from '../../commonComponents/Images/Images';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';


const HomeBanner = () => {
    const videoRef1 = useRef(null);
    const videoRef2 = useRef(null);
    const [isPlaying1, setIsPlaying1] = useState(false);
    const [isPlaying2, setIsPlaying2] = useState(false);
    const data = Images.HomePageBanner
    const navigate = useNavigate();



    const handleTogglePlay1 = () => {
        if (videoRef1.current) {
            setIsPlaying1(!isPlaying1); // Toggle play/pause state for video 1
            if (isPlaying1) {
                videoRef1.current.pause();
            } else {
                videoRef1.current.play().catch(error => {
                    console.error('Play failed:', error);
                });
            }
        }
    };

    const handleTogglePlay2 = () => {
        setIsPlaying2(!isPlaying2); // Toggle play/pause state for video 2
        if (videoRef2.current) {
            if (isPlaying2) {
                videoRef2.current.pause();
            } else {
                videoRef2.current.play().catch(error => {
                    console.error('Play failed:', error);
                });
            }
        }
    };


    useEffect(() => {
        if (videoRef1.current) {
            videoRef1.current.pause();
        }
        if (videoRef2.current) {
            videoRef2.current.pause();
        }
    }, []);

    const handleclick = () => {
        navigate('/company/aboutus');
    }

    return (
        //home page banner section
        <div className='row'>
            <div className='col-lg-8 col-md-12 col-sm-12 p-0'>
                <div className='home-banner-text'>
                    <img src={data} alt="banner" width={"100%"} />
                    <div className='home-banner-text-btn'>
                        <h1>WELCOME TO DELTAS PHARMA</h1>
                        <h4 className='mb-25 header-section'>Decoding the Natural Adjuvants</h4>
                        <button className='btn' onClick={handleclick}>LEARN MORE...</button>
                    </div>
                </div>
            </div>
            <div className='col-lg-4 col-md-12 col-sm-12 p-0'>
                <div className="videBanContent row position-relative">
                    <>
                        <video ref={videoRef1}
                            className="embed-responsive-item embed-responsive"
                            loop
                            muted={true}
                            playsInline
                            width={"100%"}
                            height={""}
                            onClick={() => {
                                setIsPlaying1(!isPlaying1);
                                if (!isPlaying1 && videoRef1.current) {
                                    videoRef1.current.play().catch(error => {
                                        console.error('Play failed:', error);
                                    });
                                } else if (isPlaying1 && videoRef1.current) {
                                    videoRef1.current.pause();
                                }
                            }}
                            onPlay={() => setIsPlaying1(true)}
                            onPause={() => setIsPlaying1(false)}
                        >
                            <source src={Images.Video1} type="video/mp4" />
                            Your browser does not support the video tag.

                        </video>
                        <div className='videoTwosec'>
                            <button onClick={handleTogglePlay1} className={`btn position-absolute ${isPlaying1 ? 'hidden' : ''}`} style={{ top: '25%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                                <FontAwesomeIcon icon={faPlay} />
                            </button>
                        </div>
                    </>
                    <div className="videoTwosec col-lg-12 col-md-12 col-sm-12 position-relative">
                        <div className="embed-responsive embed-responsive-16by9">
                            <video
                                ref={videoRef2}
                                className="embed-responsive-item embed-responsive"
                                loop
                                muted={true}
                                playsInline
                                width={"100%"}
                                height={""}
                                onClick={() => {
                                    setIsPlaying2(!isPlaying2);
                                    if (!isPlaying2 && videoRef2.current) {
                                        videoRef2.current.play().catch(error => {
                                            console.error('Play failed:', error);
                                        });
                                    } else if (isPlaying2 && videoRef2.current) {
                                        videoRef2.current.pause();
                                    }
                                }}
                                onPlay={() => setIsPlaying2(true)}
                                onPause={() => setIsPlaying2(false)}
                                autoPlay
                            >
                                <source src={Images.CosmeticVideo} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                            <button onClick={handleTogglePlay2} className={`btn position-absolute ${isPlaying2 ? 'hidden' : ''}`}>
                                <FontAwesomeIcon icon={faPlay} />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default HomeBanner;