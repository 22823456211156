import React, { useEffect, useState } from "react"
import Slider from "react-slick"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft, faArrowRight, } from "@fortawesome/free-solid-svg-icons"
import axios from "axios"

const Testimonials = () => {

    const [testimonials, setTestimonials] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // const testimonials = [
    //     {
    //         id: 1,
    //         image: Images.TestimonialProfile,
    //         testimonial: "Everyone from the pharmacists to the cashiers is so kind and knowledgeable. They even offered after hours weekend delivery, which was such a blessing! Pharmaton always understands how to support its customers and give back to our area, which is more than we all could ask for. Thank you for what you do!",
    //         name: "Dr.Aasin Mourya",
    //         title: "Regular Customer",
    //     },
    //     {
    //         id: 2,
    //         image: Images.TestimonialProfile,
    //         testimonial: "Everyone from the pharmacists to the cashiers is so kind and knowledgeable. They even offered after hours weekend delivery, which was such a blessing! Pharmaton always understands how to support its customers and give back to our area, which is more than we all could ask for. Thank you for what you do!",
    //         name: "Dr.Aasin Mourya",
    //         title: "Regular Customer",
    //     },
    // ]

    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,        // Enable autoplay
        autoplaySpeed: 1500,
        centerMode: false,
        responsive: [
            {
                breakpoint: 2560,
                settings: {
                    slidesToShow: 1,
                    prevArrow: <FontAwesomeIcon icon={faArrowLeft} />,
                    nextArrow: <FontAwesomeIcon icon={faArrowRight} />,
                }
            },
            {
                breakpoint: 1030,
                settings: {
                    slidesToShow: 1,
                    prevArrow: <FontAwesomeIcon icon={faArrowLeft} />,
                    nextArrow: <FontAwesomeIcon icon={faArrowRight} />,
                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 1,
                    prevArrow: <FontAwesomeIcon icon={faArrowLeft} />,
                    nextArrow: <FontAwesomeIcon icon={faArrowRight} />,
                }
            },
        ],
    }

    // api integration of testimonial 
    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    const testimonalEndPoint = '/testimonials_data';
    const testimonialUrl = `${apiUrl}${testimonalEndPoint}`;

    const fetchTestimonials = () => {

        axios.get(testimonialUrl).then((res) => {
            // console.log("result1000", res.data.data)
            if (res.data.status === 0 || res.data.message === "No records found") {
                setTestimonials([]);
            } else {
                setTestimonials(res.data.data);
            }
            setLoading(false);
        })
            .catch((error) => {
                setError("Error fetching testimonals data.");
                setLoading(false);
            })
    }

    useEffect(() => {
        fetchTestimonials();
    }, []);


    return (
        <>
            {/* //testimonials */}
            <div className="testimonials header-bottom-line">
                <div className="container">
                    <div className="pxy-40">
                        <h3 className="text-center py-20 ">TESTIMONIALS</h3>
                        <div className="">

                            <div className='card-carousel-main'>
                                {loading ? (
                                    <p>Loading...</p>
                                ) : error ? (
                                    <p>{error}</p>
                                ) : testimonials.length === 1 ? (
                                    <div className="row align-items-center mt-70 testimonial-carousel">
                                        <div className="col-lg-4 col-md-4 col-sm-12">
                                            <div className="img-div testimonail-img-div">
                                                <img
                                                    src={testimonials[0].image}
                                                    alt={testimonials[0].name}
                                                    width={"80%"}
                                                    style={{ margin: "auto" }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-8 col-md-8 col-sm-12">
                                            <div className="mb-25">
                                                <div className="left-quote-icon">"</div>
                                                <p className="carousel-paragraph">{testimonials[0].testimonial}</p>
                                            </div>
                                            <div className="testimonials-customer-details">
                                                <h6 className="doctors-names">{testimonials[0].name}</h6>
                                                <p>{testimonials[0].title}</p>
                                            </div>
                                        </div>
                                    </div>
                                ) : (

                                    <Slider {...settings}>
                                        {
                                            testimonials.map((testimonial) => (
                                                <>
                                                    <div className="row align-items-center mt-70 testimonial-carousel" key={testimonial.id}>
                                                        {/* <div className="col-lg-1 col-md-1 col-sm-1"></div> */}
                                                        <div className="col-lg-4 col-md-4 col-sm-12">
                                                            <div className="img-div testimonail-img-div">
                                                                <img src={testimonial.image} alt={testimonial.name} width={"80%"} style={{ margin: "auto" }} />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-8 col-md-8 col-sm-12">
                                                            <div className="mb-25">
                                                                <div className="left-quote-icon">"</div>
                                                                <p className='carousel-paragraph'>{testimonial.testimonial}</p>
                                                            </div>
                                                            <div className="testimonials-customer-details">
                                                                <h6 className="doctors-names">{testimonial.name}</h6>
                                                                <p>{testimonial.title}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            ))
                                        }
                                    </Slider>
                                )}
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
export default Testimonials