import React from "react"
import ImgWithDescription from "../../../commonComponents/ImgWithDescription/ImgWithDescription"
import Images from "../../../commonComponents/Images/Images"

const OralCare = ({ customClassName, title, titleDescription2 }) => {

    const data1 = {
        image: Images.OralCare1,
        heading: "Oral care",
        description: <><p>Innovative Oral Care Solutions from Deltas. At Deltas, we combine our vast expertise, deep knowledge, and experienced team to bring advanced manufacturing techniques to the oral care sector. Our unwavering dedication is to promote 'Dental Health for All', achieved by producing superior-quality dental care products that harness the power of natural ingredients.</p>
        </>,
    }

    const data2 = {
        image: Images.OralCare2,
        description: <><p>Our Herbal Toothpaste Manufacturing Expertise:<br /> Diverse Product Range: We offer a wide array of oral care solutions. This includes natural teeth whitening toothpastes, neem toothpaste, Miswak toothpaste, and polyherbal tooth cleansing gels. Each product is carefully formulated to cater to various dental needs and preferences. </p>
        </>,
        titleDescription2: <>Comprehensive Oral Hygiene Products: Our range extends beyond toothpaste. We also produce mouthwashes, mouth fresheners, mouth sprays, and a full lineup of Ayurvedic Herbal Oral Hygiene Products, embracing the holistic approach to oral health. Innovative Formulations: Our formulations span across different types, including Herbal, Gel-based, Clear Gel, and SLS Free toothpastes. We prioritise clean ingredients in all our products, ensuring they are safe, effective, and environmentally friendly.<br />At Deltas, we are committed to revolutionising oral care through natural and herbal products. Our extensive range not only meets the varied needs of consumers but also aligns with our philosophy of promoting dental health through nature-derived, effective solutions. Join us in embracing a new era of oral care that respects both health and nature.</>,
    }

    return (
        <>
            <ImgWithDescription {...data1} />

            {/* //right side image of oral care products section */}

            <div className="service header-bottom-line">
                <div className="container">
                    <div className="pxy-0">
                        <div className="service-description-card">
                            <div className="row mx-auto">
                                <div className={`col-lg-6 col-md-12 col-sm-12 service-description ${customClassName}`}>
                                    {title && <h3 className="text-left py-20">{title}</h3>}
                                    <p>{data2.description}</p>
                                </div>
                                <div className="col-lg-6 col-md-12 col-sm-12 service-image-card">
                                    <div className="">
                                        <img src={data2.image} alt="image" width={"100%"} />
                                    </div>
                                </div>
                            </div>
                            {data2.titleDescription2 && <p className="mt-40">{data2.titleDescription2}</p>}
                        </div>
                    </div>
                </div>
            </div>

            {/* //product range */}
            <div className="oral-care-range">
                <div className="container">
                    <div className="pxy-40 px-0">
                        <h3 className="text-left text-capitalize py-20">product range</h3>
                        <div className="">
                            <div className="row">
                                <div className="col-lg-4 col-md-12 col-sm-12">
                                    <p>
                                        <ol start="01">
                                            <li>Fluoride or non - fluoride toothpastes</li>
                                            <li>White or Opaque toothpastes</li>
                                            <li>Gel Toothpaste</li>
                                            <li>Whitening toothpastes</li>
                                            <li>Day & Night Toothpaste</li>
                                            <li>Kids Toothpastes</li>
                                        </ol>
                                    </p>
                                </div>
                                <div className="col-lg-4 col-md-12 col-sm-12">
                                    <p>
                                        <ol start="07">
                                            <li>Baking Soda Toothpastes</li>
                                            <li>Beadlet based Gel toothpaste</li>
                                            <li>Probiotic Toothpaste</li>
                                            <li>Sparkling texture toothpastes</li>
                                            <li>Toothpaste with Microbeads</li>
                                            <li>Herbal toothpaste</li>
                                        </ol>
                                    </p>
                                </div>
                                <div className="col-lg-4 col-md-12 col-sm-12">
                                    <p>
                                        <ol start="13">
                                            <li>Sensitive Toothpastes</li>
                                            <li>Neem Toothpaste</li>
                                            <li>Miwak Toothpaste</li>
                                            <li>Charcoal Toothpaste</li>
                                            <li>Turmeric Curcumin Toothpaste</li>
                                            <li>SLC Free Toothpaste</li>
                                        </ol>
                                    </p>
                                </div>
                            </div >
                        </div >
                    </div >
                </div >
            </div >

        </>
    )
}
export default OralCare