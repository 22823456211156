import React, { useState, useEffect, useRef } from "react"
import Form from 'react-bootstrap/Form'
import axios from "axios"
import { ToastContainer, toast } from 'react-toastify'


const SampleRequestComponent = () => {

    const [submitting, setSubmitting] = useState(false);
    const [errors, setErrors] = useState({});
    const [dosage, setDosage] = useState([]);
    const [formulation, setFormulation] = useState([]);

    const [formData, setFormData] = useState({
        first_name: "",
        last_name: "",
        company: "",
        mail: "",
        phone_number: "",
        website_url: "",
        product_category: "",
        quantity_units: "",
        formulation_id: "",
        dosage: "",
        order_details: "",
        status: "1"
    })

    const refs = {
        first_name: useRef(null),
        last_name: useRef(null),
        company: useRef(null),
        mail: useRef(null),
        phone_number: useRef(null),
        website_url: useRef(null),
        product_category: useRef(null),
        quantity_units: useRef(null),
        formulation_id: useRef(null),
        dosage: useRef(null),
        order_details: useRef(null),
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const apiUrl = process.env.REACT_APP_API_BASE_URL;

                const dosageListEndPoint = '/get_dosage';
                const formulationListEndPoint = '/get_formulation';

                const dosageListUrl = `${apiUrl}${dosageListEndPoint}`;
                const formulationListUrl = `${apiUrl}${formulationListEndPoint}`;

                const [dosageResponse, formulationResponse] = await Promise.all([
                    axios.get(dosageListUrl),
                    axios.get(formulationListUrl)
                ]);

                if (dosageResponse.data.status === 1 && dosageResponse.data.code === 200) {
                    setDosage(dosageResponse.data.data);
                } else {
                    console.error("Error fetching dosages:", dosageResponse.data.message);
                }

                if (formulationResponse.data.status === 1 && formulationResponse.data.code === 200) {
                    setFormulation(formulationResponse.data.data);
                } else {
                    console.error("Error fetching formulations:", formulationResponse.data.message);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);


    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
        clearError(name);
    };

    const handleSelectChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
        clearError(name);
    };

    const clearError = (name) => {
        const newErrors = { ...errors };
        delete newErrors[name];
        setErrors(newErrors);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (validateForm()) {
            setSubmitting(true);

            // Prepare data to be sent
            const requestData = {
                first_name: formData.first_name,
                last_name: formData.last_name,
                company: formData.company,
                mail: formData.mail,
                phone_number: formData.phone_number,
                website_url: formData.website_url,
                product_category: formData.product_category,
                quantity_units: formData.quantity_units,
                formulation_id: formData.formulation_id,
                dosage: formData.dosage,
                order_details: formData.order_details,
            };

            // API integration of sample request form submit
            const apiUrl = process.env.REACT_APP_API_BASE_URL;
            const sampleRequestEndPoint = '/sample_request';
            const sampleRequestUrl = `${apiUrl}${sampleRequestEndPoint}`;

            axios.post(sampleRequestUrl, requestData)
                .then((res) => {
                    if (res.data.status === 1 && res.data.code === 200) {
                        setFormData({
                            first_name: "",
                            last_name: "",
                            company: "",
                            mail: "",
                            phone_number: "",
                            website_url: "",
                            product_category: "",
                            quantity_units: "",
                            formulation_id: "",
                            dosage: "",
                            order_details: "",
                        });
                        resetForm();
                        toast.success('Form submitted successfully!');
                    } else {
                        console.error("Error:", res.data.message);
                        toast.error('Form submission failed.');
                    }
                })
                .catch((error) => {
                    console.error("Error:", error);
                    toast.error('Form submission failed.');
                    setSubmitting(false);
                })
                .finally(() => {
                    setSubmitting(false);
                });
        }
    };

    useEffect(() => {
        if (Object.keys(errors).length > 0) {
            const firstErrorField = Object.keys(errors)[0];
            if (firstErrorField && refs[firstErrorField]) {
                refs[firstErrorField].current.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [errors, refs]);

    const validateForm = () => {
        let formIsValid = true;
        const newErrors = {};

        // Check if any field is empty
        Object.keys(formData).forEach((key) => {
            if (!formData[key]) {
                newErrors[key] = "This field is required.";
                formIsValid = false;
            }
        });

        // Check if select fields are empty
        const checkSelectField = (fieldName) => {
            if (!formData[fieldName]) {
                newErrors[fieldName] = "Please select an option from the drop-down menu.";
                formIsValid = false;
            }
        };

        checkSelectField('product_category');
        checkSelectField('quantity_units');
        checkSelectField('formulation_id');
        checkSelectField('dosage');

        // Check phone number format
        const phoneRegex = /^\d{10}$/;
        if (!phoneRegex.test(formData.phone_number)) {
            newErrors.phone_number = "Please enter a valid phone number.";
            formIsValid = false;
        }

        // Check email format
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(formData.mail)) {
            newErrors.mail = "Please enter a valid email address.";
            formIsValid = false;
        }

        setErrors(newErrors);
        return formIsValid;
    };

    const resetForm = () => {
        setFormData({
            first_name: "",
            last_name: "",
            company: "",
            mail: "",
            phone_number: "",
            website_url: "",
            product_category: "",
            quantity_units: "",
            formulation_id: "",
            dosage: "",
            order_details: "",
        });
    };

    return (
        <>
            <div className="sample-request header-bottom-line">
                <div className="container">
                    <div className="pxy-40">
                        <h3 className="text-center py-20 ">sample request</h3>
                        <div className="mt-70">
                            <div className="row">
                                <div className="col-lg-1">
                                </div>
                                <div className="col-lg-10 vendor-enquiry-form">
                                    <p className="text-bold font-size-28">
                                        Your Contact Information
                                    </p>
                                    <form onSubmit={handleSubmit} className="mt-40">
                                        <div className="row row-5">
                                            <div className="col-lg-6 col-md-12 col-sm-12">
                                                <label>First Name*</label>
                                                <input
                                                    type="text"
                                                    name="first_name"
                                                    value={formData.first_name}
                                                    onChange={handleChange}
                                                    ref={refs.first_name}
                                                />
                                                {errors.first_name && <p className="error-message">{errors.first_name}</p>}
                                            </div>

                                            <div className="col-lg-6 col-md-12 col-sm-12">
                                                <label>Last Name*</label>
                                                <input
                                                    type="text"
                                                    name="last_name"
                                                    value={formData.last_name}
                                                    onChange={handleChange}
                                                    ref={refs.last_name}
                                                />
                                                {errors.last_name && <p className="error-message">{errors.last_name}</p>}
                                            </div>

                                            <div className="col-lg-6 col-md-12 col-sm-12">
                                                <label>Company Name*</label>
                                                <input
                                                    type="text"
                                                    name="company"
                                                    value={formData.company}
                                                    onChange={handleChange}
                                                    ref={refs.company}
                                                />
                                                {errors.company && <p className="error-message">{errors.company}</p>}

                                            </div>

                                            <div className="col-lg-6 col-md-12 col-sm-12">
                                                <label>Email* </label>
                                                <input
                                                    type="email"
                                                    name="mail"
                                                    value={formData.mail}
                                                    onChange={handleChange}
                                                    ref={refs.mail}

                                                />
                                                {errors.mail && <p className="error-message">{errors.mail}</p>}

                                            </div>

                                            <div className="col-lg-6 col-md-12 col-sm-12">
                                                <label>Phone Number*</label>
                                                <input
                                                    type="tel"
                                                    name="phone_number"
                                                    value={formData.phone_number}
                                                    onChange={handleChange}
                                                    ref={refs.phone_number}

                                                />
                                                {errors.phone_number && <p className="error-message">{errors.phone_number}</p>}
                                            </div>

                                            <div className="col-lg-6 col-md-12 col-sm-12">
                                                <label>Website URL</label>
                                                <input
                                                    type="url"
                                                    name="website_url"
                                                    value={formData.website_url}
                                                    onChange={handleChange}
                                                    ref={refs.website_url}

                                                />
                                                {errors.website_url && <p className="error-message">{errors.website_url}</p>}
                                            </div>


                                            <div className="col-lg-6 col-md-12 col-sm-12">
                                                <label>Product Category</label>

                                                <Form.Select
                                                    name="product_category"
                                                    aria-label="Default select example"
                                                    defaultValue="select"
                                                    onChange={handleSelectChange}
                                                    ref={refs.product_category}
                                                >
                                                    <option value="select">Please Select</option>
                                                    <option value="option1">Option 1</option>
                                                    <option value="option2">Option 2</option>
                                                    <option value="option3">Option 3</option>
                                                </Form.Select>
                                                {errors.product_category && <p className="error-message">{errors.product_category}</p>}

                                            </div>

                                            <div className="col-lg-6 col-md-12 col-sm-12">
                                                <label>Quantity Units</label>

                                                <Form.Select
                                                    name="quantity_units"
                                                    aria-label="Default select example"
                                                    defaultValue="select"
                                                    onChange={handleSelectChange}
                                                    ref={refs.quantity_units}
                                                >
                                                    <option value="select">Please Select</option>
                                                    <option value="option1">Option 1</option>
                                                    <option value="option2">Option 2</option>
                                                    <option value="option3">Option 3</option>
                                                </Form.Select>
                                                {errors.quantity_units && <p className="error-message">{errors.quantity_units}</p>}

                                            </div>

                                            <div className="col-lg-6 col-md-12 col-sm-12">
                                                <label>Formulation Type</label>

                                                <Form.Select
                                                    name="formulation_id"
                                                    aria-label="Default select example"
                                                    defaultValue="select"
                                                    onChange={handleSelectChange}
                                                    ref={refs.formulation_id}
                                                >
                                                    <option value="color:gray">Please Select</option>
                                                    {formulation.map(formulation => (
                                                        <option key={formulation.id} value={formulation.id}>{formulation.formulation_name}</option>
                                                    ))}
                                                </Form.Select>
                                                {errors.formulation_id && <p className="error-message">{errors.formulation_id}</p>}

                                            </div>

                                            <div className="col-lg-6 col-md-12 col-sm-12">
                                                <label>Dosage</label>

                                                <Form.Select
                                                    name="dosage"
                                                    aria-label="Default select example"
                                                    defaultValue="select"
                                                    onChange={handleSelectChange}
                                                    ref={refs.dosage}
                                                >
                                                    <option value="color:gray">Please Select</option>
                                                    {dosage.map(dosage => (
                                                        <option key={dosage.id} value={dosage.id}>{dosage.dosage_name}</option>
                                                    ))}
                                                </Form.Select>
                                                {errors.dosage && <p className="error-message">{errors.dosage}</p>}

                                            </div>

                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                <label>Order Details*</label>
                                                <textarea
                                                    type="text"
                                                    placeholder="Include as many details as possible about order here"
                                                    name="order_details"
                                                    value={formData.order_details}
                                                    onChange={handleChange}
                                                    ref={refs.order_details}

                                                />
                                                {errors.order_details && <p className="error-message">{errors.order_details}</p>}

                                            </div>
                                        </div>
                                        <div className="mt-30 text-center">
                                            <button className="btn" type="submit" disabled={submitting}>{submitting ? "Submitting..." : "SUBMIT"}</button>
                                        </div>
                                    </form>
                                </div>
                                <div className="col-lg-1">
                                </div>
                            </div>
                        </div>
                    </div>
                    <ToastContainer />
                </div>
            </div>
        </>
    )
}
export default SampleRequestComponent