//heder logo
import LogoRed from "../../assets/images/svg/LogoRed.svg"
//footer logo
import LogoWhite from "../../assets/images/svg/LogoWhite.svg"
import FooterCertificationLogo1 from "../../assets/images/svg/FooterCertificationLogo1.svg"
import FooterCertificationLogo2 from "../../assets/images/svg/FooterCertificationLogo2.svg"
import FooterCertificationLogo3 from "../../assets/images/svg/FooterCertificationLogo3.svg"
import FooterCertificationLogo4 from "../../assets/images/svg/FooterCertificationLogo4.svg"
import FooterCertificationLogo5 from "../../assets/images/svg/FooterCertificationLogo5.svg"
import FooterCertificationLogo6 from "../../assets/images/svg/FooterCertificationLogo6.svg"
import watsap from "../../assets/images/svg/watsap.svg"
import fb from "../../assets/images/svg/fb.svg"
import insta from "../../assets/images/svg/insta.svg"
import twitter from "../../assets/images/svg/twitter.svg"
import youtube from "../../assets/images/svg/youtube.svg"
import events from "../../assets/images/png/events.jpg"
import articles from "../../assets/images/png/articles.jpg"

//home page images
import HomePageBanner from "../../assets/images/webp/HomePageBanner.png"
import Video1 from "../../assets/videos/Video1.mov"
import CosmeticVideo from "../../assets/videos/CosmeticVideo.mp4"
import AboutOurProducts from "../../assets/images/webp/AboutOurProducts.webp"
import ServiceImg1 from "../../assets/images/webp/Serviceimg1.webp"
import Serviceimg2 from "../../assets/images/webp/Serviceimg2.webp"
import Serviceimg3 from "../../assets/images/webp/Serviceimg3.webp"
import Serviceimg4 from "../../assets/images/webp/Serviceimg4.webp"
import ProductImg1 from "../../assets/images/webp/ProductImg1.webp"
import ProductImg2 from "../../assets/images/webp/ProductImg2.webp"
import ProductImg3 from "../../assets/images/webp/ProductImg3.webp"
import ProductImg4 from "../../assets/images/webp/ProductImg4.webp"
import ProductImg5 from "../../assets/images/webp/ProductImg5.webp"
import ProductImg6 from "../../assets/images/webp/ProductImg6.webp"
import ProductImg7 from "../../assets/images/webp/ProductImg7.webp"
import PrivateLabelImg1 from "../../assets/images/webp/PrivateLabelImg1.webp"
import PrivateLabelImg2 from "../../assets/images/webp/PrivateLabelImg2.webp"
import PrivateLabelImg3 from "../../assets/images/webp/PrivateLabelImg3.webp"
import TestimonialProfile from "../../assets/images/png/TestimonialProfile.png"

//about us page
import AboutUsBanner from "../../assets/images/webp/AboutUsBanner.webp"
import Aboutus from "../../assets/images/webp/Aboutus.webp"
import LeadershipTeam1 from "../../assets/images/webp/LeadershipTeam1.webp"
import LeadershipTeam2 from "../../assets/images/webp/LeadershipTeam2.webp"
import LeadershipTeam3 from "../../assets/images/webp/LeadershipTeam3.webp"
import LeadershipTeam4 from "../../assets/images/webp/LeadershipTeam4.webp"
import ThumbsUp from "../../assets/images/svg/ThumbsUp.svg"
import QualifiedEmployee from "../../assets/images/svg/QualifiedEmployee.svg"
import OnlineSupport from "../../assets/images/svg/OnlineSupport.svg"
import PaymentMethods from "../../assets/images/svg/PaymentMethods.svg"

//Our team page
import Chairman from "../../assets/images/webp/Chairman.webp"
import ManagingDirector from "../../assets/images/webp/ManagingDirector.webp"
import Director1 from "../../assets/images/webp/Director1.webp"
import Director2 from "../../assets/images/webp/Director2.webp"
import OurTeamBanner from "../../assets/images/webp/OurTeamBanner.webp"

//RnD page
import RnDBanner from "../../assets/images/webp/RnDBanner.webp"
import haffkine from "../../assets/images/svg/haffkine.svg"
import CSIR from "../../assets/images/svg/CSIR.svg"
import anthem from "../../assets/images/svg/anthem.svg"
import healthcare from "../../assets/images/svg/healthcare.svg"
import ourRnD1 from "../../assets/images/webp/ourRnD1.webp"
import ourRnD2 from "../../assets/images/webp/ourRnD2.webp"
import ourRnD3 from "../../assets/images/webp/ourRnD3.webp"
import ourRnD4 from "../../assets/images/webp/ourRnD4.webp"

//formulationexperience page
import FormulationExperienceBanner from "../../assets/images/webp/FormulationExperienceBanner.webp"
import formulationexperience1 from "../../assets/images/svg/formulationexperience1.svg"
import formulationexperience2 from "../../assets/images/svg/formulationexperience2.svg"
import formulationexperience3 from "../../assets/images/svg/formulationexperience3.svg"
import formulationexperience4 from "../../assets/images/svg/formulationexperience4.svg"

//qualityconrol page
import QualityControlBanner from "../../assets/images/webp/QualityControlBanner.webp"
import QualityControl1 from "../../assets/images/svg/QualityControl1.svg"
import QualityControl2 from "../../assets/images/svg/QualityControl2.svg"
import QualityControl3 from "../../assets/images/svg/QualityControl3.svg"
import QualityControl4 from "../../assets/images/svg/QualityControl4.svg"
import QualityAssurance1 from "../../assets/images/svg/QualityAssurance1.svg"
import QualityAssurance2 from "../../assets/images/svg/QualityAssurance2.svg"
import QualityAssurance3 from "../../assets/images/svg/QualityAssurance3.svg"
import QualityAssurance4 from "../../assets/images/svg/QualityAssurance4.svg"

//scientific publications page
import ScientificPublicationsBanner from "../../assets/images/webp/ScientificPublicationsBanner.webp"
import scientificpublications1 from "../../assets/images/jpeg/scientificpublications1.jpg"
import scientificpublications2 from "../../assets/images/jpeg/scientificpublications2.jpg"
import scientificpublications3 from "../../assets/images/jpeg/scientificpublications3.jpg"
import scientificpublications4 from "../../assets/images/jpeg/scientificpublications4.jpg"
import scientificpublications5 from "../../assets/images/jpeg/scientificpublications5.jpg"
// import Scientific_Publications_pdf from "../../assets/pdf/Scientific_Publications_pdf.pdf"
import JournalHerbalMedicine from "../../assets/pdf/JournalHerbalMedicine.pdf"
import AnnalsPhytomedicine from "../../assets/pdf/AnnalsPhytomedicine.pdf"
import BeneficialMicrobes from "../../assets/pdf/BeneficialMicrobes.pdf"
import Dermatologyvernology from "../../assets/pdf/Dermatologyvernology.pdf"
import AnnalsPhytomedicine2 from "../../assets/pdf/AnnalsPhytomedicine2.pdf"
import BeneficialMicrobes2 from "../../assets/pdf/BeneficialMicrobes2.pdf"


//patents and certificates page
import PatentsCertificatesBanner from "../../assets/images/webp/PatentsCertificatesBanner.webp"
import patentsCertificates from "../../assets/images/jpeg/patentsCertificates.jpg"
import patent1 from "../../assets/pdf/PTPatentCertificate.pdf"
import patent2 from "../../assets/pdf/RELIEVINGPAINFROMJOINYSBONESMETHODTHEREOF.pdf"
import patent3 from "../../assets/pdf/TREATMENTOFDIABETES.pdf"
import patent4 from "../../assets/pdf/TREATMENTOFPSORIASIS.pdf"

//certificates
import certificate1 from "../../assets/pdf/GMP.pdf"
import certificate2 from "../../assets/pdf/FSSAI.pdf"
import certificate3 from "../../assets/pdf/UAE.pdf"
import certificate4 from "../../assets/pdf/ecocert.pdf"
import certificate5 from "../../assets/pdf/indocert.pdf"
import certificate6 from "../../assets/pdf/AditiCert.pdf"
import certificate7 from "../../assets/pdf/WHOGMP.pdf"
import certificate8 from "../../assets/pdf/ISO.pdf"


//service page images
import ServiceBanner from "../../assets/images/webp/ServiceBanner.webp"
import pioneeringExcellence from "../../assets/images/jpeg/pioneeringExcellence.jpg"
import service1 from "../../assets/images/svg/service1.svg"
import service2 from "../../assets/images/svg/service2.svg"
import service3 from "../../assets/images/svg/service3.svg"
import service4 from "../../assets/images/svg/service4.svg"
import creation from "../../assets/images/svg/creation.svg"
import manufacturing from "../../assets/images/svg/manufacturing.svg"
import packaging from "../../assets/images/svg/packaging.svg"
import brandDesign from "../../assets/images/svg/brandDesign.svg"
import formulation from "../../assets/images/svg/formulation.svg"
import solutions from "../../assets/images/svg/solutions.svg"
import ResearchProject from "../../assets/images/jpeg/ResearchProject.jpg"
import ClinicalTrialManufacturing from "../../assets/images/jpeg/ClinicalTrialManufacturing.jpg"
import FulfillmentServices from "../../assets/images/jpeg/FulfillmentServices.jpg"
import meeting from "../../assets/images/svg/meeting.svg"
import labelling from "../../assets/images/svg/labelling.svg"
import branding from "../../assets/images/svg/branding.svg"
import design from "../../assets/images/svg/design.svg"


//research projects page images
import ResearchProjectsBanner from "../../assets/images/webp/ResearchProjectsBanner.webp"
import PioneeringFuture from "../../assets/images/jpeg/PioneeringFuture.jpg"

//clinical manufacturing page images
import ClinicalManufacturingBanner from "../../assets/images/webp/ClinicalManufacturingBanner.webp"
import ClinicalTrial from "../../assets/images/jpeg/ClinicalTrial.jpg"

//fulfillment services page images
import FulfillmentServicesBanner from "../../assets/images/webp/FulfillmentServicesBanner.webp"
import FulfillmentService from "../../assets/images/jpeg/FulfillmentService.jpg"

//genetics testing
import Trigenome_comprehensive_brochure from "../../assets/pdf/Trigenome_comprehensive_brochure.pdf"
import Trigenome_Nedimap_brochure from "../../assets/pdf/Trigenome_Nedimap_brochure.pdf"
import Trigenome_Kids_brochure from "../../assets/pdf/Trigenome_Kids_brochure.pdf"
import GeneticsTestingBanner from "../../assets/images/webp/GeneticsTestingBanner.webp"
import StateofArt1 from "../../assets/images/png/StateofArt1.png"
import StateofArt2 from "../../assets/images/png/StateofArt2.png"
import StateofArt3 from "../../assets/images/png/StateofArt3.png"
import StateofArt4 from "../../assets/images/png/StateofArt4.png"
import StateofArt5 from "../../assets/images/png/StateofArt5.png"
import StateofArt6 from "../../assets/images/png/StateofArt6.png"
import StateofArt7 from "../../assets/images/png/StateofArt7.png"
import StateofArt8 from "../../assets/images/png/StateofArt8.png"
import StateofArt9 from "../../assets/images/png/StateofArt9.png"
import Comprehensive from "../../assets/images/jpeg/Comprehensive.jpg"
import Medimap from "../../assets/images/jpeg/Medimap.jpg"
import Kids from "../../assets/images/jpeg/Kids.jpg"
import ExtendedTest1 from "../../assets/images/jpeg/ExtendedTest1.jpg"
import ExtendedTest2 from "../../assets/images/jpeg/ExtendedTest2.jpg"
import ExtendedTest3 from "../../assets/images/jpeg/ExtendedTest3.jpg"
import ExtendedTest4 from "../../assets/images/jpeg/ExtendedTest4.jpg"
import ExtendedTest5 from "../../assets/images/jpeg/ExtendedTest5.jpg"
import ExtendedTest6 from "../../assets/images/jpeg/ExtendedTest6.jpg"
import SalivaCollectedMethodVideo from "../../assets/videos/SalivaCollectedMethodVideo.mp4"
import LabTourVideo from "../../assets/videos/LabTourVideo.mp4"
import SampleTriGenome from "../../assets/videos/SampleTriGenome.mp4"


// product page
import VirtualProductBanner from "../../assets/images/webp/VirtualProductBanner.webp"
import VirtualProduct from "../../assets/images/webp/VirtualProduct.webp"
import StockProduct from "../../assets/images/webp/StockProduct.webp"
import OralSupplement from "../../assets/images/webp/OralSupplement.webp"
import CosmeticProduct from "../../assets/images/webp/CosmeticProduct.webp"
import SpecialityProduct from "../../assets/images/webp/SpecialityProduct.webp"

//virtual product review page
import Product1 from "../../assets/images/jpeg/Product1.jpg"
import Product2 from "../../assets/images/jpeg/Product2.jpg"
import Product3 from "../../assets/images/jpeg/Product3.jpg"
import Product4 from "../../assets/images/jpeg/Product4.jpg"
import HighProteinImg from "../../assets/images/jpeg/HighProteinImg.jpg"
import Detox from "../../assets/images/jpeg/Detox.jpg"
import Tonic from "../../assets/images/jpeg/Tonic.jpg"
import Vtone from "../../assets/images/jpeg/Vtone.jpg"

//stock product page
import StockProductBanner from "../../assets/images/webp/StockProductBanner.webp"
import HealthCareExperts from "../../assets/images/webp/HealthCareExperts.webp"
import SkinCareProduct from "../../assets/images/jpeg/SkinCareProduct.jpg"

//oral supplement product page
import OralSupplementBanner from "../../assets/images/webp/OralSupplementBanner.webp"
import oralSupplementVideo from "../../assets/videos/oralSupplementVideo.mov"
import customerDistribution from "../../assets/images/svg/customerDistribution.svg"

//cosmetic products page
import CosmeticBanner from "../../assets/images/webp/CosmeticBanner.webp"
import pureActiveIngredient from "../../assets/images/svg/pureActiveIngredient.svg"
import mineralOil from "../../assets/images/svg/mineralOil.svg"
import skinFriendly from "../../assets/images/svg/skinFriendly.svg"
import cruetlyFree from "../../assets/images/svg/cruetlyFree.svg"
import OurSpeciality1 from "../../assets/images/jpeg/OurSpeciality1.jpg"
import OurSpeciality2 from "../../assets/images/jpeg/OurSpeciality2.jpg"
import OurSpeciality3 from "../../assets/images/jpeg/OurSpeciality3.jpg"
import OurSpeciality4 from "../../assets/images/jpeg/OurSpeciality4.jpg"
import CleanFormulations from "../../assets/images/jpeg/CleanFormulations.jpg"
import PersonalCare from "../../assets/images/jpeg/PersonalCare.jpg"
import SensitiveSkincare from "../../assets/images/jpeg/SensitiveSkincare.jpg"
import HairCare from "../../assets/images/jpeg/HairCare.jpg"
import NaturalProduct from "../../assets/images/jpeg/NaturalProduct.jpg"
import Essentials from "../../assets/images/jpeg/Essentials.jpg"

//speciality product page 
import SpecialityProductBanner from "../../assets/images/webp/SpecialityProductBanner.webp"
import NootropicProduct from "../../assets/images/jpeg/NootropicProduct.jpg"
import WeightGain from "../../assets/images/jpeg/WeightGain.jpg"
import WeightLoss from "../../assets/images/jpeg/WeightLoss.jpg"
import Endurance from "../../assets/images/jpeg/Endurance.jpg"
import Aphrodisiacs from "../../assets/images/jpeg/Aphrodisiacs.jpg"
import FeminineCare from "../../assets/images/jpeg/FeminineCare.jpg"
import AdvancedAyurved from "../../assets/images/jpeg/AdvancedAyurved.jpg"
import NaturalAdjuvants from "../../assets/images/jpeg/NaturalAdjuvants.jpg"
import LiposomalFormulation from "../../assets/images/jpeg/LiposomalFormulation.jpg"

import IntimacyEnhancementHer from "../../assets/images/jpeg/IntimacyEnhancementHer.jpg"
import IntimacyEnhancementHim from "../../assets/images/jpeg/IntimacyEnhancementHim.jpg"
import OrganicProducts from "../../assets/images/webp/OrganicProducts.webp"
import AnimalFeedPremix from "../../assets/images/jpeg/AnimalFeedPremix.jpg"
import BabyCareImg1 from "../../assets/images/jpeg/BabyCareImg1.jpg"
import BabyCareImg2 from "../../assets/images/jpeg/BabyCareImg2.jpg"
import OralCare1 from "../../assets/images/jpeg/OralCare1.jpg"
import OralCare2 from "../../assets/images/jpeg/OralCare2.jpg"

//private label page
import PrivateLabelBanner from "../../assets/images/webp/PrivateLabelBanner.webp"
import PrivateLabelVideo from "../../assets/videos/PrivateLabelVideo.mov"
import ManufacturingCapabilities from "../../assets/images/jpeg/ManufacturingCapabilities.jpg"

//manufacturing page
import ManufacturingPageBanner from "../../assets/images/webp/ManufacturingPageBanner.webp"
import ManufacturingBlog from "../../assets/images/jpeg/ManufacturingBlog.jpg"
import Label from "../../assets/images/jpeg/Label.jpg"
import View from "../../assets/images/jpeg/View.jpg"
import Message from "../../assets/images/jpeg/Message.jpg"

//manufacturing blogs page
import ProfileLogo from "../../assets/images/jpeg/ProfileLogo.jpg"
import TimeStampLogo from "../../assets/images/jpeg/TimeStampLogo.jpg"
import ManufacturingBlog2 from "../../assets/images/jpeg/ManufacturingBlog2.jpg"
import FacebookImg from "../../assets/images/svg/FacebookImg.svg"
import TwitterImg from "../../assets/images/svg/TwitterImg.svg"
import GoogleImg from "../../assets/images/svg/GoogleImg.svg"
import DisplayPicture from "../../assets/images/svg/DisplayPicture.svg"
import BlogArticles from "../../assets/images/jpeg/BlogArticles.jpg"
import Reply from "../../assets/images/svg/Reply.svg"

//products (private labels)
import oralPowders from "../../assets/images/webp/oralPowders.webp"
import oralTablets from "../../assets/images/webp/oralTablets.webp"
import oralChemicals from "../../assets/images/webp/oralChemicals.webp"
import oralDelivery from "../../assets/images/webp/oralDelivery.webp"


const Images = {
    //header logo
    LogoRed: LogoRed,

    //footer logo
    LogoWhite: LogoWhite,
    FooterCertificationLogo1: FooterCertificationLogo1,
    FooterCertificationLogo2: FooterCertificationLogo2,
    FooterCertificationLogo3: FooterCertificationLogo3,
    FooterCertificationLogo4: FooterCertificationLogo4,
    FooterCertificationLogo5: FooterCertificationLogo5,
    FooterCertificationLogo6: FooterCertificationLogo6,
    watsap: watsap,
    fb: fb,
    insta: insta,
    twitter: twitter,
    youtube: youtube,
    articles: articles,
    events: events,

    //home page images
    HomePageBanner: HomePageBanner,
    Video1: Video1,
    CosmeticVideo: CosmeticVideo,
    AboutOurProducts: AboutOurProducts,
    ServiceImg1: ServiceImg1,
    Serviceimg2: Serviceimg2,
    Serviceimg3: Serviceimg3,
    Serviceimg4: Serviceimg4,
    ProductImg1: ProductImg1,
    ProductImg2: ProductImg2,
    ProductImg3: ProductImg3,
    ProductImg4: ProductImg4,
    ProductImg5: ProductImg5,
    ProductImg6: ProductImg6,
    ProductImg7: ProductImg7,
    PrivateLabelImg1: PrivateLabelImg1,
    PrivateLabelImg2: PrivateLabelImg2,
    PrivateLabelImg3: PrivateLabelImg3,
    TestimonialProfile: TestimonialProfile,

    //aboutus page
    AboutUsBanner: AboutUsBanner,
    Aboutus: Aboutus,
    LeadershipTeam1: LeadershipTeam1,
    LeadershipTeam2: LeadershipTeam2,
    LeadershipTeam3: LeadershipTeam3,
    LeadershipTeam4: LeadershipTeam4,
    ThumbsUp: ThumbsUp,
    QualifiedEmployee: QualifiedEmployee,
    OnlineSupport: OnlineSupport,
    PaymentMethods: PaymentMethods,

    //our team page
    OurTeamBanner: OurTeamBanner,
    Chairman: Chairman,
    ManagingDirector: ManagingDirector,
    Director1: Director1,
    Director2: Director2,

    ourRnD1: ourRnD1,
    ourRnD2: ourRnD2,
    ourRnD3: ourRnD3,
    ourRnD4: ourRnD4,


    //RnD page
    RnDBanner: RnDBanner,
    haffkine: haffkine,
    CSIR: CSIR,
    anthem: anthem,
    healthcare: healthcare,

    //formulation experience page
    FormulationExperienceBanner: FormulationExperienceBanner,
    formulationexperience1: formulationexperience1,
    formulationexperience2: formulationexperience2,
    formulationexperience3: formulationexperience3,
    formulationexperience4: formulationexperience4,

    //quality control
    QualityControlBanner: QualityControlBanner,
    QualityControl1: QualityControl1,
    QualityControl2: QualityControl2,
    QualityControl3: QualityControl3,
    QualityControl4: QualityControl4,
    QualityAssurance1: QualityAssurance1,
    QualityAssurance2: QualityAssurance2,
    QualityAssurance3: QualityAssurance3,
    QualityAssurance4: QualityAssurance4,

    //scientific publication page
    ScientificPublicationsBanner: ScientificPublicationsBanner,
    scientificpublications1: scientificpublications1,
    scientificpublications2: scientificpublications2,
    scientificpublications3: scientificpublications3,
    scientificpublications4: scientificpublications4,
    scientificpublications5: scientificpublications5,
    // Scientific_Publications_pdf: Scientific_Publications_pdf,
    JournalHerbalMedicine: JournalHerbalMedicine,
    AnnalsPhytomedicine: AnnalsPhytomedicine,
    BeneficialMicrobes: BeneficialMicrobes,
    Dermatologyvernology: Dermatologyvernology,
    AnnalsPhytomedicine2: AnnalsPhytomedicine2,
    BeneficialMicrobes2: BeneficialMicrobes2,


    //patents and certificates images
    PatentsCertificatesBanner: PatentsCertificatesBanner,
    patentsCertificates: patentsCertificates,
    patent1: patent1,
    patent2: patent2,
    patent3: patent3,
    patent4: patent4,

    //certificates
    certificate1: certificate1,
    certificate2: certificate2,
    certificate3: certificate3,
    certificate4: certificate4,
    certificate5: certificate5,
    certificate6: certificate6,
    certificate7: certificate7,
    certificate8: certificate8,


    //service page images
    ServiceBanner: ServiceBanner,
    pioneeringExcellence: pioneeringExcellence,
    service1: service1,
    service2: service2,
    service3: service3,
    service4: service4,
    creation: creation,
    manufacturing: manufacturing,
    packaging: packaging,
    brandDesign: brandDesign,
    formulation: formulation,
    solutions: solutions,
    ResearchProject: ResearchProject,
    ClinicalTrialManufacturing: ClinicalTrialManufacturing,
    FulfillmentServices: FulfillmentServices,
    meeting: meeting,
    labelling: labelling,
    branding: branding,
    design: design,

    //research projects page
    ResearchProjectsBanner: ResearchProjectsBanner,
    PioneeringFuture: PioneeringFuture,

    //clinical manufacturing
    ClinicalManufacturingBanner: ClinicalManufacturingBanner,
    ClinicalTrial: ClinicalTrial,

    //fulfillment services
    FulfillmentServicesBanner: FulfillmentServicesBanner,
    FulfillmentService: FulfillmentService,

    //genetics testing
    Trigenome_comprehensive_brochure: Trigenome_comprehensive_brochure,
    Trigenome_Nedimap_brochure: Trigenome_Nedimap_brochure,
    Trigenome_Kids_brochure: Trigenome_Kids_brochure,
    GeneticsTestingBanner: GeneticsTestingBanner,
    StateofArt1: StateofArt1,
    StateofArt2: StateofArt2,
    StateofArt3: StateofArt3,
    StateofArt4: StateofArt4,
    StateofArt5: StateofArt5,
    StateofArt6: StateofArt6,
    StateofArt7: StateofArt7,
    StateofArt8: StateofArt8,
    StateofArt9: StateofArt9,
    Comprehensive: Comprehensive,
    Medimap: Medimap,
    Kids: Kids,
    ExtendedTest1: ExtendedTest1,
    ExtendedTest2: ExtendedTest2,
    ExtendedTest3: ExtendedTest3,
    ExtendedTest4: ExtendedTest4,
    ExtendedTest5: ExtendedTest5,
    ExtendedTest6: ExtendedTest6,
    SalivaCollectedMethodVideo: SalivaCollectedMethodVideo,
    LabTourVideo: LabTourVideo,
    SampleTriGenome: SampleTriGenome,

    //product page
    VirtualProductBanner: VirtualProductBanner,
    VirtualProduct: VirtualProduct,
    StockProduct: StockProduct,
    OralSupplement: OralSupplement,
    CosmeticProduct: CosmeticProduct,
    SpecialityProduct: SpecialityProduct,

    //virtual product review page
    Product1: Product1,
    Product2: Product2,
    Product3: Product3,
    Product4: Product4,
    HighProteinImg: HighProteinImg,
    Detox: Detox,
    Tonic: Tonic,
    Vtone: Vtone,

    //stock product page
    StockProductBanner: StockProductBanner,
    HealthCareExperts: HealthCareExperts,
    SkinCareProduct: SkinCareProduct,

    //Oral supplement product page
    OralSupplementBanner: OralSupplementBanner,
    oralSupplementVideo: oralSupplementVideo,
    customerDistribution: customerDistribution,

    //cosmetic product page
    CosmeticBanner: CosmeticBanner,
    pureActiveIngredient: pureActiveIngredient,
    mineralOil: mineralOil,
    skinFriendly: skinFriendly,
    cruetlyFree: cruetlyFree,
    OurSpeciality1: OurSpeciality1,
    OurSpeciality2: OurSpeciality2,
    OurSpeciality3: OurSpeciality3,
    OurSpeciality4: OurSpeciality4,
    CleanFormulations: CleanFormulations,
    PersonalCare: PersonalCare,
    SensitiveSkincare: SensitiveSkincare,
    HairCare: HairCare,
    NaturalProduct: NaturalProduct,
    Essentials: Essentials,

    //speciality products page
    SpecialityProductBanner: SpecialityProductBanner,
    NootropicProduct: NootropicProduct,
    WeightGain: WeightGain,
    WeightLoss: WeightLoss,
    Endurance: Endurance,
    Aphrodisiacs: Aphrodisiacs,
    FeminineCare: FeminineCare,
    AdvancedAyurved: AdvancedAyurved,
    NaturalAdjuvants: NaturalAdjuvants,
    LiposomalFormulation: LiposomalFormulation,
    IntimacyEnhancementHer: IntimacyEnhancementHer,
    IntimacyEnhancementHim: IntimacyEnhancementHim,
    OrganicProducts: OrganicProducts,
    AnimalFeedPremix: AnimalFeedPremix,
    BabyCareImg1: BabyCareImg1,
    BabyCareImg2: BabyCareImg2,
    OralCare1: OralCare1,
    OralCare2: OralCare2,

    //private label page
    PrivateLabelBanner: PrivateLabelBanner,
    PrivateLabelVideo: PrivateLabelVideo,
    ManufacturingCapabilities: ManufacturingCapabilities,

    //manufacturing page
    ManufacturingPageBanner: ManufacturingPageBanner,
    ManufacturingBlog: ManufacturingBlog,
    Label: Label,
    View: View,
    Message: Message,

    //manufacturing blogs page
    ProfileLogo: ProfileLogo,
    TimeStampLogo: TimeStampLogo,
    ManufacturingBlog2: ManufacturingBlog2,
    FacebookImg: FacebookImg,
    TwitterImg: TwitterImg,
    GoogleImg: GoogleImg,
    DisplayPicture: DisplayPicture,
    BlogArticles: BlogArticles,
    Reply: Reply,

    //private lable products
    oralPowders: oralPowders,
    oralTablets: oralTablets,
    oralChemicals: oralChemicals,
    oralDelivery: oralDelivery,


}
export default Images