import React from "react"
import NavigationBar from "../../../commonComponents/NavigationBar/NavigationBar"
import Banner from "../../../commonComponents/Banner/Banner"
import Footer from "../../../commonComponents/Footer/Footer"
import Images from "../../../commonComponents/Images/Images"
import CardSection from "../../../commonComponents/CardSection/CardSection"


const QualityControl = () => {

    const data = {
        image: Images.QualityControlBanner,
        title: "quality control",
    }

    const heading1 = "Quality Management"

    const description1 = "Our quality control (QC) management for supplements ensures that every product not only meets but often surpasses our clients' high standards*:"


    const articlesData1 = [
        {
            title: 'Active Ingredient Assays',
            text: "We perform detailed QC assays to verify that the supplement's ingredients align precisely with those stated on its label",
            image: Images.QualityControl1,
        },

        {
            title: 'Dissolution / Disintegration Tests',
            text: 'Our QC labs replicate the digestive conditions of the body to confirm that supplements disintegrate properly for effective absorption.',
            image: Images.QualityControl2,
        },

        {
            title: 'microbiological purity',
            text: 'We conduct tests to certify that our products are free from microbial contamination.',
            image: Images.QualityControl3,
        },
        {
            title: 'gmo testing',
            text: "For products declared GMO-Free, we validate the absence of genetically modified organisms' materials.",
            image: Images.QualityControl4,
        },



    ];

    const notes = <>*Note: Some tests may be conducted by independent third-party laboratories at the client's request.
        <br /> For further details about our supplement QC services or to request a quote, please get in touch with Deltas.
    </>

    //quality assurance

    const heading2 = "quality assurance"

    const description2 = "Our quality assurance (QA) is of the utmost priority, where our QA and Quality Control (QC) teams work together to scrutinise every detail of a supplement's journey—from initial raw material testing*  to the final product assessment."


    const articlesData2 = [
        {
            title: 'Thorough Testing Procedures',
            text: 'Quality assurance in our supplement production is comprehensive, guided by strict protocols at each manufacturing phase to guarantee the highest supplement quality.',
            image: Images.QualityAssurance1,
        },

        {
            title: 'Raw Material Evaluation',
            text: 'We engage raw material providers vetted through an extensive Supplier Qualification Program, ensuring regulatory adherence and superior quality control. Only the freshest and purest ingredients are selected, resulting in top-tier supplements.',
            image: Images.QualityAssurance2,
        },
        {
            title: 'Laboratory Testing',
            text: 'Our QC labs, staffed by skilled chemists, rigorously test both raw materials and finished products against stringent consistency and quality benchmarks.',
            image: Images.QualityAssurance3,
        },
        {
            title: 'Advanced Manufacturing',
            text: 'We utilise cutting-edge facilities with the latest equipment for optimal blending, granulation, encapsulation, and tablet compression, contributing to exceptional product outcomes.',
            image: Images.QualityAssurance4,
        },
        {
            title: 'Environmental Monitoring',
            text: 'Continuous surveillance of our testing, manufacturing, and packaging environments ensures adherence to strict quality and manufacturing standards for air and water purity.',
            image: Images.QualityAssurance3,
        },
        {
            title: 'Packaging',
            text: 'The QA team meticulously oversees packaging processes to guarantee the integrity, purity, and accuracy of labelling claims on our products.',
            image: Images.QualityAssurance3,
        },
        {
            title: 'Comprehensive Product Testing',
            text: 'Our products undergo extensive testing, including but not limited to High Performance Liquid Chromatography, Gas Chromatography, Fourier Transform Infrared Spectroscopy, Thin Layer Chromatography, and various spectrometry methods.',
            image: Images.QualityAssurance3,
        },

    ];

    const notes2 = <>*Note: Upon client request, certain tests may be conducted by independent third-party laboratories.
        <br /> For inquiries or to request detailed information about our quality assurance services, please contact Deltas.
    </>


    return (
        <>
            <NavigationBar />
            <Banner bannerImg={data.image} title={data.title} customClassName="quality-management-page-banner-css" />
            <CardSection articlesData={articlesData1} heading={heading1} description={description1} notes={notes} customClassName="pb-4" customClassName2="quality-control-card" />
            <CardSection articlesData={articlesData2} heading={heading2} description={description2} notes={notes2} customClassName="" />
            <Footer />
        </>
    )
}
export default QualityControl