import React from "react"
import TitleWithDescription from "../../commonComponents/TitleWithDescriptionLayout/TitleWithDescriptionLayout"
import Images from "../../commonComponents/Images/Images"
import Slider from "react-slick"
import { NavLink } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'


const OurCollabrations = () => {


    const images = {
        image1: Images.haffkine,
        image2: Images.CSIR,
        image3: Images.anthem,
        image4: Images.healthcare,
    }


    const data1 = {
        title: "Welcome to the Research & Development Portal At Deltas Pharma",
        description1: <ul> <li>At Deltas Pharma, our dedicated R&D team focuses on harnessing the latest technology and ancient medicines in producing evidence-based Ayurvedic products that promote health and well-being.</li>
            <li> With over a quarter-century of dedicated expertise in the realm of natural ingredients, our journey has been anchored in the rigorous exploration of how these organic elements contribute to overall health and vitality.</li>
        </ul>,
        description2: <ul>
            <li>Thanks to continuous research we were able to formulate natural phospholipids, a variety of pigments, natural resins, polyphenols, and peptides.
            </li>
            <li>
                Our approach to product development combines ongoing research and partnership with leading suppliers to ensure that our cosmetics and supplements are both innovative and effective.We aim to meet our customers' needs with products supported by science and inspired by nature.
            </li>
        </ul>

    }

    const data2 = {
        title: "Our R&D",
        description1: <ul> <li>At Deltas Pharma, our formulation research extends beyond just natural ingredients. Our continuous collaboration with top ingredient suppliers helps our formulators find the best combinations of active ingredients. This effort allows us to develp cosmetics and supplements that meet the twin goals of innovation and real-world effectiveness.</li>
            <li>
                Leveraging our deep knowledge of plant-based nutraceuticals
            </li>
        </ul>,
        description2: <ul>
            <li> and phytoceuticals, we tap into the power of plants to enhance health and wellness.</li>
            <li>Deltas provides a variety of plant - based phytoceutical formulations, including herbal supplements, antioxidants, polyphenols, and omega-3 fatty acids. We specialize in using unique ingredients and their synergistic partners to improve health and nutritional outcomes. Below is a list of some of these functional ingredients:
            </li>

        </ul>
    }

    const servicescarousel = [
        {
            id: 1,
            Image: Images.ourRnD1,
            title: "formulation experience",
            link: "/company/formulation-experience",
        },
        {
            id: 2,
            Image: Images.ourRnD2,
            title: "quality management",
            link: "/company/quality-management",
        },
        {
            id: 3,
            Image: Images.ourRnD3,
            title: "scientific publications",
            link: "/company/scientific-publications",
        },
        {
            id: 4,
            Image: Images.ourRnD4,
            title: "patents & certification",
            link: "/company/patents-certifications",
        },
    ]

    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: false,        // Enable autoplay
        autoplaySpeed: 1500,
        centerMode: false,

        responsive: [
            {
                breakpoint: 1030,
                settings: {
                    slidesToShow: 3,
                    prevArrow: <FontAwesomeIcon icon={faChevronLeft} />,
                    nextArrow: <FontAwesomeIcon icon={faChevronRight} />,
                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 2,
                    prevArrow: <FontAwesomeIcon icon={faChevronLeft} />,
                    nextArrow: <FontAwesomeIcon icon={faChevronRight} />,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    prevArrow: <FontAwesomeIcon icon={faChevronLeft} />,
                    nextArrow: <FontAwesomeIcon icon={faChevronRight} />,
                }
            },
        ],
    }

    return (
        <>
            {/* //heading with description */}
            <TitleWithDescription title={data1.title} description1={data1.description1} description2={data1.description2} />

            {/* //our OurCollabrations */}
            <div className="our-collabrations">
                <div className="container">
                    <div className="">
                        <div className="row services-institute">
                            <div className="col-lg-5 col-md-12 col-sm-12 our-collabrations-description">
                                <h3 className="text-left py-20">Our Collaborations</h3>
                                <p>Our R&D endeavors are bolstered by strong collaborations with a range of government institutes and research organizations, both public and private. These collaborations, some over 15 years old, are crucial to our research. They have supported many projects and help us stay innovative in the field of natural ingredient research.</p>
                            </div>
                            <div className="col-lg-7 col-md-12 col-sm-12 services-institute-div">
                                <div className="row row-25">
                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className="d-flex">
                                            <div className="rnd-img-div">
                                                <img src={images.image1} alt="Haffkine Institute" />
                                            </div>
                                            <div className="service-content">
                                                <div>
                                                    <span className="text-left text-bold">Haffkine Institute :</span>
                                                </div>
                                                <div>
                                                    <span className="services-institute-description">Founded in 1899 for bacteriological research; provides basic and applied biomedical sciences.</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className="d-flex">
                                            <div className="rnd-img-div">
                                                <img src={images.image2} alt="CSIR - CIMAP" />
                                            </div>
                                            <div className="service-content">
                                                <div>
                                                    <span className="text-left text-bold">CSIR - CIMAP :</span>
                                                </div>
                                                <div>
                                                    <span className="services-institute-description">A leading multidisciplinary institute for research on medicinal and aromatic plants under the CSIR.</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row row-25">
                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className="d-flex">
                                            <div className="rnd-img-div">
                                                <img src={images.image3} alt="Anthem Biosciences" />
                                            </div>
                                            <div className="service-content">
                                                <div>
                                                    <span className="text-left text-bold">Anthem Biosciences :</span> </div>
                                                <div>
                                                    <span className="services-institute-description">A CRISP, producing innovative commercial drug actives in Bangalore.</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className="d-flex">
                                            <div className="rnd-img-div">
                                                <img src={images.image4} alt="Integrity Healthcare Services" />
                                            </div>
                                            <div className="service-content">
                                                <div>
                                                    <span className="text-left text-bold">Integrity Healthcare Services :</span> </div>
                                                <div>
                                                    <span className="services-institute-description">A CRO specializing in formulation development and clinical research from Phase II to IV.</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >

            {/* //our rnd */}
            <TitleWithDescription title={data2.title} description1={data2.description1} description2={data2.description2} customClassName="pb-4" />

            {/* // our rnd carousel */}
            <div className="services header-bottom-line container">
                <div className="pxy-40 px-0 pt-0">
                    <div className="">
                        <div className='card-carousel-main'>
                            <Slider {...settings}>
                                {servicescarousel.map((obj) => (
                                    <div className='carousel-card-main services-carousel' key={obj.id}>
                                        <NavLink to={obj.link}>
                                            <div className='carousel-card-nav card our-rnd-card'>
                                                <div className="img-container">
                                                    <img src={obj.Image} className='carousel-card-img' width={"100%"} />
                                                </div>
                                                <div className="carousel-content font-family-regular">
                                                    <h5 className='carousel-title text-center py-20'>{obj.title}</h5>
                                                </div>
                                            </div>
                                        </NavLink>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                </div >
            </div >

        </>
    )
}

export default OurCollabrations