import React from "react"
import Images from "../../commonComponents/Images/Images"

const PrivateLabelComponent = () => {

    const data = {
        image1: Images.ManufacturingCapabilities
    }

    return (
        <>
            {/* //private label components, packaging and manufacturing sections */}
            <div className="manufacturing-packaging header-bottom-line">
                <div className="container">
                    <div className="text-center">
                        <img className="img-fluid display-inline" src={data.image1} alt="" width={"75%"} />
                    </div>
                    <div className="pxy-40 pb-0">
                        <div className="centered-content">
                            <div>
                                <h3 className="text-center py-20 ">manufacturing capabilities</h3>
                                <div className="mt-40">
                                    <p className="mb-25">Besides bringing integrity, quality and service into everything we do, you get: Delivery Forms Superior Supplement Manufacturing.</p>
                                    <div className="row row-25">
                                        <div className="col-lg-6 col-md-12 col-sm-12">
                                            <p>
                                                <ol start="1">
                                                    <li>Capsules</li>
                                                    <li>Powders</li>
                                                    <li>Tablets</li>
                                                    <li>Lami Tubes</li>
                                                    <li>Syrups</li>
                                                </ol>
                                            </p>
                                        </div>
                                        <div className="col-lg-6 col-md-12 col-sm-12">
                                            <p>
                                                <ol start="6">
                                                    <li>Sachets</li>
                                                    <li>Aromatherapy oils</li>
                                                    <li>Liquids</li>
                                                    <li>Lozenges</li>
                                                </ol>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <h3 className="text-center py-20 ">packaging</h3>
                                <div className="mt-40">
                                    <p className="font-size-22 text-bold text-black mb-25">Packaging and more - from concept to finished product</p>
                                    <div className="row row-25">
                                        <div className="col-lg-6 col-md-12 col-sm-12">
                                            <p>
                                                <ol start="1">
                                                    <li>Blister Packs</li>
                                                    <li>Bottles</li>
                                                    <li>Stick Packs</li>
                                                    <li>Laminated Tubes</li>
                                                    <li>Sachets</li>
                                                </ol>
                                            </p>
                                        </div>
                                        <div className="col-lg-6 col-md-12 col-sm-12">
                                            <p>
                                                <ol start="6">
                                                    <li>Folding Cartons</li>
                                                    <li>Aromatherapy oils</li>
                                                    <li>Pump based liquid Packagings</li>
                                                </ol>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="bottle-packaging-div pxy-30 my-20">
                    <div className="container">
                        <p className="font-size-22 text-bold text-center text-black mb-25">Bottle Packaging</p>
                        <div>
                            <div className="mb-25 w-50 mx-auto bottle-packaging-width">
                                <p className="">We offer bottling options for almost any situation ranging from but not limited to:</p>
                                <p className="">bottle packaging solutions colorful bottles superior supplement manufacturing</p>
                            </div>
                            <div className="row row-25">
                                <div className="col-lg-4 col-md-12 col-sm-12">
                                    <p>
                                        <ol start="1">
                                            <li>Dropper Bottle Packaging Superior<br /> Supplement Manufacturing</li>
                                            <li>Polyethlene Terephthalate<br /> (PET) Bottles</li>
                                            <li>High - Density Polyethylene<br /> (HDPE) Bottles</li>
                                        </ol>
                                    </p>
                                </div>
                                <div className="col-lg-4 col-md-12 col-sm-12">
                                    <p>
                                        <ol start="4">
                                            <li>Diverse Stock Colors or<br /> Custom Colors</li>
                                            <li>Various Neck and Mouth Sizes<br /> Small to Large</li>
                                            <li>Threaded, Snap On, Flip Top<br /> and More</li>
                                        </ol>
                                    </p>
                                </div>
                                <div className="col-lg-4 col-md-12 col-sm-12">
                                    <p>
                                        <ol start="7">
                                            <li>Sizes 50cc to 1000cc or Larger</li>
                                            <li>Oblong, Jar, Packer, Oval and More</li>
                                            <li>Glass and Plastic</li>
                                            <li>Laminated tubes</li>
                                            <li>Laminated Sachets</li>
                                        </ol>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="pxy-40 pt-0">
                        <div className="centered-content">
                            <div className="mb-40">
                                <p className="font-size-22 text-bold text-black mb-25">Blister Packaging</p>
                                <div>
                                    <p>A blister supplement design is the ideal solution when needing individual tablet and capsule protection or selling standalone units. Blister packaging provides added protection as well as a level of tamper protection. In addition, blister supplement packaging can be used with products that require an added level of moisture protection.</p>
                                    <p>We can provide standard blister supplement packaging as well as custom card shapes, styles and sizes as well as multiple tablet cavities and special cavity shapes.</p>
                                </div>
                            </div>
                            <div className="">
                                <p className="font-size-22 text-bold text-black mb-25">Folding Carton Packaging</p>
                                <div>
                                    <p>We offer folding carton supplement packaging to give your private label supplements product maximum visibility. From small to large blister boxes and snap, auto and tuck boxes available we have a wide variety of supplement packaging options, sizes and applications to fit your needs.</p>
                                    <p>Call us to find if a folding carton supplement packaging is the best option for your product.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >


        </>
    )
}
export default PrivateLabelComponent