import React from "react"
import NavigationBar from "../../../commonComponents/NavigationBar/NavigationBar"
import Banner from "../../../commonComponents/Banner/Banner"
import Footer from "../../../commonComponents/Footer/Footer"
import Images from "../../../commonComponents/Images/Images"
import ServicePageComponent from "../../../components/ServicePageComponents/ServicePageComponent"

const ServicePage = () => {

    const data = {
        image: Images.ServiceBanner,
        title: "service",
        content: "Decoding the Natural Adjuvants",
    }

    return (
        <>
            <NavigationBar />
            <Banner bannerImg={data.image} title={data.title} content={data.content} customClassName="service-page-banner-css" />
            <ServicePageComponent />
            <Footer />
        </>
    )

}
export default ServicePage