import React from "react"
import Images from "../../commonComponents/Images/Images"
import Slider from "react-slick"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'

const CardsWithCarousel = ({ customClassName }) => {

    const servicecarousel = [
        {
            id: 1,
            Image: Images.service1,
            title: "team up with industry leaders",
            description: "Harness the collective knowledge and energy of our top-tier scientists and industry experts. With Deltas, you’re not just getting a service provider; you’re gaining a partner who takes care of the intricate processes, freeing you to focus solely on achieving results.",
            button: false,
        },
        {
            id: 2,
            Image: Images.service2,
            title: <>diverse product<br /> portfolio</>,
            description: "Choose from over 150 private and white label formulas across burgeoning sectors like health and wellness, beauty, personal care, and animal health. Each formula is customizable to mirror your brand's ethos and objectives.",
            button: false,

        },
        {
            id: 3,
            Image: Images.service3,
            title: <>low start-up<br /> investment</>,
            description: "Understanding the essence of time in business, we ensure rapid product launches to accelerate your journey towards revenue generation.",
            button: false,

        },
        {
            id: 4,
            Image: Images.service4,
            title: <>affordable and<br /> accessible</>,
            description: "Embark on your product launch journey with minimal financial strain. Our minimum order quantity starts at just 1000 units, catering to businesses at every scale.",
            button: false,

        },
        {
            id: 5,
            Image: Images.service1,
            title: "End-to-End Product Development",
            description: "Leave the complexities of product development to us. Our expertise lies in managing the nitty-gritty of product creation, allowing you to concentrate on driving your business forward through effective marketing and sales strategies.",
            button: false,

        },
    ]

    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: false,        // Enable autoplay
        autoplaySpeed: 1500,
        centerMode: false,
        responsive: [
            {
                breakpoint: 2560,
                settings: {
                    slidesToShow: 4,
                    prevArrow: <FontAwesomeIcon icon={faChevronLeft} />,
                    nextArrow: <FontAwesomeIcon icon={faChevronRight} />,
                }
            },
            {
                breakpoint: 1030,
                settings: {
                    slidesToShow: 4,
                    prevArrow: <FontAwesomeIcon icon={faChevronLeft} />,
                    nextArrow: <FontAwesomeIcon icon={faChevronRight} />,
                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 2,
                    prevArrow: <FontAwesomeIcon icon={faChevronLeft} />,
                    nextArrow: <FontAwesomeIcon icon={faChevronRight} />,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    prevArrow: <FontAwesomeIcon icon={faChevronLeft} />,
                    nextArrow: <FontAwesomeIcon icon={faChevronRight} />,
                }
            },
        ],
    }

    return (
        <>
            {/* //cards with carousel */}
            <div className="header-bottom-line">
                <div className="container">
                    <div className={`pxy-40 ${customClassName}`}>
                        <div className="">
                            <div className="row">
                                <Slider {...settings}>
                                    {servicecarousel.map((article, index) => (
                                        <div key={index} className="col-lg-3 col-md-6 col-sm-12 border-line carousel-card-main">
                                            <article class="box-minimal-modern carousel-card">
                                                <div class="box-minimal-icon novi-icon linearicons-pills">
                                                    <img src={article.Image} alt="service logo" width={"50%"} />
                                                </div>
                                                <h6 class="box-minimal-title text-center">{article.title}</h6>
                                                <p class="box-minimal-text text-justify letter-spacing-5">{article.description}</p>
                                                {article.button && <div class="text-center mt-3">
                                                    <button class="btn ash-border">LEARN MORE</button>
                                                </div>}
                                            </article>
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default CardsWithCarousel