import React from "react"
import Images from "../../commonComponents/Images/Images"



const OralSupplements = () => {

    const data = {
        image1: Images.oralPowders,
        image2: Images.oralTablets,
        image3: Images.oralChemicals,
        image4: Images.oralDelivery,

    }


    return (
        <>
            {/* //few words oral supplements */}
            <div className="about-us header-bottom-line">
                <div className="container">
                    <div className="pxy-40 px-0">
                        <h3 className="text-center py-20 ">ORAL SUPPLEMENTS</h3>
                        <div className="mt-70">
                            <div className="">
                                <div className="">
                                    <p className="font-size-22 mb-25">Our Oral Supplements are a range of food supplements designed to meet specific wellness needs. They are crafted from unique formulations, developed through extensive research and a deep understanding of the synergy between active ingredients. We prioritise safety, effectiveness, and quality, while also respecting both the body and the environment.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* //card section */}
            <div className="oral-supplements">
                <div className="container">
                    <div className="pxy-40 px-0">
                        <h3 className="py-20 text-center">Guaranteed Quality thanks to the control of the entire Supply Chain</h3>
                        <div className="pt-40">
                            <div className="row text-center oral-supplements-row card-section-full-img">
                                <div className="col-lg-3 col-md-12 col-sm-12">
                                    <div className="card">
                                        <div>
                                            <div className="img-container-full">
                                                <img src={data.image1} alt="supply-chain1" width={"100%"} />
                                            </div>
                                            <div className="card-body">
                                                <h6 className="py-20 mt-20">Selecting Only Premium Ingredients</h6>
                                                <div>
                                                    <p>We exclusively select premium-quality ingredients</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-12 col-sm-12">
                                    <div>
                                        <div className="card">
                                            <div className="img-container-full">
                                                <img src={data.image2} alt="supply-chain2" width={"100%"} />
                                            </div>
                                            <div className="card-body">
                                                <h6 className="py-20 mt-20">Limited Production for Freshness</h6>
                                                <div>
                                                    <p>We produce in small batches to ensure continual freshness of our products</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-12 col-sm-12 ">
                                    <div className="card">
                                        <div>
                                            <div className="img-container-full">
                                                <img src={data.image1} alt="supply-chain3" width={"100%"} />
                                            </div>
                                            <div className="card-body">
                                                <h6 className="py-20 mt-20">Thorough Testing and Quality Control</h6>
                                                <div>
                                                    <p>Every stage of our production process is subjected to stringent quality checks</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-12 col-sm-12">
                                    <div className="card">
                                        <div>
                                            <div className="img-container-full">
                                                <img src={data.image2} alt="supply-chain4" width={"100%"} />
                                            </div>
                                            <div className="card-body">
                                                <h6 className="py-20 mt-20">Direct-to-Customer Distribution</h6>
                                                <div>
                                                    <p>Our direct sales approach ensures the best quality-to-price ratio for our customers</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
export default OralSupplements