import React from "react"
import { Modal } from "react-bootstrap"
import Button from 'react-bootstrap/Button'
import Images from "../Images/Images"


const DisplayModal = ({ show, onHide, pdfSrc }) => {

    return (
        <>
            <Modal
                // {...props}
                // size="lg"
                // aria-labelledby="contained-modal-title-vcenter"
                // centered
                show={show}
                onHide={onHide}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    {/* <Modal.Title id="contained-modal-title-vcenter">
                        Modal heading
                    </Modal.Title> */}
                </Modal.Header>
                <Modal.Body>
                    <iframe src={pdfSrc} frameborder="0" title="pdf" allowFullScreen width={"100%"} height={"700px"}></iframe>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default DisplayModal