import React from "react"
import NavigationBar from "../../../commonComponents/NavigationBar/NavigationBar"
import Banner from "../../../commonComponents/Banner/Banner"
import Footer from "../../../commonComponents/Footer/Footer"
import Images from "../../../commonComponents/Images/Images"
import ImgWithDescription from "../../../commonComponents/ImgWithDescription/ImgWithDescription"
import CardSection from "../../../commonComponents/CardSection/CardSection"

const OralSupplements = () => {

    const data = {
        image: Images.OralSupplementBanner,
        title: "Oral supplements",

    }

    const data2 = {
        video: Images.oralSupplementVideo,
        heading: "herbal and nutraceutical",
        // subtitle: "5 Years of Pioneering Excellence:",
        description: "Our Oral Supplements are a range of food supplements designed to meet specific wellness needs. They are crafted from unique formulations, developed through extensive research and a deep understanding of the synergy between active ingredients. We prioritise safety, effectiveness, and quality, while also respecting both the body and the environment.",
    }

    const articlesData = [
        {
            title: 'SELECTING ONLY PREMIUM INGREDIENTS',
            text: 'We exclusively select premium-quality ingredients',
            image: Images.QualityControl1,
        },

        {
            title: 'LIMITED PRODUCTION FOR FRESHNESS',
            text: 'We produce in small batches to ensure continual freshness of our products',
            image: Images.QualityAssurance4,
        },

        {
            title: 'THOROUGH TESTING AND QUALITY CONTROL',
            text: 'Every stage of our production process is subjected to stringent quality checks',
            image: Images.QualityAssurance1,
        },
        {
            title: 'DIRECT-TO-CUSTOMER DISTRIBUTION',
            text: 'Our direct sales approach ensures the best quality-to-price ratio for our customers',
            image: Images.customerDistribution,
        },

    ];

    return (
        <>
            <NavigationBar />
            <Banner bannerImg={data.image} title={data.title} content={data.content} customClassName="oralsupplements-page-banner-css" />
            <ImgWithDescription {...data2} customClassName="product-description" />
            <CardSection articlesData={articlesData} customClassName="box-mini-minimal mt-0" customClassName2="oralsupplements-card"/>
            <Footer />
        </>
    )
}
export default OralSupplements