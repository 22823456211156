import './App.css'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import routes from "./routes/routes"
//bootstrap css
import 'bootstrap/dist/css/bootstrap.min.css'
//bootstrap js
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
//slick carousel css
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
//toastify css
import 'react-toastify/dist/ReactToastify.css'
import ScrollToTop from "../src/commonComponents/ScrollToTop/ScrollToTop"
import "../src/assets/css/NavigationBar/NavigationBar.css"
import "../src/assets/css/Footer/Footer.css"
import "../src/assets/css/HomePage/HomePage.css"
import "../src/assets/css/Media/Media.css"
import "../src/assets/css/CompanyModule/CompanyModule.css"
import "../src/assets/css/Products/Products.css"
import "../src/assets/css/ServiceModule/ServiceModule.css"
import "../src/assets/css/KnowledgeCenterModule/KnowledgeCenterModule.css"
import "../src/assets/css/FeasibilityModule/FeasibilityModule.css"


const live = "" //live
const staging = "/deltaspharma2"



const App = () => {

  return (
    <>
      <Router

        basename={live}
      >
        <ScrollToTop />
        <Routes >
          {routes.map((route) => {
            return (
              <Route key={route.name} path={route.path} exact={route.exact} name={route.name} element={<route.element />}
              />
            );
          })}
        </Routes>
      </Router >
    </>
  );
}

export default App

