import React, { useState, useEffect } from "react"
import NavigationBar from "../../../commonComponents/NavigationBar/NavigationBar"
import Banner from "../../../commonComponents/Banner/Banner"
import Footer from "../../../commonComponents/Footer/Footer"
import Images from "../../../commonComponents/Images/Images"
import { useNavigate } from "react-router-dom"
import axios from "axios"

const VirtualProductReviewPage = () => {

    const [virtualProducts, setVirtualProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const data = {
        image: Images.VirtualProductBanner,
    }

    // const reviewProductImages = [
    //     {
    //         id: 1,
    //         slug: "nutratutical-sports",
    //         productReviewImage: Images.Product1,
    //     },
    //     {
    //         id: 2,
    //         slug: "chlorophyll-detox",
    //         productReviewImage: Images.Product2,
    //     },
    //     {
    //         id: 3,
    //         slug: "jodrelief",
    //         productReviewImage: Images.Product3,
    //     },
    //     {
    //         id: 4,
    //         slug: "vtone",
    //         productReviewImage: Images.Product4,
    //     },
    // ]

    //get reivew products api integration from cms
    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    const virtualProductsEndpoint = '/virtual_product';
    const virtualProductsUrl = `${apiUrl}${virtualProductsEndpoint}`;


    const fetchReviewProducts = () => {

        axios.get(virtualProductsUrl).then((res) => {
            //     if (res.data.status === 0 || res.data.message === "No records found") {
            //         setVirtualProducts([]);
            //     } else {
            //         setVirtualProducts(res.data.data);
            //     }
            //     setLoading(false);
            // })
            if (res.data.status === 1) {
                const filteredData = res.data.data.filter(item => item.master_type === "product" && item.file_category === "banner");

                // Set the filtered features to state
                setVirtualProducts(filteredData);
                setLoading(false);
            } else {
                setVirtualProducts([]);
                setLoading(false);
            }
        })
            .catch((error) => {
                setError("Error fetching Virtual Products data.");
                setLoading(false);
            })
    };


    useEffect(() => {
        fetchReviewProducts();
    }, []);


    const handleClick = (id) => {
        navigate(`/product/product-review`, {
            state: {
                id: id ?? '',
            }
        });
    };


    return (
        <>
            <NavigationBar />
            <Banner bannerImg={data.image} title={data.title} content={data.content} customClassName="virtualproductreview-page-banner-css" />
            <div className="product-review-images">
                {
                    loading ? (
                        <p>Loading...</p>
                    ) : error ? (
                        <p>
                            {error}
                        </p>
                    ) : virtualProducts.length > 0 ? (
                        virtualProducts.map((obj) => (
                            <img src={obj.file_path} alt="review products" onClick={() => handleClick(obj.id)} />
                        ))
                    ) : (
                        <p>No Virtual Products found</p>
                    )
                }
            </div>
            <Footer />
        </>
    )
}
export default VirtualProductReviewPage