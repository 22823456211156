import React, { useEffect, useState } from "react"
import Images from "../../commonComponents/Images/Images"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import AccordianSection from "../../commonComponents/AccordianSection/AccordianSection"
import { NavLink, redirect, useNavigate } from "react-router-dom"
import axios from "axios"
import moment from "moment"


const ManufacturingComponent = () => {

    const [latestBlogs, setLatestBlogs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");
    const navigate = useNavigate();

    // const latestBlogs = [

    //     {
    //         Timestamp: <>Mar 26, 2024<br /> at 5:46 pm</>,
    //         image: Images.ManufacturingBlog,
    //         title: "Fucoxanthin : A Marine Ally in the Battle against Obesity",
    //         blogDescription: "Introduction: The increasing prevalence of obesity and associated lifestyle diseases has led to a search for effective interventions. Among them, fucoxanthin, a carotenoid from brown seaweeds, emerges as a promising candidate in the anti-obesity battle. This article delves deeper into fucoxanthin's potential and its development into functional foods and drugs.",
    //         news: "News",
    //         viewCount: 196,
    //         messageCount: 4,

    //     },
    //     {
    //         Timestamp: <>Mar 26, 2024<br /> at 5:46 pm</>,
    //         image: Images.ManufacturingBlog,
    //         title: "Shilajeet, a swift restorative : ",
    //         blogDescription: "Shilajit, also known as 'the destroyer of weakness', is a potent substance found in the rocky mountains of the Himalayas, Altai, Caucasus, and other mountain ranges. This dark, resinous material has been treasured for centuries in traditional Ayurvedic and Tibetan medicine systems for its purported health benefits. In recent years, scientific research has begun to shed light on the potential therapeutic properties of Shilajit, leading to increased interest in its use for various health conditions.",
    //         news: "News",
    //         viewCount: 196,
    //         messageCount: 4,

    //     },
    //     {
    //         Timestamp: <>Mar 26, 2024<br /> at 5:46 pm</>,
    //         image: Images.ManufacturingBlog,
    //         title: "Exploring the Therapeutic Potential of Apigenin for Skin and Hair Health ",
    //         blogDescription: "Apigenin, a natural flavonoid found abundantly in fruits, vegetables, and herbs, has garnered significant attention in recent years for its potential therapeutic effects on skin and hair health. With a rich history in traditional medicine and growing scientific interest, apigenin presents a promising avenue for addressing various dermatological concerns. In this comprehensive guide, we delve into the diverse pharmacological properties of apigenin and its application in skincare and haircare, supported by evidence from scientific studies and clinical trials.",
    //         news: "News",
    //         viewCount: 196,
    //         messageCount: 4,

    //     },
    //     {
    //         Timestamp: <>Mar 26, 2024<br /> at 5:46 pm</>,
    //         image: Images.ManufacturingBlog,
    //         title: "Menaquinone: The Role of Vitamin K2 in Health and Disease",
    //         blogDescription: "In recent years, there has been growing interest in the role of menaquinone, also known as Vitamin K2, in promoting health and preventing disease. Menaquinone, a fat-soluble vitamin, is essential for various physiological processes in the body, including blood clotting, bone metabolism, and cardiovascular health. This comprehensive blog explores the scientific evidence surrounding menaquinone and its impact on health and disease, supported by extensive citations from reputable sources.",
    //         news: "News",
    //         viewCount: 196,
    //         messageCount: 4,

    //     },
    //     {
    //         Timestamp: <>Mar 26, 2024<br /> at 5:46 pm</>,
    //         image: Images.ManufacturingBlog,
    //         title: "Exploring the Health Benefits of Equol: A Comprehensive Guide",
    //         blogDescription: "Equol, a metabolite derived from the microbial metabolism of dietary soy isoflavones, has gained significant attention in recent years for its potential health benefits. This blog post aims to delve into the scientific research surrounding equol, exploring its role in promoting health and potentially preventing diseases.",
    //         news: "News",
    //         viewCount: 196,
    //         messageCount: 4,

    //     },
    //     {
    //         Timestamp: <>Mar 26, 2024<br /> at 5:46 pm</>,
    //         image: Images.ManufacturingBlog,
    //         title: " Cordyceps: Unveiling the Potential of Nature's Pharmacy",
    //         blogDescription: "Cordyceps, a genus of fungi with over 400 species, has been a cornerstone in traditional Chinese medicine for its myriad health benefits. Originating from the high-altitude regions of Tibet and China, this unique fungus has piqued the interest of researchers globally due to its intriguing properties and potential therapeutic applications. This article delves into the captivating world of cordyceps, exploring its impact on health and disease while illuminating the scientific evidence supporting its medicinal claims.Origins of Cordyceps:",
    //         news: "News",
    //         viewCount: 196,
    //         messageCount: 4,

    //     },
    //     {
    //         Timestamp: <>Mar 26, 2024<br /> at 5:46 pm</>,
    //         image: Images.ManufacturingBlog,
    //         title: "The Wonder Natural Ingredient for Longevity: Spermidine ",
    //         blogDescription: "Spermidine, a polyamine first characterized in sperm, is found in all eukaryotic cells.It plays a central role in the development of life, with bacteria, archaea, plants, and even viruses all relying on polyamines.Spermidine has been shown to have pleiotropic effects, favorably modulating all of the nine original hallmarks of aging plus the three new hallmarks, including dysregulation of macroautophagy, dysbiosis, and inflammation. ",
    //         news: "News",
    //         viewCount: 196,
    //         messageCount: 4,

    //     },
    //     {
    //         Timestamp: <>Mar 26, 2024<br /> at 5:46 pm</>,
    //         image: Images.ManufacturingBlog,
    //         title: "Exploring the Health Benefits of Urolithin A: A Comprehensive Scientific Analysis",
    //         blogDescription: "Urolithin A (UroA), derived from the gut microbiome through the metabolic transformation of ellagic acid and ellagitannins, has emerged as a fascinating subject of scientific inquiry due to its potential health benefits. Extensive research across various organisms, from worms and cells to mice and humans, has shed light on its diverse effects on health and disease management. This comprehensive scientific blog aims to delve into the intricate details of Urolithin A, exploring its role in promoting health and preventing diseases across multiple organ systems.",
    //         news: "News",
    //         viewCount: 196,
    //         messageCount: 4,

    //     }
    // ]

    const accordianHeading = "expertise that sets us apart:"

    const accordionData = [
        {
            question: "Consistency and Standardization",
            answer: "Achieving consistency in herbal and nutraceutical formulations is a cornerstone of our expertise. We employ state-of-the-art processes and quality control measures to ensure every batch meets the highest standards. Our commitment to standardization guarantees that your private label products maintain uniformity and effectiveness."
        },
        {
            question: "Shelf Life Assurance",
            answer: "Achieving consistency in herbal and nutraceutical formulations is a cornerstone of our expertise."
        },
        {
            question: "Sustainability in Every Step",
            answer: "Achieving consistency in herbal and nutraceutical formulations is a cornerstone of our expertise. We employ state-of-the-art processes and quality control measures to ensure every batch meets the highest standards."
        },
        {
            question: "Hoslistic Support Beyond",
            answer: "Achieving consistency in herbal and nutraceutical formulations is a cornerstone of our expertise."
        },
        {
            question: "Packaging Soutions",
            answer: "Achieving consistency in herbal and nutraceutical formulations is a cornerstone of our expertise."
        },
        {
            question: "Regulatory Compliance",
            answer: "Achieving consistency in herbal and nutraceutical formulations is a cornerstone of our expertise."
        },
        {
            question: "Proof of Claims",
            answer: "Achieving consistency in herbal and nutraceutical formulations is a cornerstone of our expertise. We employ state-of-the-art processes and quality control measures to ensure every batch meets the highest standards."
        },
        {
            question: "Driving Your Brand Success",
            answer: "Achieving consistency in herbal and nutraceutical formulations is a cornerstone of our expertise."
        },

    ];

    // api integration of latestblogs with short description

    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    const latestBlogsEndPoint = '/blog_image_fetch';
    const latestBlogsUrl = `${apiUrl}${latestBlogsEndPoint}`

    const fetchLatestBlogs = (query = "") => {
        setLoading(true);
        const url = query ? `${latestBlogsUrl}?search=${query}` : latestBlogsUrl;

        axios.get(url)
            .then((res) => {
                // if (res.data.data === 0) {
                //     setLatestBlogs([]);
                // } else {
                //     setLatestBlogs(res.data.data);
                // }
                // setLoading(false);
                if (res.data.status === 1) {
                    const filteredData = res.data.data.filter(item => item.master_type === "blog" && item.file_category === "thumbnail");

                    // Set the filtered thumbnails to state
                    setLatestBlogs(filteredData);
                    setLoading(false);
                } else {
                    setLatestBlogs([]);
                    setLoading(false);
                }
            })
            .catch((error) => {
                setError("Error fetching latest blogs data.");
                setLatestBlogs([]);
                setLoading(false);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    useEffect(() => {
        fetchLatestBlogs(searchQuery);
    }, [searchQuery]);

    const handleSearch = (e) => {
        e.preventDefault();
        fetchLatestBlogs(searchQuery);
    };

    const handleclick = (blog_id) => {
        console.log(blog_id);
        navigate(`/manufacturing/blogs/${blog_id}`, {
            state: {
                scrollId: '#comments-section',
            }
        });
        console.log(navigate);

    }

    return (
        <>
            <div className="manufacturing-blogs-section header-bottom-line">
                <div className="container">
                    <div className="pxy-40 px-0">
                        <div className="">
                            <div className="row">
                                <div className="col-lg-8 col-md-12 col-sm-12">
                                    <div className="time-line-vertical">
                                        {loading ? (
                                            <p>Loading...</p>
                                        ) : error ? (
                                            <p>{error}</p>
                                        ) : latestBlogs.length > 0 ? (
                                            latestBlogs.map((item) => (
                                                <div className="time-line-vertical-element time-line-vertical-element-offset" key={item.blog_id}>
                                                    <div className="unit blog-lists-div">
                                                        <div className="unit-left">
                                                            <div className="time-line-time">
                                                                <time className="wow fadeInLeft" data-wow-delay=".6s" datetimestamp="">{moment(item.updated_ts).format('DD-MM-YYYY')}</time>
                                                            </div>
                                                            <div className="display-picture-div text-center">
                                                                <div className="display-img-div">
                                                                    <img src={Images.DisplayPicture} alt="" width={"100px"} />
                                                                </div>
                                                                <div>
                                                                    <span>{item.user_role_name}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="unit-body card">
                                                            <NavLink to={`/manufacturing/blogs/${item.blog_id}`}>
                                                                <div className="time-line-content wow fadeInRight" data-wow-delay=".6s">
                                                                    <div className="border-radius">
                                                                        <img src={item.file_path} width="100%" height="auto" alt={item.name} />
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <p className="font-size-22 text-bold mb-0">{item.name}</p>
                                                                        <div className="short-description" >
                                                                            <p>{item.short_description}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </NavLink>
                                                            <div className="d-flex justify-content-between blog-card-margin">
                                                                <NavLink to={`/manufacturing/blogs/${item.blog_id}`}>
                                                                    <div className="blog-img-text">
                                                                        <div>
                                                                            <img src={Images.Label} alt="label" />
                                                                        </div>
                                                                        <span>News</span>
                                                                    </div>
                                                                </NavLink>
                                                                <div className="d-flex">
                                                                    <NavLink to={`/manufacturing/blogs/${item.blog_id}`}>
                                                                        <div className="mx-3 blog-img-text">
                                                                            <div>
                                                                                <img src={Images.View} alt="view" />
                                                                            </div>
                                                                            <span>{item.viewCount}</span>
                                                                        </div>
                                                                    </NavLink>
                                                                    <div className="mx-3 blog-img-text" onClick={() => handleclick(item.blog_id)} style={{ cursor: "pointer" }}>
                                                                        <div>
                                                                            <img src={Images.Message} alt="message" />
                                                                        </div>
                                                                        <span>{item.messageCount}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        ) : (
                                            <p>No blogs available.</p>
                                        )}
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-12 col-sm-12">
                                    {/* //search bar section */}
                                    <div className="search-bar mb-55">
                                        <label className="text-bold" htmlFor="searchInput">SEARCH</label>
                                        <form className="d-flex search-form" role="search" onSubmit={handleSearch}>
                                            <input
                                                className="form-control"
                                                type="search"
                                                placeholder="Enter a Keyword..."
                                                aria-label="Search"
                                                value={searchQuery}
                                                onChange={(e) => setSearchQuery(e.target.value)} />
                                            <button className="btn search-button" type="submit">
                                                <FontAwesomeIcon className="fa-search" icon={faSearch} />
                                            </button>
                                        </form>
                                    </div>
                                    {/* //welcome to deltas section */}
                                    <div>
                                        <h6 className="mb-25 text-left">welcome to deltas - your trusted partner in herbal and nutraceutical manufacturing</h6>
                                        <p>
                                            At Deltas, we take pride in being recognized as one of the premier herbal and nutraceutical companies in India, specializing in private label solutions. Our commitment to excellence is evident in our ability to address the unique challenges of herbal and nutraceutical product manufacturing setting us apart as a trusted B2B partner.
                                        </p>
                                    </div>
                                    {/* //accordian section */}
                                    <AccordianSection accordionData={accordionData} heading={accordianHeading} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >

        </>
    )
}
export default ManufacturingComponent