import React from "react"
import Images from "../../commonComponents/Images/Images"


const CosmeticProductComponent = () => {

    const images = {
        image1: Images.pureActiveIngredient,
        image2: Images.mineralOil,
        image3: Images.skinFriendly,
        image4: Images.cruetlyFree,
    }

    const data = {
        image1: Images.OurSpeciality1,
        image2: Images.OurSpeciality2,
        image3: Images.OurSpeciality3,
        image4: Images.OurSpeciality4,

    }

    const ourRangecards = [
        {
            Image: Images.CleanFormulations,
            title: <>clean formulations <span>(harmful ingredient free / cosmos compliant)</span></>,
        },
        {
            Image: Images.PersonalCare,
            title: "all natural personal care",
        },
        {
            Image: Images.SensitiveSkincare,
            title: "sensitive skincare",
        },
        {
            Image: Images.HairCare,
            title: "hair care",
        },
        {
            Image: Images.NaturalProduct,
            title: "all natural product",
        },
        {
            Image: Images.Essentials,
            title: "essential / aromatherapy oil based product",
        },
    ]

    return (
        <>
            {/* //formulated with science in mind */}
            <div className="cosmetic-products-science">
                <div className="container">
                    <div className="pxy-40 pt-0 px-0">
                        <div className="row formulated-science">
                            <div className="col-lg-4 col-md-12 col-sm-12 formulated-science-description">
                                <h3 className="text-left py-20 text-capitalize">Formulated with Science in Mind</h3>
                                <p>Right from our inception we are focused on creating cosmetics with care and attention, ensuring they are beneficial for both skin health and our environment.</p>
                            </div>
                            <div className="col-lg-8 col-md-12 col-sm-12 formulated-science-div">
                                <div className="row row-25">
                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className="d-flex">
                                            <div className="cosmetic-img-div">
                                                <img src={images.image1} alt="Pure active ingredient" width={"%"} />
                                            </div>
                                            <div className="service-content">
                                                <div>
                                                    <span className="text-left text-bold">Pure active ingredient</span>
                                                </div>
                                                <div>
                                                    <span className="services-institute-description">Each formula is a blend of active ingredients</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className="d-flex">
                                            <div className="cosmetic-img-div">
                                                <img src={images.image2} alt="Free from Parabens and Mineral Oils" width={"%"} />
                                            </div>
                                            <div className="service-content">
                                                <div>
                                                    <span className="text-left text-bold">Free from Parabens and Mineral Oils</span>
                                                </div>
                                                <div>
                                                    <span className="services-institute-description">Our products are formulated without  ingredients that are
                                                        damaging to both the skin and the environment.
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row row-25">
                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className="d-flex">
                                            <div className="cosmetic-img-div">
                                                <img src={images.image3} alt="Skin - Friendly and pure" width={"%"} />
                                            </div>
                                            <div className="service-content">
                                                <div>
                                                    <span className="text-left text-bold">Skin - Friendly and pure</span> </div>
                                                <div>
                                                    <span className="services-institute-description">Every product we offer is tested for nickel and is safe for the skin</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className="d-flex">
                                            <div className="cosmetic-img-div">
                                                <img src={images.image4} alt="Crutely - Free Ingredients" width={"%"} />
                                            </div>
                                            <div className="service-content">
                                                <div>
                                                    <span className="text-left text-bold">Crutely - Free Ingredients</span> </div>
                                                <div>
                                                    <span className="services-institute-description">We exclusively select ingredients that have not been tested on animals</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >

            {/* //our speciality card section */}
            <div className="services header-bottom-line">
                <h3 className="py-20 text-center">our speciality</h3>
                <div className="mt-70">
                    <div className="pxy-40 cosmetic-products">
                        <div className="container">
                            <div className="row row-50 text-center cosmetic-products card-section-full-img">
                                <div className="col-lg-3 col-md-6 col-sm-12">
                                    <div className="card">
                                        <div>
                                            <div className="img-container-full">
                                                <img src={data.image1} alt="supply-chain1" width={"100%"} />
                                            </div>
                                            <div className="card-body">
                                                <h6 className="py-20 mt-20">Transdermal Phospholipids</h6>
                                                <div>
                                                    <p>Transdermal formulations incorporating phospholipids from natural sources can significantly improve skin permeation. This leads to enhanced bioavailability and effectiveness in delivering active substances to the skin.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12">
                                    <div>
                                        <div className="card">
                                            <div className="img-container-full">
                                                <img src={data.image2} alt="supply-chain2" width={"100%"} />
                                            </div>
                                            <div className="card-body">
                                                <h6 className="py-20 mt-20">Liposomal Anti-Cellulite</h6>
                                                <div>
                                                    <p>Key ingredients like caffeine, retinol, antioxidants, and natural extracts known for their skin-toning abilities are encapsulated in liposomes. This targeted approach ensures that these ingredients are delivered precisely to the areas that need them most.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12 ">
                                    <div className="card">
                                        <div>
                                            <div className="img-container-full">
                                                <img src={data.image3} alt="supply-chain3" width={"100%"} />
                                            </div>
                                            <div className="card-body">
                                                <h6 className="py-20 mt-20">Bio-radiance</h6>
                                                <div>
                                                    <p>Natural peptides are utilised to stimulate collagen production, which is vital for maintaining skin elasticity. This contributes to enhanced skin radiance and offers anti-aging benefits by improving the overall health and appearance of the skin.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12">
                                    <div className="card">
                                        <div>
                                            <div className="img-container-full">
                                                <img src={data.image4} alt="supply-chain4" width={"100%"} />
                                            </div>
                                            <div className="card-body">
                                                <h6 className="py-20 mt-20">Respiratory Skin Foundation</h6>
                                                <div>
                                                    <p>Our natural mineral-based skincare premix is crafted to provide the coverage of a foundation while allowing the skin to breathe. It offers a sun protection factor (SPF) and delivers intense moisturization, maintaining the skin's natural health and appearance.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* //our range card section */}
            <div className="services header-bottom-line container">
                <div className="pxy-40 px-0">
                    <h3 className="py-20 text-center">our range</h3>
                    <div className="mt-70">
                        <div className="row">
                            {ourRangecards.map((obj, index) => (
                                <div className="col-lg-4 col-md-6 col-sm-12">
                                    <div className='card-carousel-main'>
                                        <div className='carousel-card-main services-carousel p-0' key={index}>
                                            <div className='carousel-card-nav card cosmetic-product-card mx-auto'>
                                                <div className="img-container m-0">
                                                    <img src={obj.Image} className='carousel-card-img' width={"100%"} />
                                                </div>
                                                <div className="carousel-content font-family-regular">
                                                    <h6 className='carousel-title text-left py-20'>{obj.title}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div >
            </div >
        </>
    )
}
export default CosmeticProductComponent