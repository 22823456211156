import React, { useState } from "react"
import Images from "../../commonComponents/Images/Images"
import LeadershipTeam from "./LeadershipTeam"

const AboutUs = () => {

    const [showmore1, setShowMore1] = useState(false);
    const [showmore2, setShowMore2] = useState(false);
    const [showmore3, setShowMore3] = useState(false);
    const [showmore4, setShowMore4] = useState(false);


    const toggleShowMoreOrLess1 = () => {
        setShowMore1(!showmore1);
    }
    const toggleShowMoreOrLess2 = () => {
        setShowMore2(!showmore2);
    }
    const toggleShowMoreOrLess3 = () => {
        setShowMore3(!showmore3);
    }
    const toggleShowMoreOrLess4 = () => {
        setShowMore4(!showmore4);
    }


    const data = {
        image1: Images.Aboutus,
        image2: Images.ThumbsUp,
        image3: Images.QualifiedEmployee,
        image4: Images.OnlineSupport,
        image5: Images.PaymentMethods,

    }

    return (
        <>
            {/* //few words about us */}
            <div className="about-us header-bottom-line">
                <div className="container">
                    {/* //our team description */}
                    <div>
                        <div className="mt-70">
                            <div className="header-bottom-line-left">
                                <div className="row">
                                    <div className="col-12">
                                        <h4 className="text-left py-20 font-35">Hello and Welcome to Deltas!</h4>
                                        <p className="mt-28 text-justify font-size-22">Greetings! I genuinely appreciate you taking the time to explore our website. Whether this is your inaugural visit or you're an old companion, I trust you'll find our content both relevant and straightforward.</p>
                                        <p className="font-size-22">At the heart of Deltas, we are deeply passionate about natural product development and the vast realm of the white label business. Through this platform, I aim to offer you a clear perspective on our services and to share the diligent strides we're making in our domain.</p>
                                        <p className="font-size-22">Dive in, explore our offerings, familiarise yourself with our recent projects, meet the dedicated folks behind Deltas, and should you have questions, our FAQ section is well-equipped to assist.</p>
                                        <p className="font-size-22">Building meaningful relationships is fundamental to us. If there's anything you'd like to discuss, seek clarity on, or just share, please don’t hesitate to get in touch. My team and I prioritise ensuring that your experience with us is both informative and seamless.</p>
                                        <p className="font-size-22">Thank you for your interest and time. Stay in the loop for regular updates, and I personally look forward to fostering a fruitful association with you.</p>
                                        <p className="font-size-22">With sincere appreciation, </p>
                                        <p className="font-size-22">Aasin Maurya<br />
                                            Managing Director
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="pxy-75 pt-2">
                        <h3 className="text-center py-20 ">A FEW WORDS ABOUT US</h3>
                        <div className="mt-70">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="mb-20">
                                        <img src={data.image1} alt="about us" width={"100%"} />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <p className="font-size-22 mb-25">Founded in 2007 and headquartered in Mumbai with a state-of-the-art manufacturing unit in Haridwar, Uttarakhand, DeltasPharma India Pvt. Ltd. stands as a beacon of excellence in the world of wellness manufacturing.</p>
                                    <p>As a leading contract manufacturer, we specialise in the crafting of Ayurvedic, natural supplements, and personal care products.</p>
                                    {/* <div className="mt-3">
                                        <button className="btn">LEARN MORE...</button>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* //card section */}
            <div className="pxy-40 aboutus-card-section header-bottom-line">
                <div className="container">
                    <div className="row row-25 text-center card-gap">
                        <div className="col-lg-3 col-md-12 col-sm-12">
                            <div className="d-flex team-services">
                                <img src={data.image2} alt="individual approach" width={"35px"} />
                                <h4 className="py-20 text-left">What We Do</h4>
                            </div>
                            <p className="font-family-latolight text-justify">
                                We transform ideas into realities. Whether you’re an entrepreneur {showmore1 ? (<> or an established brand, we are committed to bringing your wellness vision to life. Our expertise ranges from skincare products and natural cosmetics to Ayurvedic and nutraceutical supplements. We pride ourselves on our adaptability, being able to deliver products in various formats such as capsules, liquids, gels, creams, and lotions.With a production capacity of 100,000 units per day and experience with over 1500 active ingredients, we ensure each product meets the highest standards of quality and safety.
                                </>) : null}
                            </p>
                            <div className="mt-3 text-left">
                                <button className="btn" onClick={toggleShowMoreOrLess1}>{showmore1 ? "Read Less" : "Read More"}</button>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-12 col-sm-12">
                            <div className="d-flex team-services">
                                <img src={data.image3} alt="qualified employee" width={"35px"} />
                                <h4 className="py-20 text-left">Why Choose Us</h4>
                            </div>
                            <p className="font-family-latolight text-justify">Our expert team in phytochemistry and microbiology{showmore2 ? (<> transforms your innovative ideas into real products, managing every step from initial formulation to final manufacturing in our GMP-certified facility. We ensure each product is safe, effective, and beautifully packaged to meet regulatory standards and shine on shelves.For existing products needing enhancements, our 25 years of formulation expertise allows us to swiftly pinpoint and address issues, boosting efficacy and quality with the finest raw materials. We pride ourselves on excellent customer service, offering quick quotes within 72 hours and fast order turnarounds.
                            </>) : null}
                            </p>
                            <div className="mt-3 text-left">
                                <button className="btn" onClick={toggleShowMoreOrLess2}>{showmore2 ? "Read Less" : "Read More"}</button>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-12 col-sm-12">
                            <div className="d-flex team-services">
                                <img src={data.image4} alt="online support" width={"35px"} />
                                <h4 className="py-20 text-left">Our Mission</h4>
                            </div>
                            <p className="font-family-latolight text-justify">At DeltasPharma India Pvt. Ltd., we specialize in developing  {showmore3 ? (<> bespoke, cutting-edge natural wellness products, ensuring excellence in engineering and customer service. We provide swift quotations within 72 hours and guarantee rapid delivery to maintain your business momentum.
                                Our commitment streamlines your product's journey from conception to market, supporting your vision to establish a legacy brand and enhancing your market presence with quality and reliability.
                            </>) : null}
                            </p>
                            <div className="mt-3 text-left">
                                <button className="btn" onClick={toggleShowMoreOrLess3}>{showmore3 ? "Read Less" : "Read More"}</button>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-12 col-sm-12">
                            <div className="d-flex team-services">
                                <img src={data.image5} alt="payment methods" width={"35px"} />
                                <h4 className="py-20 text-left">Our Values</h4>
                            </div>

                            <p className="font-family-latolight text-justify">Efficacy: We enhance the performance and potency of your {showmore4 ? (<> products to ensure they meet their promised results.

                                <p className="font-family-latolight text-justify mt-2"> Usability: We prioritize practicality by streamlining and optimizing our products to be more evidence-based and user-friendly.</p>

                                <p className="font-family-latolight text-justify">Allure: We focus on the initial impact, enhancing the visual and sensory appeal of your products to make them not only effective but also a delightful part of your customers' routines.</p>

                                <p className="font-family-latolight text-justify">Profitability: We keep a sharp eye on market trends to enhance the profitability of your products, ensuring a substantial return on investment.</p>
                            </>) : null}
                            </p>
                            <div className="mt-3 text-left">
                                <button className="btn" onClick={toggleShowMoreOrLess4}>{showmore4 ? "Read Less" : "Read More"}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* //leadership team */}
            <LeadershipTeam />
        </>
    )
}
export default AboutUs