import React from "react"

const TitleWithDescription = ({ title, description1, description2, customClassName }) => {
    return (
        <>
            <div className="title-description header-bottom-line">
                <div className="container">
                    <div className={`pxy-40 px-0 ${customClassName}`}>
                        <h3 className="text-center py-20 ">{title}</h3>
                        <div className="mt-70">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6">
                                    <p className="text-justify">{description1}</p>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6">
                                    <p className="text-justify">{description2}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
export default TitleWithDescription