import React from "react"
import ImgWithDescription from "../../../commonComponents/ImgWithDescription/ImgWithDescription"
import Images from "../../../commonComponents/Images/Images"

const IntimacyEnhacement = ({ titleDescription, titleDescription2, customClassName3 }) => {

    const data = {
        image1: Images.IntimacyEnhancementHer,
        image2: Images.IntimacyEnhancementHim,
        titleDescription: <>At Deltas, we recognize the importance of sexual well-being and pleasure in everyone's life. Our range of intimacy enhancement products is crafted with the utmost care, using natural ingredients to meet the unique needs of both women and men.</>,
        titleDescription2: <>At Deltas, our dedication to intimacy enhancement is rooted in a deep understanding of the different needs and preferences of both women and men. We strive to offer products that not only ensure safety and effectiveness but also enhance the intimate experiences of individuals, contributing to a healthier and more fulfilled sexual life.</>,
    }

    return (
        <>
            {/* //intimacy enhancement for her */}
            <div className="about-us header-bottom-line">
                <div className="container">
                    <div className="pxy-40 px-0">
                        <h3 className="text-center py-20 ">intimacy enhancement</h3>
                        {/* <div className="mt-70"> */}
                        <div className={`service-description-card ${data.titleDescription ? 'mt-40' : (customClassName3 === 'mt-0' ? 'mt-0' : 'mt-70')}`}>
                            {data.titleDescription && <p className="mb-40">{data.titleDescription}</p>}
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="mb-20">
                                        <img src={data.image1} alt="about us" width={"100%"} />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12 row justify-content-center">
                                    <h4 className="text-capitalize">For Her</h4>
                                    <p className="">For Her: Emphasizing Women's Sexual Well-being<br /> Our products for women include arousal gels, personal lubricants, and massage oils, all infused with natural botanical extracts, essential oils, and vitamins. These formulations are designed to enhance sensitivity, stimulate arousal, and enrich the overall intimate experience. We focus on catering to the diverse desires and needs of women, ensuring that our products contribute to their holistic well-being and fulfillment in intimate settings.</p>
                                </div>
                            </div>
                        </div>
                        {/* //intimacy enhancement for him */}
                        <div className="mt-40">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-12 row justify-content-center">
                                    <h4 className="text-capitalize">For Him</h4>
                                    <p className="">For Him: Focusing on Men's Sexual Health<br /> Our range for men includes performance supplements and topical applications, targeting various aspects of male sexual health. We use natural ingredients like herbal extracts and amino acids to support increased stamina and improved blood flow, enhancing overall performance. Our commitment is to provide thoughtful and diverse solutions that support the sexual well-being of men.</p>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="mb-20">
                                        <img src={data.image2} alt="about us" width={"100%"} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {data.titleDescription2 && <p className="mt-40">{data.titleDescription2}</p>}

                    </div>
                </div>
            </div>
        </>
    )
}
export default IntimacyEnhacement