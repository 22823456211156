import React from "react"
import NavigationBar from "../../../commonComponents/NavigationBar/NavigationBar"
import Banner from "../../../commonComponents/Banner/Banner"
import Footer from "../../../commonComponents/Footer/Footer"
import Images from "../../../commonComponents/Images/Images"
import ImgWithDescription from "../../../commonComponents/ImgWithDescription/ImgWithDescription"
import KeyPointComponent from "../../../commonComponents/KeyPointComponent/KeyPointComponent"

const ResearchProjectsPage = () => {

    const data = {
        image: Images.ResearchProjectsBanner,
        title: "research projects",
    }

    const data2 = {
        image: Images.PioneeringFuture,
        heading: <> deltas: pioneering the future<br /> of well-being and sustainability </>,
        // title: "Embracing Challenges with Innovative Spirit:",
        subtitle1: "Embracing Challenges with Innovative Spirit:",
        description: "At Deltas, the essence of our work lies in our unwavering commitment to research, innovation, and experimentation. New challenges are not hurdles but catalysts that propel us to explore and invent novel solutions. Our focus remains steadfast on enhancing human well-being and championing environmental sustainability.",
    }

    const data3 = {
        title: "key projects at Deltas:",
        description1: <>
            <h6 className="text-capitalize">
                Project "Age - related Macular Degeneration (DMLE)" -<br /> A Visionary Endeavor.
            </h6>
            <div className="mb-2">
                <p><strong >Objective: </strong>This project is dedicated to developing an advanced food supplement incorporating organic lycopene and other antioxidants. The synergy of these components aims to mitigate oxidative stress and reduce the risk of DMLE. </p>
            </div>
            <div className="mb-2">
                <p><strong >Innovation and Impact: </strong>The product conceptualised by Deltas is a groundbreaking dietary aid, offering ophthalmologists a new tool in the battle against DMLE. It stands as a testament to our commitment to addressing crucial health issues through innovative solutions. </p>
            </div>
            <h6 className="text-capitalize">
                Probiotic - Based Skin Care Project:
            </h6>
            <div className="mb-2">
                <p><strong >Exploratory Approach: </strong>Deltas ventures into the realm of skincare with a probiotic-based project, symbolising a fusion of nature and science. </p>
            </div>
        </>,
        description2: <>
            <div className="mb-2">
                <p><strong >Phases of Development: </strong></p>
                <ul className="list-items">
                    <li>Proof of Concepts: Establishing foundational ideas and potential efficacy.</li>
                    <li>Flavour Variant Development: Crafting a range of appealing flavours, aligning with consumer preferences.</li>
                    <li>Literature-Based Research: Grounding our development in scientific evidence and existing studies.</li>
                    <li>Preclinical Studies: Rigorous testing in controlled environments to ensure safety and effectiveness.</li>
                    <li>Clinical Study: Comprehensive trials to validate the product's benefits and usability.</li>
                </ul>

            </div >

            <div className="mb-2">
                <p>At Deltas, we're not just creating products; we constantly think of a future where health and sustainability converge, Join us on this journey of discovery and advancement.</p>
            </div>
        </>,
    }

    return (
        <>
            <NavigationBar />
            <Banner bannerImg={data.image} title={data.title} content={data.content} customClassName="researchprojects-page-banner-css" />
            <ImgWithDescription {...data2} />
            <KeyPointComponent {...data3} customClassName="pt-0" />
            <Footer />
        </>
    )
}
export default ResearchProjectsPage