import React from "react"
import Images from "../../commonComponents/Images/Images"

const AboutOurProducts = () => {

    const data = Images.AboutOurProducts

    return (
        <>
            {/* //about our products homepage */}
            <div className="about-ourproducts header-bottom-line">
                <div className="container">
                    <div className="pxy-40">
                        <h3 className="text-center py-20 ">ABOUT OUR PRODUCTS</h3>
                        <div className="mt-70">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="mb-20">
                                        <img src={data} alt="about our products" width={"100%"} />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <p className="font-size-22">At Deltas, the concept of traditional science is substantiated with evidence based innovative technologies. For over 20 years, in fact, we believe that Research and Innovation are the essential “ingredients” to develop products able to improve the Wellbeing of people.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default AboutOurProducts