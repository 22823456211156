import React from "react"
import Images from "../../commonComponents/Images/Images"
import CardsWithCarousel from "../../commonComponents/CardsWithCarousel/CardsWithCarousel"
import ImgWithDescription from "../../commonComponents/ImgWithDescription/ImgWithDescription"
import CardSection from "../../commonComponents/CardSection/CardSection"
import OurProcessComponent from "./OurProcessComponent"


const ServicePageComponent = () => {

    const data = {
        image: Images.pioneeringExcellence,
        heading: <>welcome to deltas: <br /> your gateway to business success</>,
        title: "25 Years of Pioneering Excellence:",
        // subtitle: "5 Years of Pioneering Excellence:",
        description: "At Deltas, we pride ourselves on our rich 25-year history in the realms of business, product development, manufacturing, and marketing. Our expertise is your launchpad to rapidly bring your business idea to fruition. While we meticulously handle product design, manufacturing, and order fulfillment, you can channel your energies into what truly matters - marketing and selling your unique product.",
    }


    const heading = "how deltas elevates your brand"
    const articlesData = [
        {
            title: <>custom <br />creation</>,
            text: 'Our team of skilled scientists and herbal experts are ready to craft custom formulations or reverse-engineer existing products, tailoring them specifically for your brand.',
            image: Images.creation,
            showButton: false,
        },

        {
            title: 'efficient manufacturing',
            text: 'Our state-of-the-art, controlled environment laboratory and production facility guarantees the creation and testing of your products within a two-week period, ensuring top-tier quality that your customers can trust.',
            image: Images.manufacturing,
            showButton: false,

        },

        {
            title: 'Eye- catching packaging',
            text: 'We offer an array of container choices in various shapes, sizes, and colours, complete with custom labelling options, all set for your branding.',
            image: Images.packaging,
            showButton: false,

        },
        {
            title: 'professional brand design',
            text: 'Benefit from our comprehensive branding services, including logo design, website support, and content development, complemented by access to our trusted marketing and website management partners.',
            image: Images.brandDesign,
            showButton: false,

        },
        {
            title: <>Timely<br /> custom<br /> formulations</>,
            text: 'Selecting Deltas means access to a vast array of products, knowledgeable consultants, and unique ingredients. We promise to meet your specific formulation needs with exceptional service.',
            image: Images.formulation,
            showButton: false,

        },
        {
            title: 'innovative flavouring solutions',
            text: 'Our unique Natural and Organic Non-Repetitive System, featuring a proprietary Flavoring Base Triangle, allows us to create custom, unmatched flavours, ensuring a delightful taste experience for your customers.',
            image: Images.solutions,
            showButton: false,

        },

    ];

    return (
        <>
            {/* //your gateway to business success */}
            <ImgWithDescription {...data} />

            {/* //card with carousel component */}
            <CardsWithCarousel customClassName="px-0 pt-0" />

            {/* //elevates your brands */}
            <CardSection articlesData={articlesData} heading={heading} customClassName2="min-height-aricle-card" />

            {/* //our process  */}
            <OurProcessComponent />
        </>
    )
}
export default ServicePageComponent