import React from "react"
import ImgWithDescription from "../../../commonComponents/ImgWithDescription/ImgWithDescription"
import Images from "../../../commonComponents/Images/Images"

const BabyCareProducts = ({ customClassName, title }) => {

    const data1 = {
        image: Images.BabyCareImg1,
        heading: "baby care products",
        description: <><p>Launch Your Brand with Our Premium Herbal Baby Care Products. Introducing our exclusive range of Herbal Baby Care Products, now available for private labeling. This line is a fusion of exceptional quality and utmost care, designed to offer the best natural solutions for baby's sensitive skin. Our products, ranging from soothing herbal lotions to mild shampoos and gentle diaper creams, are formulated with botanical extracts renowned for their calming and nourishing properties.</p>
        </>,
    }

    const data2 = {
        image: Images.BabyCareImg2,
        description: <><p>Why Choose Our Herbal Baby Care Range for Private Labeling?<br /> Custom Branding: Our private labeling option allows you to create a unique brand that aligns with your vision. Your brand, powered by our quality products, offers a perfect blend to assure parents of the best care for their babies. High-Quality Standards: We adhere to stringent manufacturing standards, focusing on safety, purity, and efficacy. Every product in our baby care line meets the highest quality benchmarks. Natural and Gentle: The herbal ingredients in our products are carefully selected to ensure they are gentle and safe for the delicate skin of babies. Trusted Herbal Solutions: By partnering with us, you provide parents with a trusted herbal alternative that caters to the tender needs of their little ones.</p>
        </>,
        titleDescription2: <>Embrace this opportunity to elevate your brand with our Herbal Baby Care Products. Offer parents a natural, gentle, and loving care solution for their babies under your brand name. Partner with us to create a private label experience that showcases your commitment to nature, nurture, and the well-being of the youngest members of the family.</>,
    }


    return (
        <>
            {/* //right side image of baby care products section */}

            <div className="service header-bottom-line">
                <div className="container">
                    <div className="pxy-40 px-0">
                        {data1.heading && <h3 className="text-center py-20">{data1.heading}</h3>}
                        <div className="mt-70 service-description-card">
                            <div className="row mx-auto">
                                <div className={`col-lg-6 col-md-12 col-sm-12 service-description ${customClassName}`}>
                                    {title && <h3 className="text-left py-20">{title}</h3>}
                                    <p>{data1.description}</p>
                                </div>
                                <div className="col-lg-6 col-md-12 col-sm-12 service-image-card">
                                    <div className="">
                                        <img src={data1.image} alt="image" width={"100%"} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ImgWithDescription {...data2} customClassName2="pt-0" customClassName3="mt-0" />

        </>
    )
}
export default BabyCareProducts