import React, { useState, useEffect, useRef } from "react"
import Form from 'react-bootstrap/Form';
import axios from "axios"
import { ToastContainer, toast } from 'react-toastify'


const RFQComponent = () => {

    const [selection1, setSelection1] = useState(true);
    const [selection2, setSelection2] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const [errors, setErrors] = useState({});
    const [dosage, setDosage] = useState([]);
    const [formulation, setFormulation] = useState([]);
    const [products, setProducts] = useState([{ product_name: '', formulation_id: '', dosage_id: '' }]);
    const [productErrors, setProductErrors] = useState(Array.from({ length: products.length }, () => ({})));
    const [formData, setFormData] = useState({
        first_name: "",
        last_name: "",
        company: "",
        mail: "",
        phone_number: "",
        website_url: "",
        // productquantity: "",
        // percontainer: "",
        // manufacturing: "",
        // packaging: "",
        production_status: true,
        product_exported: true,
        // timefrmaeManufacturing: "",
        // storage: "",
        order_details: "",
        products: []
    })

    const refs = {
        first_name: useRef(null),
        last_name: useRef(null),
        company: useRef(null),
        mail: useRef(null),
        phone_number: useRef(null),
        website_url: useRef(null),
        order_details: useRef(null),
    };

    const handleRadioChange1 = (event) => {
        const value = (event.target.value === "Yes");
        setSelection1(value);
        setFormData({
            ...formData,
            status: value ? "1" : "0"
        });
    };

    const handleRadioChange2 = (event) => {
        const value = (event.target.value === "Yes");
        setSelection2(value);
        setFormData({
            ...formData,
            status: value ? "1" : "0"
        });
    };

    const handleProductChange = (index, field, value) => {
        const newProducts = [...products];
        newProducts[index][field] = value;
        setProducts(newProducts);
        clearProductError(index, field);
        // console.log(newProducts);

        // Update formData with the new products array
        setFormData({
            ...formData,
            products: newProducts
        });
    };

    const clearProductError = (index, field) => {
        const newProductErrors = [...productErrors];
        newProductErrors[index] = {
            ...newProductErrors[index],
            [field]: ''
        };
        setProductErrors(newProductErrors);
    };

    const handleAddProduct = () => {
        const lastProduct = products[products.length - 1];
        if (lastProduct.product_name && lastProduct.formulation_id && lastProduct.dosage_id) {
            setProducts([...products, { product_name: '', formulation_id: '', dosage_id: '' }]);
            setProductErrors([...productErrors, {}]);
        }
    };

    const isProductEmpty = (product) => {
        return !product.product_name.trim() || !product.formulation_id.trim() || !product.dosage_id.trim();
    };

    const isCurrentProductEmpty = () => {
        const lastProduct = products[products.length - 1];
        return isProductEmpty(lastProduct);
    };

    const isAnyProductEmpty = () => {
        return products.some(isProductEmpty);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const apiUrl = process.env.REACT_APP_API_BASE_URL;

                const dosageListEndPoint = '/get_dosage';
                const formulationListEndPoint = '/get_formulation';

                const dosageListUrl = `${apiUrl}${dosageListEndPoint}`;
                const formulationListUrl = `${apiUrl}${formulationListEndPoint}`;

                const [dosageResponse, formulationResponse] = await Promise.all([
                    axios.get(dosageListUrl),
                    axios.get(formulationListUrl)
                ]);

                if (dosageResponse.data.status === 1 && dosageResponse.data.code === 200) {
                    setDosage(dosageResponse.data.data);
                } else {
                    console.error("Error fetching dosages:", dosageResponse.data.message);
                }

                if (formulationResponse.data.status === 1 && formulationResponse.data.code === 200) {
                    setFormulation(formulationResponse.data.data);
                } else {
                    console.error("Error fetching formulations:", formulationResponse.data.message);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);


    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
        clearError(name);
    };

    const handleSelectChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
        clearError(name);
    };

    const clearError = (name) => {
        // console.log("errr", name)

        const newErrors = { ...errors };
        delete newErrors[name];
        setErrors(newErrors);
    };

    // handle submit of RFQ form 
    const handleSubmit = (event) => {
        event.preventDefault();
        console.log(validateForm(), "Form data before submission:", formData);

        if (validateForm()) {
            setSubmitting(true);

            // Prepare data to be sent
            const requestData = {
                first_name: formData.first_name,
                last_name: formData.last_name,
                company: formData.company,
                mail: formData.mail,
                phone_number: formData.phone_number,
                website_url: formData.website_url,
                production_status: formData.production_status ? "1" : "0",
                product_exported: formData.product_exported ? "1" : "0",
                order_details: formData.order_details,
                products: formData.products.map(product => ({
                    product_name: product.product_name,
                    formulation_id: product.formulation_id,
                    dosage_id: product.dosage_id
                }))
            };


            // API integration of RFQ
            const apiUrl = process.env.REACT_APP_API_BASE_URL;
            const RFQEndPoint = '/request_quote';
            const RFQUrl = `${apiUrl}${RFQEndPoint}`;

            axios.post(RFQUrl, requestData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then((res) => {
                    if (res.data.status === 1 && res.data.code === 200) {
                        resetForm();
                        toast.success('Form submitted successfully!');
                        setSubmitting(false);
                    } else {
                        console.error("Error:", res.data.message);
                        toast.error('Form submission failed.');
                        setSubmitting(false);
                    }
                })
                .catch((error) => {
                    // console.error("Error submitting form", error);
                    toast.error('Form submission failed.');
                    setSubmitting(false);
                })

                .finally(() => {
                    setSubmitting(false);
                });
        } else {
            // console.log('Hello')
        }
    };

    useEffect(() => {
        if (Object.keys(errors).length > 0) {
            const firstProductError = Object.keys(errors)[0];
            if (firstProductError && refs[firstProductError]) {
                refs[firstProductError].current.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [errors, refs]);



    const validateForm = () => {
        let formIsValid = true;
        const newErrors = {};

        // Check if any field is empty
        Object.keys(formData).forEach((key) => {
            if (!formData[key]) {
                newErrors[key] = "This field is required.";
                formIsValid = false;
            }
        });

        // Validate each product
        const newProductErrors = [...productErrors];
        products.forEach((product, index) => {
            const productError = {};
            if (!product.product_name.trim()) {
                productError.product_name = "Product name is required.";
                formIsValid = false;
            }
            if (!product.formulation_id.trim()) {
                productError.formulation_id = "Formulation type is required.";
                formIsValid = false;
            }
            if (!product.dosage_id.trim()) {
                productError.dosage_id = "Dosage is required.";
                formIsValid = false;
            }
            newProductErrors[index] = productError;
        });

        setProductErrors(newProductErrors);


        // Check if any product field is empty
        products.forEach((product, index) => {
            if (isProductEmpty(product)) {
                newErrors[`product_${index}`] = "Please fill in all fields for this product.";
                formIsValid = false;
            }
        });

        // checkSelectField('formulation_type');
        // checkSelectField('dosage');
        // checkSelectField('productquantity');
        // checkSelectField('percontainer');
        // checkSelectField('manufacturing');
        // checkSelectField('packaging');
        // checkSelectField('timefrmaeManufacturing');
        // checkSelectField('storage');

        // Check phone number format
        const phoneRegex = /^\d{10}$/;
        if (!phoneRegex.test(formData.phone_number)) {
            newErrors.phone_number = "Please enter a valid phone number.";
            formIsValid = false;
        }

        // Check email format
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(formData.mail)) {
            newErrors.mail = "Please enter a valid email address.";
            formIsValid = false;
        }


        setErrors(newErrors);
        return formIsValid;
    };

    const resetForm = () => {
        setFormData({
            first_name: "",
            last_name: "",
            company: "",
            mail: "",
            phone_number: "",
            website_url: "",
            // product_name: "",
            // formulation_type: "",
            // dosage: "",
            productquantity: "",
            percontainer: "",
            manufacturing: "",
            packaging: "",
            production_status: true,
            product_exported: true,
            timefrmaeManufacturing: "",
            storage: "",
            order_details: "",
            products: [],
        });
        setSelection1(true);
        setSelection2(true);
        setProducts([{ product_name: '', formulation_id: '', dosage_id: '' }]);
    };



    return (
        <>
            <div className="rfq header-bottom-line">
                <div className="container">
                    <div className="pxy-40">
                        <h3 className="text-center py-20 ">request a quote</h3>
                        <div className="mt-70">
                            <div className="row">
                                <div className="col-lg-1">
                                </div>
                                <div className="col-lg-10 vendor-enquiry-form">
                                    <p className="text-bold font-size-28">
                                        Your Contact Information
                                    </p>
                                    <form onSubmit={handleSubmit} className="mt-40">
                                        <div className="row row-5">
                                            <div className="col-lg-6 col-md-12 col-sm-12">
                                                <label>First Name*</label>
                                                <input
                                                    type="text"
                                                    name="first_name"
                                                    value={formData.first_name}
                                                    onChange={handleChange}
                                                    ref={refs.first_name}

                                                />
                                                {errors.first_name && <p className="error-message">{errors.first_name}</p>}

                                            </div>

                                            <div className="col-lg-6 col-md-12 col-sm-12">
                                                <label>Last Name*</label>
                                                <input
                                                    type="text"
                                                    name="last_name"
                                                    value={formData.last_name}
                                                    onChange={handleChange}
                                                    ref={refs.last_name}

                                                />
                                                {errors.last_name && <p className="error-message">{errors.last_name}</p>}

                                            </div>

                                            <div className="col-lg-6 col-md-12 col-sm-12">
                                                <label>Company Name*</label>
                                                <input
                                                    type="text"
                                                    name="company"
                                                    value={formData.company}
                                                    onChange={handleChange}
                                                    ref={refs.company}
                                                />
                                                {errors.company && <p className="error-message">{errors.company}</p>}

                                            </div>

                                            <div className="col-lg-6 col-md-12 col-sm-12">
                                                <label>Email* </label>
                                                <input
                                                    type="email"
                                                    name="mail"
                                                    value={formData.mail}
                                                    onChange={handleChange}
                                                    ref={refs.mail}
                                                />
                                                {errors.mail && <p className="error-message">{errors.mail}</p>}

                                            </div>

                                            <div className="col-lg-6 col-md-12 col-sm-12">
                                                <label>Phone Number*</label>
                                                <input
                                                    type="text"
                                                    name="phone_number"
                                                    value={formData.phone_number}
                                                    onChange={handleChange}
                                                    ref={refs.phone_number}
                                                />
                                                {errors.phone_number && <p className="error-message">{errors.phone_number}</p>}

                                            </div>

                                            <div className="col-lg-6 col-md-12 col-sm-12">
                                                <label>Website URL</label>
                                                <input
                                                    type="text"
                                                    name="website_url"
                                                    value={formData.website_url}
                                                    onChange={handleChange}
                                                    ref={refs.website_url}
                                                />
                                                {errors.website_url && <p className="error-message">{errors.website_url}</p>}

                                            </div>
                                            <div>
                                                <div className="row" >
                                                    {products.map((product, index) => (
                                                        <>
                                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                                <label>Product Name</label>
                                                                <input
                                                                    type="text"
                                                                    name="product_name"
                                                                    value={product.product_name}
                                                                    onChange={(e) => handleProductChange(index, "product_name", e.target.value)}
                                                                // ref={refs[`product_${index}`]}
                                                                />
                                                                {productErrors[index].product_name && <p className="error-message">{productErrors[index].product_name}</p>}
                                                            </div>
                                                            <div className="col-lg-6 col-md-12 col-sm-12">
                                                                <label>Formulation Type</label>
                                                                <Form.Select
                                                                    aria-label="Default select example"
                                                                    defaultValue="select"
                                                                    className="capabilities"
                                                                    name="formulation_id"
                                                                    value={product.formulation_id}
                                                                    data-index={index}
                                                                    onChange={(e) => handleProductChange(index, "formulation_id", e.target.value)}
                                                                // ref={refs[`formulation_id_${index}`]}
                                                                >
                                                                    <option value="select">Please Select</option>
                                                                    {formulation.map(formulation => (
                                                                        <option key={formulation.id} value={formulation.id}>{formulation.formulation_name}</option>
                                                                    ))}
                                                                </Form.Select>
                                                                {productErrors[index].formulation_id && <p className="error-message">{productErrors[index].formulation_id}</p>}
                                                            </div>
                                                            <div className="col-lg-6 col-md-12 col-sm-12">
                                                                <label>Dosage</label>
                                                                <Form.Select
                                                                    aria-label="Default select example"
                                                                    defaultValue="select"
                                                                    className="capabilities"
                                                                    name="dosage_id"
                                                                    value={product.dosage_id}
                                                                    data-index={index}
                                                                    onChange={(e) => handleProductChange(index, "dosage_id", e.target.value)}
                                                                // ref={refs[`dosage_id_${index}`]}
                                                                >
                                                                    <option value="select">Please Select</option>
                                                                    {dosage.map(dosage => (
                                                                        <option key={dosage.id} value={dosage.id}>{dosage.dosage_name}</option>
                                                                    ))}
                                                                </Form.Select>
                                                                {productErrors[index].dosage_id && <p className="error-message">{productErrors[index].dosage_id}</p>}
                                                            </div>
                                                        </>
                                                    ))}
                                                    <div className="my-20 col-lg-6 col-md-12 col-sm-12">
                                                        <label>Add Multiple Products</label>
                                                        <button type="button" className="btn" onClick={handleAddProduct}
                                                            disabled={isCurrentProductEmpty()}>+</button>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-md-12 col-sm-12">
                                                <label>Desired Finished product quantity*</label>

                                                <Form.Select name="productquantity" aria-label="Default select example" onChange={handleSelectChange}>
                                                    <option value="select">Please Select</option>
                                                    <option value="option1">Option 1</option>
                                                    <option value="option2">Option 2</option>
                                                    <option value="option3">Option 3</option>
                                                </Form.Select>
                                                {errors.productquantity && <p className="error-message">{errors.productquantity}</p>}

                                            </div>


                                            <div className="col-lg-6 col-md-12 col-sm-12">
                                                <label>Daily saving per container</label>

                                                <Form.Select name="percontainer" aria-label="Default select example" onChange={handleSelectChange}>
                                                    <option value="select">Please Select</option>
                                                    <option value="option1">Option 1</option>
                                                    <option value="option2">Option 2</option>
                                                    <option value="option3">Option 3</option>
                                                </Form.Select>
                                                {errors.percontainer && <p className="error-message">{errors.percontainer}</p>}

                                            </div>


                                            <div className="col-lg-6 col-md-12 col-sm-12">
                                                <label>Time frame to start manufacturing*</label>

                                                <Form.Select name="manufacturing" aria-label="Default select example" onChange={handleSelectChange}>
                                                    <option value="select">Please Select</option>
                                                    <option value="option1">Option 1</option>
                                                    <option value="option2">Option 2</option>
                                                    <option value="option3">Option 3</option>
                                                </Form.Select>
                                                {errors.manufacturing && <p className="error-message">{errors.manufacturing}</p>}

                                            </div>


                                            <div className="col-lg-6 col-md-12 col-sm-12">
                                                <label>For packaging I want</label>

                                                <Form.Select name="packaging" aria-label="Default select example" onChange={handleSelectChange}>
                                                    <option value="select">Please Select</option>
                                                    <option value="option1">Option 1</option>
                                                    <option value="option2">Option 2</option>
                                                    <option value="option3">Option 3</option>
                                                </Form.Select>
                                                {errors.packaging && <p className="error-message">{errors.packaging}</p>}

                                            </div>



                                            {/* Select an option in dropdown  */}
                                            <div className="RFQoption col-lg-12 col-md-12 col-sm-12 my-20">
                                                <div className="RFQflex radio-button-division ">
                                                    <p className="RFQques">Is this product currently in production?* </p>
                                                    <div className="d-flex align-items-center">
                                                        <div className="form-check">
                                                            <input className="form-check-input radio-btn"
                                                                type="radio"
                                                                name="productAvailable"
                                                                id="productAvailableYes"
                                                                value="Yes"
                                                                checked={selection1}
                                                                onChange={handleRadioChange1}
                                                            />
                                                            <label class="form-check-label radio-label" htmlFor="flexRadioDefault1">
                                                                Yes /
                                                            </label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input className="form-check-input radio-btn"
                                                                type="radio"
                                                                name="productAvailable"
                                                                id="productAvailableNo"
                                                                value="No"
                                                                checked={!selection1}
                                                                onChange={handleRadioChange1}
                                                            />
                                                            <label class="form-check-label radio-label" htmlFor="flexRadioDefault2">
                                                                No
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="radio-button-division ">
                                                    <p className="RFQques">Does this product need to be exported outside? </p>
                                                    <div className="d-flex align-items-center">
                                                        <div className="form-check">
                                                            <input className="form-check-input radio-btn"
                                                                type="radio"
                                                                name="productExported"
                                                                id="productExportedYes"
                                                                value="Yes"
                                                                checked={selection2}
                                                                onChange={handleRadioChange2}
                                                            />
                                                            <label class="form-check-label radio-label" htmlFor="flexRadioDefault3">
                                                                Yes /
                                                            </label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input className="form-check-input radio-btn"
                                                                type="radio"
                                                                name="productExported"
                                                                id="productExportedNo"
                                                                value="No"
                                                                checked={!selection2}
                                                                onChange={handleRadioChange2}
                                                            />
                                                            <label className="form-check-label radio-label" htmlFor="flexRadioDefault4">
                                                                No
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12">
                                                <label>Time frame to start manufacturing*</label>
                                                <Form.Select name="timefrmaeManufacturing" aria-label="Default select example" onChange={handleSelectChange}>
                                                    <option value="select">Desired Label Claim</option>
                                                    <option value="option1">Option 1</option>
                                                    <option value="option2">Option 2</option>
                                                    <option value="option3">Option 3</option>
                                                </Form.Select>
                                                {errors.timefrmaeManufacturing && <p className="error-message">{errors.timefrmaeManufacturing}</p>}

                                            </div>

                                            <div className="col-lg-6 col-md-12 col-sm-12">
                                                <label>Storage / Fullfillment</label>

                                                <Form.Select name="storage" aria-label="Default select example" onChange={handleSelectChange}>
                                                    <option value="select">Please Select</option>
                                                    <option value="option1">Option 1</option>
                                                    <option value="option2">Option 2</option>
                                                    <option value="option3">Option 3</option>
                                                </Form.Select>
                                                {errors.storage && <p className="error-message">{errors.storage}</p>}

                                                <span>Deltas has the FAQ registered, climate control warehouses specifically made for vitamins & supplements. We have capabilities to store your bulk product safely. We also offer product fullfillment to take care of shipping out orders from your customers every day, as they come into your e-commerce shipping cart, Amazon FBM, etc.</span>
                                            </div>


                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                <label>Order Details*</label>
                                                <textarea
                                                    type="text"
                                                    placeholder="Include as many details as possible about order here"
                                                    name="order_details"
                                                    value={formData.order_details}
                                                    onChange={handleChange}
                                                    ref={refs.order_details}
                                                />
                                                {errors.order_details && <p className="error-message">{errors.order_details}</p>}

                                            </div>
                                        </div>
                                        <div className="mt-30 text-center">
                                            <button className="btn" type="submit" disabled={submitting}> {submitting ? "Submitting..." : "SUBMIT"}</button>
                                        </div>
                                    </form>
                                </div>
                                <div className="col-lg-1">
                                </div>
                            </div>
                        </div>
                    </div>
                    <ToastContainer />
                </div>
            </div>
        </>
    )
}
export default RFQComponent