import React, { useEffect, useState } from "react"
import { NavLink, useNavigate } from "react-router-dom"
import Nav from 'react-bootstrap/Nav';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import Images from "../../../commonComponents/Images/Images";
// import { NavLink } from "react-bootstrap"
import axios from "axios"
import SkinCareProdctDescription from "./SkinCareProductDescription";
import PaginationComponent from "../../../commonComponents/Pagination/PaginationComponent";

const SkinCareAndCosmeticProducts = () => {

    const [stockProducts, setStockProducts] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showDescription, setShowDescription] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageCount, setPageCount] = useState(1);
    const navigate = useNavigate();




    // const stockProducts = [
    //     {
    //         id: 1,
    //         category_name: "DIETARY SUPPLEMENTS",
    //         product_image: Images.ProductImg1,
    //         name: "Slimwin Tablet",
    //         count: <span className="font-family-latolight">60 Tablets</span>,
    //         button: "READ MORE...",
    //     },
    //     {
    //         id: 2,
    //         category_name: "PAIN RELIEVERS",
    //         product_image: Images.ProductImg2,
    //         name: "Somiwin Tablet",
    //         count: <span className="font-family-latolight">60 Tablets</span>,
    //         button: "READ MORE...",

    //     },
    //     {
    //         id: 3,
    //         category_name: "HERBAL FORMULAS",
    //         product_image: Images.ProductImg3,
    //         name: "Diawon Tablet",
    //         count: <span className="font-family-latolight">60 Tablets</span>,
    //         button: "READ MORE...",

    //     },
    //     {
    //         id: 4,
    //         category_name: "DIGESTIVE ENZYMES",
    //         product_image: Images.ProductImg4,
    //         name: "Gynowin Tablet",
    //         count: <span className="font-family-latolight">60 Tablets</span>,
    //         button: "READ MORE...",

    //     },
    //     {
    //         id: 5,
    //         category_name: "WEIGHT LOSS",
    //         product_image: Images.ProductImg5,
    //         name: "Somiwin Tablet",
    //         count: <span className="font-family-latolight">60 Tablets</span>,
    //         button: "READ MORE...",

    //     },
    //     {
    //         id: 6,
    //         category_name: "PAIN RELIEVERS",
    //         product_image: Images.ProductImg6,
    //         name: "Psorawin Tablet",
    //         count: <span className="font-family-latolight">60 Tablets</span>,
    //         button: "READ MORE...",

    //     },

    // ]






    //api integration of our stock products homepage

    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    const stockProductsEndPoint = '/fetch_stock_products';
    const stockProductsUrl = `${apiUrl}${stockProductsEndPoint}`;


    const fetchStockProducts = (query = "", page = 1) => {
        setLoading(true);
        let url = `${stockProductsUrl}?search=${query}`;
        if (page) {
            url += `&page=${page}`;
        }
        // let url = `${stockProductsUrl}?search=${query}&formulation_type=${type}`;

        axios.get(url).then((res) => {

            //     if (res.data.status === 0 || res.data.message === "No records found") {
            //         setStockProducts([]);
            //     } else {
            //         setStockProducts(res.data.data);
            //     }
            //     setLoading(false);
            // })

            if (res.data.status === 1) {
                // Filter the data to include only thumbnails
                const filteredData = res.data.data.filter(item => item.master_type === "product" && item.file_category === "thumbnail");
                // Set the filtered thumbnails to state
                setStockProducts(filteredData);
                setPageCount(res.data.total_no_of_pages);
                setLoading(false);
            } else {
                setStockProducts([]);
                setPageCount(1);
                setLoading(false);
            }
        })
            .catch((error) => {
                setError("Error fetching cosmetic products data.");
                setLoading(false);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    useEffect(() => {
        fetchStockProducts(searchQuery, currentPage);
    }, [currentPage, searchQuery]);

    const handlePageClick = (data) => {
        setCurrentPage(data.selected + 1);
    };

    const handleImageClick = () => {
        setShowDescription(true);
    }


    return (
        <>
            {/* //our stock products */}
            <div className="skincare-cosmeticproducts-section header-bottom-line">
                <h3 className="text-center py-20 ">advance skin care & cosmetic products</h3>
                <div className="skincare-cosmeticproducts mt-70">
                    <div className="pxy-40">
                        <div className="product-list">
                            <div className="container">
                                <div className="row align-items-center justify-content-end mb-25">
                                    <div className="col-lg-6 col-md-12 col-sm-12">
                                        <div className="search-bar mb-55">
                                            <label className="text-bold" htmlFor="searchInput">SEARCH</label>
                                            <form className="d-flex search-form mt-28" role="search" onSubmit={(e) => e.preventDefault()}>
                                                <input
                                                    className="form-control"
                                                    type="search"
                                                    placeholder="Enter a Keyword..."
                                                    aria-label="Search"
                                                    value={searchQuery}
                                                    onChange={(e) => setSearchQuery(e.target.value)}
                                                />
                                                <button className="btn search-button" type="submit">
                                                    <FontAwesomeIcon className="fa-search" icon={faSearch} />
                                                </button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className="row row-30">
                                    {loading ? (
                                        <p>Loading...</p>
                                    ) : error ? (
                                        <p>{error}</p>
                                    ) : stockProducts.length > 0 ? (
                                        stockProducts.map((product) => (
                                            <div className="col-lg-3 col-md-4 col-sm-6 mb-30" >
                                                <NavLink to={`/product/product-description/${product.product_id}`}>
                                                    <div className='card-carousel-main our-products-hover product'>
                                                        <div className='services-carousel ' key={product.product_id}>
                                                            <div className="card ">
                                                                <h6 className="text-center products-title">{product.category_name}</h6>
                                                                <div className="card-body">
                                                                    <div className='card-nav font-family-regular'>
                                                                        <div className="img-div product-img-div">
                                                                            <img src={product.file_path} className='carousel-card-img' width={"100%"} />
                                                                        </div>
                                                                        <p className='carousel-title text-center product-title-ht'>{product.name}</p>
                                                                        <p className='carousel-title text-center'>{product.count}</p>
                                                                        <div className="d-flex justify-content-center mt-3 product-button">
                                                                            <button className='btn'>READ MORE...</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </NavLink>

                                            </div>
                                        ))
                                    ) : (
                                        <p>No products available.</p>
                                    )}
                                </div>
                                {pageCount >= 1 && (
                                    <PaginationComponent onPageChange={handlePageClick} pageCount={pageCount} />
                                )}
                            </div>
                            {/* {calculateRows() > 1 && !showAllProducts && ( */}
                            {/* <div className="text-center view-all mt-50">
                                <button className="btn" onClick={handleButtonClick}>VIEW ALL PRODUCTS</button>
                            </div> */}
                            {/* )} */}
                        </div>
                    </div>
                </div >
            </div >

            {/* //product description */}
            {showDescription && <SkinCareProdctDescription />}
        </>
    )
}
export default SkinCareAndCosmeticProducts