import React from "react"
import NavigationBar from "../../../commonComponents/NavigationBar/NavigationBar"
import Banner from "../../../commonComponents/Banner/Banner"
import Footer from "../../../commonComponents/Footer/Footer"
import Images from "../../../commonComponents/Images/Images"
import StockProductComponent from "../../../components/ProductModuleComponents/StockProductComponent/StockProductComponent"

const StockProducts = () => {

    const data = {
        image: Images.StockProductBanner,
        title: "stock product",

    }

    return (
        <>
            <NavigationBar />
            <Banner bannerImg={data.image} title={data.title} content={data.content} customClassName="stockproduct-page-banner-css" />
            <StockProductComponent />
            <Footer />
        </>
    )
}
export default StockProducts