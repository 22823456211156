import React from "react"
import NavigationBar from "../../../commonComponents/NavigationBar/NavigationBar"
import Banner from "../../../commonComponents/Banner/Banner"
import Footer from "../../../commonComponents/Footer/Footer"
import Images from "../../../commonComponents/Images/Images"
import Patents from "../../../components/PatentsCertification/Patents"
import Certificates from "../../../components/PatentsCertification/Certificates"

const PatentsCertificationsPage = () => {

    const data = {
        image: Images.PatentsCertificatesBanner,
        title: "patents & certification",
    }

    return (
        <>
            <NavigationBar />
            <Banner bannerImg={data.image} title={data.title} customClassName="patents-certification-page-banner-css" />
            <Patents />
            <Certificates />
            <Footer />
        </>
    )
}
export default PatentsCertificationsPage