import React, { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';

const AccordianSection = ({ accordionData, heading }) => {

    const [openItem, setOpenItem] = useState(0);

    const toggleItem = (index) => {
        setOpenItem(openItem === index ? null : index);
    };



    return (
        <>
            {/* //accordian section */}
            <div className="accordian-card-section card-group card-group-custom card-group-corporate" id="accordion1" role="tablist" aria-multiselectable="false">
                {heading && <h6 className="mb-25 text-left">{heading}</h6>}
                {accordionData.map((item, index) => (
                    <div className="card card-custom card-corporate" key={index}>
                        <div className="card-heading" id={`accordion1Heading${index + 1}`} role="tab">
                            <div className="card-title">
                                <a
                                    role="button"
                                    data-toggle="collapse"
                                    href={`#accordion1Collapse${index + 1}`}
                                    aria-controls={`accordion1Collapse${index + 1}`}
                                    aria-expanded={openItem === index ? 'true' : 'false'}
                                    onClick={() => toggleItem(index)}
                                    className={openItem === index ? '' : 'collapsed'}
                                >
                                    {item.question}
                                    <div className={`card-arrow ${openItem === index ? 'card-arrow-2' : ''}`}>
                                        {openItem === index ? <FontAwesomeIcon className="fa-minus" icon={faMinus} /> : <FontAwesomeIcon className="fa-plus" icon={faPlus} />}
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className={`collapse ${openItem === index ? 'show' : ''}`} id={`accordion1Collapse${index}`} role="tabpanel" aria-labelledby={`accordion1Heading${index + 1}`}>
                            <div className="card-body">
                                <p>{item.answer}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>


        </>
    )
}
export default AccordianSection