import React, { useState, useEffect } from "react"
import NavigationBar from "../../../commonComponents/NavigationBar/NavigationBar"
import Banner from "../../../commonComponents/Banner/Banner"
import Footer from "../../../commonComponents/Footer/Footer"
import Images from "../../../commonComponents/Images/Images"
import ScientificPublications from "../../../components/ScientificPublications/ScientificPublications"
import axios from "axios"


const ScientificPublicationsPage = () => {

    const [publications, setPublications] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const data = {
        image: Images.ScientificPublicationsBanner,
        title: "scientific publications",
    }

    // api integration of scientific publications
    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    const publicationEndPoint = '/scientific_published';
    const publicationUrl = `${apiUrl}${publicationEndPoint}`

    const fetchScientificPublications = () => {
        axios.get(publicationUrl).then((res) => {
            console.log("result1", res.data.data)
            if (res.data.status === 0 || res.data.message === "No records found") {
                setPublications([]);
            } else {
                setPublications(res.data.data);
            }
            setLoading(false);
        })
            .catch((error) => {
                setError("Error fetching publications data.");
                setLoading(false);
            })
    }

    useEffect(() => {
        fetchScientificPublications();
    }, []);

    return (
        <>
            <NavigationBar />
            <Banner bannerImg={data.image} title={data.title} customClassName="scientific-publicationsBanner-page-banner-css" />
            {loading ? (
                <p>Loading...</p>
            ) : error ? (
                <p>{error}</p>
            ) : (
                <ScientificPublications publications={publications} />
            )}
            <Footer />
        </>
    )
}
export default ScientificPublicationsPage