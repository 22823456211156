import React from "react"
import NavigationBar from "../../../commonComponents/NavigationBar/NavigationBar"
import Banner from "../../../commonComponents/Banner/Banner"
import Footer from "../../../commonComponents/Footer/Footer"
import Images from "../../../commonComponents/Images/Images"
import GetinTouch from "../../../components/OurTeamPageComponents/GetinTouch"


const OurTeamPage = () => {

    const data = {
        image: Images.OurTeamBanner,
        title: "Our team",
        content: "Decoding the Natural Adjuvants",
    }

    return (
        <>
            <NavigationBar />
            <Banner bannerImg={data.image} title={data.title} content={data.content} customClassName="our-team-page-banner-css" />
            <GetinTouch />
            <Footer />
        </>
    )
}
export default OurTeamPage