import React, { useRef, useEffect, useState } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause } from '@fortawesome/free-solid-svg-icons';

const ImgWithDescription = ({ customClassName, customClassName2, customClassName3, customClassName4, heading, titleDescription, titleDescription2, title, subtitle1, subtitle, description, image, video }) => {

    const [isPlaying, setIsPlaying] = useState(false);
    const videoRef3 = useRef(null);

    // const handleAutoplay = (videoRef) => {
    //     if (videoRef.current) {
    //         if (videoRef.current && videoRef.current.paused) {
    //             videoRef.current.play().catch(error => {
    //                 console.error('Autoplay failed:', error);
    //             });
    //         } else {
    //             videoRef.current.pause();
    //         }
    //     }
    // };

    const handleTogglePlay = () => {
        setIsPlaying(!isPlaying); // Toggle play/pause state for video 2
        if (videoRef3.current) {
            if (isPlaying) {
                videoRef3.current.pause();
            } else {
                videoRef3.current.play().catch(error => {
                    console.error('Play failed:', error);
                });
            }
        }
    };


    useEffect(() => {
        if (videoRef3.current) {
            videoRef3.current.pause();
        }
    }, []);

    // useEffect(() => {
    //     if (videoRef1.current && videoRef2.current) {
    //         videoRef1.current.play().catch(error => {
    //             console.error('Autoplay failed:', error);
    //         });
    //         videoRef2.current.play().catch(error => {
    //             console.error('Autoplay failed:', error);
    //         });
    //     }
    // }, []);

    return (
        <>
            {/* //image left side, content right side */}
            <div className="service header-bottom-line">
                <div className="container">
                    <div className={`pxy-40 px-0 ${customClassName2}`}>
                        {heading && <h3 className="text-center py-20">{heading}</h3>}
                        <div className={`service-description-card ${titleDescription ? 'mt-40' : (customClassName3 === 'mt-0' ? 'mt-0' : 'mt-70')}`}>
                            {titleDescription && <p className="mb-40">{titleDescription}</p>}
                            <div className="row mx-auto">
                                <div className={`col-lg-6 col-md-12 col-sm-12 service-image-card ${customClassName4}`}>
                                    <div className={typeof image !== 'string' ? "video-container-div videobtn position-relative" : ""}>
                                        {typeof image === 'string' ? (
                                            <img src={image} alt="image" width={"100%"} />
                                        ) : (
                                            <>

                                                <video
                                                    ref={videoRef3}
                                                    className="embed-responsive-item"
                                                    loop
                                                    muted={true}
                                                    playsInline
                                                    width={"100%"}
                                                    height={"100%"}
                                                    onClick={() => {
                                                        setIsPlaying(!isPlaying);
                                                        if (!isPlaying && videoRef3.current) {
                                                            videoRef3.current.play().catch(error => {
                                                                console.error('Play failed:', error);
                                                            });
                                                        } else if (isPlaying && videoRef3.current) {
                                                            videoRef3.current.pause();
                                                        }
                                                    }}
                                                    onPlay={() => setIsPlaying(true)}
                                                    onPause={() => setIsPlaying(false)}
                                                    autoPlay
                                                >
                                                    <source src={video} type="video/mp4" width={"100%"} />
                                                    Your browser does not support the video tag.
                                                </video>
                                                <button onClick={handleTogglePlay} className={`btn position-absolute ${isPlaying ? 'hidden' : ''}`}>
                                                    <FontAwesomeIcon icon={faPlay} />
                                                </button>
                                            </>
                                        )}

                                    </div>
                                </div>
                                <div className={`col-lg-6 col-md-12 col-sm-12 service-description ${customClassName}`}>
                                    {title && <h3 className="text-left py-20 my-0">{title}</h3>}
                                    {subtitle1 && <h6 className="text-capitalize">{subtitle1}</h6>}
                                    {subtitle && <p>{subtitle}</p>}
                                    {description && <p>{description}</p>}
                                </div>
                            </div>
                            {titleDescription2 && <p className="mt-40">{titleDescription2}</p>}

                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
export default ImgWithDescription