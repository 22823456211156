import React from "react"
import Images from "../../../commonComponents/Images/Images"
import Form from 'react-bootstrap/Form';
import SkinCareAndCosmeticProducts from "./SkinCareAndCosmeticProducts";

const StockProductComponent = () => {

    const data = {
        image1: Images.HealthCareExperts,


    }

    return (
        <>
            {/* //stock product healthcare experts */}
            <div className="service header-bottom-line">
                <div className="container p-0">
                    <div className="pxy-40 px-0">
                        <h3 className="text-center py-20">stock product - for healthcare experts</h3>
                        <div className="mt-70 ">
                            <div className="row mx-auto">
                                <div className="col-lg-8 col-md-12 col-sm-12 ">
                                    <div>
                                        <img src={data.image1} alt="" width={"100%"} />
                                    </div>
                                    <div className="mt-20">
                                        <p className="mb-2">At Deltas, we specialise in high-quality, competitively priced private label supplements. Our products are inspired by top-selling items and current market trends, ensuring they meet and exceed industry standards.</p>
                                        <p className="mb-2">What sets our formulas apart is their Registered Trademark status and the opportunity for co-branding. This, combined with their pre-approval by AYUSH/FSSAI, means you can launch your product in the market much faster.</p>
                                        <p className="mb-2">We aim to make the process of developing a competitive private label product both quick and cost-effective for you. Our formulas are available with a low minimum order quantity (MOQ), helping you minimise initial costs and test the market effectively.</p>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-12 col-sm-12 ">
                                    <h6>Our stock formulations are popular among a wide range of professionals and organisations</h6>
                                    <p className="text-bold mt-18">who appreciate our co-branding options:</p>
                                    <Form className="mt-10 professionals-offering-checkbox">
                                        <div className="mb-3">
                                            <Form.Check
                                                type="checkbox"
                                                id="checkboxId1"
                                                label="Ayurvedic Doctors"
                                            />
                                            <Form.Check
                                                type="checkbox"
                                                id="checkboxId2"
                                                label="Healthcare Practitioners"
                                            />
                                            <Form.Check
                                                type="checkbox"
                                                id="checkboxId3"
                                                label="Dieticians"
                                            />
                                            <Form.Check
                                                type="checkbox"
                                                id="checkboxId4"
                                                label="Sports Nutritionists"
                                            />
                                            <Form.Check
                                                type="checkbox"
                                                id="checkboxId5"
                                                label="Wellness Start - ups"
                                            />
                                            <Form.Check
                                                type="checkbox"
                                                id="checkboxId6"
                                                label="Hosptials and Wellness Institutions"
                                            />
                                            <Form.Check
                                                type="checkbox"
                                                id="checkboxId7"
                                                label="Marketing Professional Groups and Consultants"
                                            />
                                            <Form.Check
                                                type="checkbox"
                                                id="checkboxId8"
                                                label="PCD Distributors"
                                            />
                                            <Form.Check
                                                type="checkbox"
                                                id="checkboxId9"
                                                label={<p>Healthcare NGOs <br /> and Self-Help Groups</p>}
                                            />
                                            <p>Explore our list of available private label nutraceutical products to find the perfect match for your needs.</p>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* //advance skin care & cosmetic products  */}
            <SkinCareAndCosmeticProducts />
        </>
    )
}
export default StockProductComponent