import React from "react"
import Images from "../../commonComponents/Images/Images"


const CardSection = ({ articlesData, heading, description, customClassName, notes, customClassName2 }) => {


    return (
        <>
            <div className="card-section header-bottom-line">
                <div className="">
                    <div className={`pxy-75 ${customClassName}`}>
                        {heading && <h3 className="text-center py-20 ">{heading}</h3>}
                        <div className={`mt-40 ${customClassName}`}>
                            {description && <p className="mx-275 text-justify">{description}</p>}
                            <div className={`mt-70 ${customClassName}`}>
                                <div className="row row-50 align-items-center justify-content-center">
                                    {articlesData.map((article, index) => (
                                        <div key={index} className="col-lg-3 col-md-6 col-sm-12">
                                            <article class={`box-minimal-modern ${customClassName2}`}>
                                                <div class="box-minimal-icon novi-icon linearicons-pills">
                                                    <img src={article.image} alt="icons" width={""} />
                                                </div>
                                                {/* <div> */}
                                                <h6 class="box-minimal-title text-center">{article.title}</h6>
                                                <p class="box-minimal-text text-justify letter-spacing-5">{article.text}</p>
                                                {article.showButton && (
                                                    <div class="mt-3 text-center">
                                                        <button class="btn ash-border">LEARN MORE</button>
                                                    </div>
                                                )}
                                                {/* </div> */}

                                            </article>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="mx-100 text-center">
                                <span className="text-center">{notes}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div >

        </>
    )
}
export default CardSection