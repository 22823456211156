import React from "react"
import NavigationBar from "../../../commonComponents/NavigationBar/NavigationBar"
import Banner from "../../../commonComponents/Banner/Banner"
import Footer from "../../../commonComponents/Footer/Footer"
import Images from "../../../commonComponents/Images/Images"
import OurCollabrations from "../../../components/RndComponents/OurCollabrations"

const RnDPage = () => {

    const data = {
        image: Images.RnDBanner,
        title: "R&D",
        content: "Decoding the Natural Adjuvants",
    }

 
    return (
        <>
            <NavigationBar />
            <Banner bannerImg={data.image} title={data.title} content={data.content} customClassName="rnd-page-banner-css"/>
            <OurCollabrations />
            <Footer />
        </>
    )
}
export default RnDPage