import React, { useState, useEffect } from "react"
import DisplayModal from "../../commonComponents/DisplayModal/DisplayModal"


const ScientificPublications = ({ publications }) => {

    const [showPdf, setShowPdf] = useState(false);
    const [selectedPdf, setSelectedPdf] = useState(null);

    const openPdfModal = (pdfSrc) => {
        setSelectedPdf(pdfSrc);
        setShowPdf(true);
    };

    return (
        <>
            {/* //scientific publications */}
            <div className="scientific-publications header-bottom-line">
                <div className="container">
                    <div className="pxy-40 px-0">
                        <h3 className="text-center py-20 ">scientific publications</h3>
                        <div className="mt-70">
                            <div className="row row-50">
                                {publications.map((publications, index) => (
                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                        <div class="card">
                                            <div>
                                                <img src={publications.image} alt="publications" width="100%" />
                                            </div>
                                            <div className="card-body">
                                                <h6 class="box-minimal-title text-center">{publications.name}</h6>
                                                <p class="box-minimal-text text-center">{publications.short_description}</p>
                                                <div class="mt-3 text-center">
                                                    <button class="btn white-border" onClick={() => openPdfModal(publications.pdf_path)}>Read more</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <DisplayModal
                show={showPdf}
                onHide={() => setShowPdf(false)}
                pdfSrc={selectedPdf}
            />
        </>
    )
}
export default ScientificPublications