import React from "react"
import NavigationBar from "../../../commonComponents/NavigationBar/NavigationBar"
import Banner from "../../../commonComponents/Banner/Banner"
import Footer from "../../../commonComponents/Footer/Footer"
import Images from "../../../commonComponents/Images/Images"
import FormulationExperience from "../../../components/FormulationExperienceComponents/FormulationExperience"
import CardSection from "../../../commonComponents/CardSection/CardSection"

const FormulationExperiencePage = () => {

    const data = {
        image: Images.FormulationExperienceBanner,
        title: "formulation experience",
    }

    const heading = "Criteria for our organic certification of wild-harvested medicinal plants include:"

    const articlesData = [
        {
            title: 'Verification of Wild Status',
            text: ' We harvest from uncontaminated wild areas, avoiding synthetic pesticides, herbicides, and fertilisers. Assessments confirm the plants are wild and not intensively managed.',
            image: Images.formulationexperience1,
        },

        {
            title: 'Sustainable Harvesting',
            text: 'Certification demands proof of harvesting practices that preserve wild plant populations’ health and future viability. Practices may include selective and non-excessive harvesting, allowing natural plant regeneration.',
            image: Images.formulationexperience2,
        },

        {
            title: 'Ecosystem Protection',
            text: 'Our harvesting methods protect the local ecosystems, conserving biodiversity, preventing damage to other species and habitats, and mitigating negative impacts on water and soil.',
            image: Images.formulationexperience3,
        },
        {
            title: 'Chain of Custody',
            text: 'We ensure traceability from harvest to product, maintaining organic integrity through the supply chain.',
            image: Images.formulationexperience4,
        },

    ];


    return (
        <>
            <NavigationBar />
            <Banner bannerImg={data.image} title={data.title} customClassName="formulation-experience-page-banner-css" />
            <FormulationExperience />
            <CardSection articlesData={articlesData} heading={heading} />
            <Footer />
        </>
    )
}
export default FormulationExperiencePage