import React from "react"
import NavigationBar from "../../../commonComponents/NavigationBar/NavigationBar"
import Footer from "../../../commonComponents/Footer/Footer"
import VendorEnquiryComponent from "../../../components/VendorEnquiryComponent/VendorEnquiryComponent"

const VendorEnquiry = () => {
    return (
        <>
            <NavigationBar customclassname="feasibility-navsection" />
            <VendorEnquiryComponent />
            <Footer />
        </>
    )
}
export default VendorEnquiry