import React from "react"
import NavigationBar from "../../../commonComponents/NavigationBar/NavigationBar"
import Banner from "../../../commonComponents/Banner/Banner"
import Footer from "../../../commonComponents/Footer/Footer"
import Images from "../../../commonComponents/Images/Images"
import ImgWithDescription from "../../../commonComponents/ImgWithDescription/ImgWithDescription"
import GeneticsTestingComponent from "../../../components/GeneticsTestingComponent/GeneticsTestingComponent"

const GeneticsTestingPage = () => {

    const data = {
        image: Images.GeneticsTestingBanner,
    }

    const data2 = {
        video: Images.SalivaCollectedMethodVideo,
        heading: "saliva based dna testing services",
        description: <>At Deltas, we specialize in cutting-edge saliva-based DNA testing, designed to empower you with insights into your genetic makeup and guide you toward a healthier future. Our service leverages Single Nucleotide Polymorphisms (SNPs) assessment, utilizing advanced technology and a proprietary algorithm to deliver highly accurate and personalized reports. Through our innovative approach, we provide an unprecedented level of accuracy in SNP-based assessment, reaching up to 99% accuracy in reporting.<br />
            Our primary objective is preventive healthcare — helping you understand your genetic predispositions so you can make informed decisions about your health. With Deltas, you're not just getting a DNA test; you're accessing a comprehensive health tool designed to pave the way for a healthier, more informed lifestyle.
        </>,
    }

    return (
        <>
            <NavigationBar />
            <Banner bannerImg={data.image} title={data.title} content={data.content} customClassName="clinicaltrial-page-banner-css" />
            <ImgWithDescription {...data2} customClassName="product-description overflow-scroll-xy" customClassName4="cosmetic-video" />
            <GeneticsTestingComponent/>
            <Footer />
        </>
    )
}
export default GeneticsTestingPage