import React from "react"

const WhyChooseUs = () => {
    return (
        <>
            {/* //why choose us */}

            <div className="pxy-40 why-chooseus header-bottom-line">
                <h3 className="text-center py-20">WHY CHOOSE US</h3>
                <div className="pt-40">
                    <div className="row text-center why-choose-us-row">
                        <div className="col-lg-3 col-md-12 col-sm-12 divisions">
                            <div className="whychooseus-divisions"><div className="number">01 </div></div>
                            <h5 className="py-20 mt-20">MODERN PACKAGING</h5>
                            <p className="font-family-latolight mt-28 text-center">All our medicine is provided with modern and durable packaging.</p>
                        </div>
                        <div className="col-lg-3 col-md-12 col-sm-12 divisions">
                            <div className="whychooseus-divisions"><div className="number">02 </div></div>
                            <h5 className="py-20 mt-20">AFFORDABLE PRICES</h5>
                            <p className="font-family-latolight mt-28 text-center">All our medicine is provided with modern and durable packaging.</p>
                        </div>
                        <div className="col-lg-3 col-md-12 col-sm-12 divisions">
                            <div className="whychooseus-divisions"><div className="number">03</div></div>
                            <h5 className="py-20 mt-20">NEW TECHNOLOGIES</h5>
                            <p className="font-family-latolight mt-28 text-center">All our medicine is provided with modern and durable packaging.</p>
                        </div>
                        <div className="col-lg-3 col-md-12 col-sm-12 divisions">
                            <div className="whychooseus-divisions"><div className="number">04</div></div>
                            <h5 className="py-20 mt-20">UNIQUE APPROACH</h5>
                            <p className="font-family-latolight mt-28 text-center">All our medicine is provided with modern and durable packaging.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default WhyChooseUs