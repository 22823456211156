import React from "react"
import NavigationBar from "../../../commonComponents/NavigationBar/NavigationBar"
import Banner from "../../../commonComponents/Banner/Banner"
import Footer from "../../../commonComponents/Footer/Footer"
import Images from "../../../commonComponents/Images/Images"
import ProductComponent from "../../../components/ProductModuleComponents/ProductComponent"

const ProductPage = () => {

    const data = {
        image: Images.VirtualProductBanner,
    }


    return (
        <>
            <NavigationBar />
            <Banner bannerImg={data.image} title={data.title} content={data.content} customClassName="product-page-banner-css" />
            <ProductComponent />
            <Footer />
        </>
    )
}
export default ProductPage