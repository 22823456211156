import React from "react"
import NavigationBar from "../../commonComponents/NavigationBar/NavigationBar"
import Footer from "../../commonComponents/Footer/Footer"
import WhyChooseUs from "../../components/HomePageComponents/WhyChooseUs"
import AboutOurProducts from "../../components/HomePageComponents/AboutOurProducts"
import Testimonials from "../../components/HomePageComponents/Testimonials"
import Services from "../../components/HomePageComponents/Services"
import OurProducts from "../../components/HomePageComponents/OurProducts"
import PrivateLabel from "../../components/HomePageComponents/PrivateLable"
import HomeBanner from "../../components/HomePageComponents/HomeBanner"
const HomePage = () => {
    const data = {
        // image: Images.HomePageBanner
    }
    return (
        <>
            <NavigationBar />
            <HomeBanner />
            <WhyChooseUs />
            <OurProducts />
            {/* <Testimonials /> */}
            <Services />
            <AboutOurProducts />
            <PrivateLabel />
            <Footer />
        </>
    )
}
export default HomePage