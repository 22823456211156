import React from "react"
import NavigationBar from "../../../commonComponents/NavigationBar/NavigationBar"
import Footer from "../../../commonComponents/Footer/Footer"
import SampleRequestComponent from "../../../components/SampleRequestComponent/SampleRequestComponent"

const SampleRequest = () => {
    return (
        <>
            <NavigationBar customclassname="feasibility-navsection"/>
            <SampleRequestComponent />
            <Footer />
        </>
    )
}
export default SampleRequest