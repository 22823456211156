import React, { useState, useEffect, useRef } from "react"
import Form from 'react-bootstrap/Form'
import axios from "axios"
import { ToastContainer, toast } from 'react-toastify'



const VendorEnquiryComponent = () => {

    const [formData, setFormData] = useState({
        first_name: "",
        last_name: "",
        company: "",
        country_id: "",
        phone_number: "",
        mail: "",
        capabilities: "",
        leadTime: "",
        status: true,
        companydoes: ""
    });

    const refs = {
        first_name: useRef(null),
        last_name: useRef(null),
        company: useRef(null),
        country_id: useRef(null),
        phone_number: useRef(null),
        mail: useRef(null),
        capabilities: useRef(null),
        leadTime: useRef(null),
        status: useRef(null),
        companydoes: useRef(null),
    };

    const [selection, setSelection] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const [errors, setErrors] = useState({});
    const [countries, setCountries] = useState([]);
    // const [formulation, setFormulation] = useState([]);


    useEffect(() => {
        const fetchCountries = async () => {
            try {
                const apiUrl = process.env.REACT_APP_API_BASE_URL;
                const countryListEndPoint = '/countrys_list';
                const countryListUrl = `${apiUrl}${countryListEndPoint}`;

                const response = await axios.get(countryListUrl);
                if (response.data.status === 1 && response.data.code === 200) {
                    setCountries(response.data.data);
                } else {
                    console.error("Error fetching countries:", response.data.message);
                }
            } catch (error) {
                console.error("Error fetching countries:", error);
            }
        };

        fetchCountries();
    }, []);


    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             const apiUrl = process.env.REACT_APP_API_BASE_URL;

    //             const countryListEndPoint = '/countrys_list';
    //             const formulationListEndPoint = '/get_formulation';

    //             const countryListUrl = `${apiUrl}${countryListEndPoint}`;
    //             const formulationListUrl = `${apiUrl}${formulationListEndPoint}`;

    //             const [countryResponse, formulationResponse] = await Promise.all([
    //                 axios.get(countryListUrl),
    //                 axios.get(formulationListUrl)
    //             ]);

    //             if (countryResponse.data.status === 1 && countryResponse.data.code === 200) {
    //                 setCountries(countryResponse.data.data);
    //             } else {
    //                 console.error("Error fetching dosages:", countryResponse.data.message);
    //             }

    //             if (formulationResponse.data.status === 1 && formulationResponse.data.code === 200) {
    //                 setFormulation(formulationResponse.data.data);
    //             } else {
    //                 console.error("Error fetching formulations:", formulationResponse.data.message);
    //             }
    //         } catch (error) {
    //             console.error("Error fetching data:", error);
    //         }
    //     };

    //     fetchData();
    // }, []);

    const handleRadioChange = (event) => {
        const value = event.target.value === "Yes";
        setSelection(value);
        setFormData({
            ...formData,
            status: value ? "1" : "0"
        });
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
        clearError(name);

    };

    const handleSelectChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
        clearError(name);
    };

    const clearError = (name) => {
        const newErrors = { ...errors };
        delete newErrors[name];
        setErrors(newErrors);
    };

    // handle submit of vendor enquiry form 
    const handleSubmit = (event) => {
        event.preventDefault();
        if (validateForm()) {
            setSubmitting(true);

            // Prepare data to be sent
            const requestData = {
                first_name: formData.first_name,
                last_name: formData.last_name,
                company: formData.company,
                country_id: formData.country_id,
                phone_number: formData.phone_number,
                mail: formData.mail,
                capabilities: formData.capabilities,
                leadTime: formData.leadTime,
                status: formData.status ? "1" : "0",
                companydoes: formData.companydoes
            };


            // API integration of vendor form submit
            const apiUrl = process.env.REACT_APP_API_BASE_URL;
            const enquiryEndPoint = '/vendor_enquiry';
            const enquiryUrl = `${apiUrl}${enquiryEndPoint}`;
            // const countryListEndPoint = '/countrys_list';
            // const countryListUrl = `${apiUrl}${countryListEndPoint}`;

            axios.post(enquiryUrl, requestData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then((res) => {
                    if (res.data.status === 1 && res.data.code === 200) {
                        resetForm();
                        toast.success('Form submitted successfully!');
                        setSubmitting(false);
                    } else {
                        console.error("Error:", res.data.message);
                        toast.error('Form submission failed.');
                    }
                })
                .catch((error) => {
                    console.error("Error:", error);
                    toast.error('Form submission failed.');
                    setSubmitting(false);
                })

                .finally(() => {
                    setSubmitting(false);
                });
        }
    };

    // useEffect(() => {
    //     if (Object.keys(errors).length > 0) {
    //         const firstErrorField = Object.keys(errors)[0];
    //         if (firstErrorField) {
    //             refs[firstErrorField].current.scrollIntoView({ behavior: 'smooth' });
    //         }
    //     }
    // }, [errors]);

    useEffect(() => {
        if (Object.keys(errors).length > 0) {
            const firstErrorField = Object.keys(errors)[0];
            if (firstErrorField && refs[firstErrorField]) {
                refs[firstErrorField].current.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [errors, refs]);

    const validateForm = () => {
        let formIsValid = true;
        const newErrors = {};

        // Check if any field is empty
        Object.keys(formData).forEach((key) => {
            if (!formData[key]) {
                newErrors[key] = "This field is required.";
                formIsValid = false;
            }
        });

        // Check if select fields are empty
        if (!formData.capabilities) {
            newErrors.capabilities = "Please select an option from the drop-down menu.";
            formIsValid = false;
        }

        if (!formData.leadTime) {
            newErrors.leadTime = "Please select an option from the drop-down menu.";
            formIsValid = false;
        }

        // Check phone number format
        const phoneRegex = /^\d{10}$/;
        if (!phoneRegex.test(formData.phone_number)) {
            newErrors.phone_number = "Please enter a valid phone number.";
            formIsValid = false;
        }

        // Check email format
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(formData.mail)) {
            newErrors.mail = "Please enter a valid email address.";
            formIsValid = false;
        }

        setErrors(newErrors);
        return formIsValid;
    };

    const resetForm = () => {
        setFormData({
            first_name: "",
            last_name: "",
            company: "",
            country_id: "",
            phone_number: "",
            mail: "",
            capabilities: "",
            leadTime: "",
            status: true,
            companydoes: ""
        });
        setSelection(true);
    };



    return (
        <>
            <div className="vendor-enquiry header-bottom-line">
                <div className="container">
                    <div className="pxy-40">
                        <h3 className="text-center py-20 ">vendor enquiry</h3>
                        <div className="">
                            <div className="row">
                                <div className="col-lg-1">
                                </div>
                                <div className="col-lg-10 vendor-enquiry-form">
                                    <p className="text-bold mt-70">
                                        Become a Supplier for Deltas
                                    </p>
                                    <p>If you are a vendor or supplier of nutraceuticals fill in the form below to work with Deltas. We will send you quotes for all future orders we receive.</p>
                                    <p>If you are not a vendor and wish to have your product manufactured by Deltas please fill in the request a quote form here.</p>
                                    <form onSubmit={handleSubmit} className="mt-40">
                                        <div className="row row-5">
                                            <div className="col-lg-6 col-md-12 col-sm-12">
                                                <label>First Name*</label>
                                                <input
                                                    type="text"
                                                    name="first_name"
                                                    value={formData.first_name}
                                                    onChange={handleChange}
                                                    ref={refs.first_name}
                                                />
                                                {errors.first_name && <p className="error-message">{errors.first_name}</p>}
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12">
                                                <label>Last Name*</label>
                                                <input
                                                    type="text"
                                                    name="last_name"
                                                    value={formData.last_name}
                                                    onChange={handleChange}
                                                    ref={refs.last_name}
                                                />
                                                {errors.last_name && <p className="error-message">{errors.last_name}</p>}
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12">
                                                <label>Company Name*</label>
                                                <input
                                                    type="text"
                                                    name="company"
                                                    value={formData.company}
                                                    onChange={handleChange}
                                                    ref={refs.company}
                                                />
                                                {errors.company && <p className="error-message">{errors.company}</p>}
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12">
                                                <label>Country / Region*</label>
                                                <Form.Select name="country_id"
                                                    aria-label="Default select example"
                                                    defaultValue="select"
                                                    onChange={handleSelectChange}
                                                    ref={refs.country_id}
                                                >
                                                    <option value="select">Please Select</option>
                                                    {countries.map(country => (
                                                        <option key={country.id} value={country.id}>{country.country_name}</option>
                                                    ))}
                                                </Form.Select>
                                                {errors.country_id && <p className="error-message">{errors.country_id}</p>}
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12">
                                                <label>Phone Number*</label>
                                                <input
                                                    type="tel"
                                                    name="phone_number"
                                                    value={formData.phone_number}
                                                    onChange={handleChange}
                                                    ref={refs.phone_number}

                                                />
                                                {errors.phone_number && <p className="error-message">{errors.phone_number}</p>}
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12">
                                                <label>Email*</label>
                                                <input
                                                    type="email"
                                                    name="mail"
                                                    value={formData.mail}
                                                    onChange={handleChange}
                                                    ref={refs.mail}

                                                />
                                                {errors.mail && <p className="error-message">{errors.mail}</p>}
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12">
                                                <label>Your Capabilities (What to send you RFQs for)</label>
                                                <Form.Select
                                                    name="capabilities"
                                                    aria-label="Default select example"
                                                    defaultValue="select"
                                                    onChange={handleSelectChange}
                                                    ref={refs.capabilities}
                                                >
                                                    <option value="select">Please Select</option>
                                                    <option value="capsules">Capsules</option>
                                                    <option value="tablets">Tablets</option>
                                                    <option value="lami tubes">lami tubes</option>
                                                    <option value="gummies">Gummies</option>
                                                    <option value="lozenges">Lozenges</option>
                                                    <option value="powders">Powders</option>
                                                    <option value="softgels">Softgels</option>
                                                    <option value="extracts">Extracts</option>
                                                    <option value="vitamins">Vitamins</option>
                                                    <option value="excipients">Excipients</option>
                                                    <option value="packaging">Packaging</option>
                                                    <option value="Other">Other</option>
                                                </Form.Select>
                                                {errors.capabilities && <p className="error-message">{errors.capabilities}</p>}
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12">
                                                <label>What is your lead time for providing requested quotes?"</label>
                                                <Form.Select
                                                    name="leadTime"
                                                    aria-label="Default select example"
                                                    defaultValue="select"
                                                    onChange={handleSelectChange}
                                                    ref={refs.leadTime}
                                                >
                                                    <option value="select">Please Select</option>
                                                    <option value="2 weeks">2 weeks</option>
                                                    <option value="3 weeks">3 weeks</option>
                                                    <option value="4 weeks">4 weeks</option>
                                                    <option value="5 weeks">5 weeks</option>
                                                </Form.Select>
                                                {errors.leadTime && <p className="error-message">{errors.leadTime}</p>}
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-12 my-20">
                                                {/* <p className="select-option">Please select an option from the drop-down menu</p> */}
                                                <div className="radio-button-division ml-25">
                                                    <p className="">Are you able to send status to Deltas headquarters?* </p>
                                                    <div className="d-flex align-items-center">
                                                        <div className="form-check">
                                                            <input className="form-check-input radio-btn"
                                                                type="radio"
                                                                name="vendorEnquiryRadioBtn"
                                                                id="radioBtnYes"
                                                                value="Yes"
                                                                checked={selection}
                                                                onChange={handleRadioChange}
                                                            />
                                                            <label className="form-check-label radio-label" htmlFor="radioBtnYes">
                                                                Yes /
                                                            </label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input className="form-check-input radio-btn"
                                                                type="radio"
                                                                name="vendorEnquiryRadioBtn"
                                                                id="radioBtnNo"
                                                                value="No"
                                                                checked={!selection}
                                                                onChange={handleRadioChange}
                                                            />
                                                            <label className="form-check-label radio-label" htmlFor="radioBtnNo">
                                                                No
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                <label>Summarize what is that your company does best. You can also put prices here, please be as detailed as you can.*</label>
                                                <textarea
                                                    type="text"
                                                    name="companydoes"
                                                    value={formData.companydoes}
                                                    onChange={handleChange}
                                                    ref={refs.companydoes}

                                                />
                                                {errors.companydoes && <p className="error-message">{errors.companydoes}</p>}
                                            </div>
                                        </div>
                                        <div className="mt-30 text-center">
                                            <button className="btn" type="submit" disabled={submitting}> {submitting ? "Submitting..." : "SUBMIT"}</button>
                                        </div>
                                    </form>
                                </div>
                                <div className="col-lg-1">
                                </div>
                            </div>
                        </div>
                    </div>
                    <ToastContainer />
                </div>
            </div>

        </>
    )
}
export default VendorEnquiryComponent
