import React, { useState, useEffect, useRef } from "react"
import Images from "../../commonComponents/Images/Images"
import DisplayModal from "../../commonComponents/DisplayModal/DisplayModal"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf, faPlay } from "@fortawesome/free-solid-svg-icons";


const GeneticsTestingComponent = ({ video }) => {

    const [showPdf, setShowPdf] = useState(false);
    const [selectedPdf, setSelectedPdf] = useState(null);
    const [isPlaying1, setIsPlaying1] = useState(false);
    const [isPlaying2, setIsPlaying2] = useState(false);

    const videoRef1 = useRef(null);
    const videoRef2 = useRef(null);

    const openPdfModal = (pdfSrc) => {
        setSelectedPdf(pdfSrc);
        setShowPdf(true);
    };

    const popular_tests_pdfs = {
        pdf1: Images.Trigenome_comprehensive_brochure,
        pdf2: Images.Trigenome_Nedimap_brochure,
        pdf3: Images.Trigenome_Kids_brochure,
    }

    const images = {
        image1: Images.StateofArt1,
        image2: Images.StateofArt2,
        image3: Images.StateofArt3,
        image4: Images.StateofArt4,
        image5: Images.StateofArt5,
        image6: Images.StateofArt6,
        image7: Images.StateofArt7,
        image8: Images.StateofArt8,
        image9: Images.StateofArt9,

    }

    const data = {
        image1: Images.Comprehensive,
        image2: Images.Medimap,
        image3: Images.Kids,
    }

    const testOfferings = [
        {
            Image: Images.ExtendedTest1,
            title: "7 panels and 46 conditions",
        },
        {
            Image: Images.ExtendedTest2,
            title: "6 panels and 45 conditions",
        },
        {
            Image: Images.ExtendedTest3,
            title: "9 panels and 55 conditions",
        },
        {
            Image: Images.ExtendedTest4,
            title: "10 panels and 42 conditions",
        },
        {
            Image: Images.ExtendedTest5,
            title: "12 panels and 69 conditions",
        },
        {
            Image: Images.ExtendedTest6,
            title: "6 risk factors, over 15+ cancer",
        },
    ]


    const handleTogglePlay = () => {
        setIsPlaying1(!isPlaying1); // Toggle play/pause state for video 1
        if (videoRef1.current) {
            if (isPlaying1) {
                videoRef1.current.pause();
            } else {
                videoRef1.current.play().catch(error => {
                    console.error('Play failed:', error);
                });
            }
        }
    };

    const handleTogglePlay2 = () => {
        setIsPlaying2(!isPlaying2); // Toggle play/pause state for video 2
        if (videoRef2.current) {
            if (isPlaying2) {
                videoRef2.current.pause();
            } else {
                videoRef2.current.play().catch(error => {
                    console.error('Play failed:', error);
                });
            }
        }
    };

    useEffect(() => {
        if (videoRef1.current) {
            videoRef1.current.pause();
        }
        if (videoRef2.current) {
            videoRef2.current.pause();
        }
    }, []);


    return (
        <>
            {/* //State of art Laboratory */}
            <div className="cosmetic-products-science">
                <div className="container">
                    <div className="pxy-40 pt-0 px-0">
                        <div className="row formulated-science">
                            <div className="col-lg-5 col-md-12 col-sm-12 formulated-science-description">
                                <h3 className="text-left py-20 text-capitalize">State of art Laboratory</h3>
                                <p>Our samples are processed in state of art laboratory, which is equipped with cutting-edge molecular assays, including RT-PCR, genotyping, sequencing, BioIT services and diagnostic tests. By integrating genetic health profiles, biochemical tests, and health history with expert genetic counseling, TriGenome™ delivers actionable insights for individuals and their healthcare providers to promote a healthier life.</p>
                            </div>
                            <div className="col-lg-7 col-md-12 col-sm-12 formulated-science-div state-ofart-laboratory">
                                <div className="row row-25">
                                    <div className="col-lg-4 col-md-4 col-sm-12">
                                        <div className="d-flex flex-column align-items-center">
                                            <div className="cosmetic-img-div">
                                                <img src={images.image1} alt="Pure active ingredient" width={"%"} />
                                            </div>
                                            <div className="service-content text-center">
                                                <span className="text-bold">ISO<br />9001: 2015</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-12">
                                        <div className="d-flex flex-column align-items-center">
                                            <div className="cosmetic-img-div">
                                                <img src={images.image2} alt="Free from Parabens and Mineral Oils" width={"%"} />
                                            </div>
                                            <div className="service-content text-center">
                                                <span className="text-bold">NABL<br />MC - 6120</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-12">
                                        <div className="d-flex flex-column align-items-center">
                                            <div className="cosmetic-img-div">
                                                <img src={images.image3} alt="Pure active ingredient" width={"%"} />
                                            </div>
                                            <div className="service-content text-center">
                                                <span className="text-bold">Preventive healthcare<br /> approach</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row row-25">
                                    <div className="col-lg-4 col-md-4 col-sm-12">
                                        <div className="d-flex flex-column align-items-center">
                                            <div className="cosmetic-img-div">
                                                <img src={images.image4} alt="Skin - Friendly and pure" width={"%"} />
                                            </div>
                                            <div className="service-content text-center">
                                                <span className="text-bold">Latest Sequencing<br /> Technology</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-12">
                                        <div className="d-flex flex-column align-items-center">
                                            <div className="cosmetic-img-div">
                                                <img src={images.image5} alt="Crutely - Free Ingredients" width={"%"} />
                                            </div>
                                            <div className="service-content text-center">
                                                <span className="text-bold">Once in a lifetime and<br /> Non-invasive</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-12">
                                        <div className="d-flex flex-column align-items-center">
                                            <div className="cosmetic-img-div">
                                                <img src={images.image6} alt="Skin - Friendly and pure" width={"%"} />
                                            </div>
                                            <div className="service-content text-center">
                                                <span className="text-bold">Easy to read and<br /> self - explanatory report</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row row-25">
                                    <div className="col-lg-4 col-md-4 col-sm-12">
                                        <div className="d-flex flex-column align-items-center">
                                            <div className="cosmetic-img-div">
                                                <img src={images.image7} alt="Skin - Friendly and pure" width={"%"} />
                                            </div>
                                            <div className="service-content text-center">
                                                <span className="text-bold">Genetic counseling &<br /> personalised suggestion</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-12">
                                        <div className="d-flex flex-column align-items-center">
                                            <div className="cosmetic-img-div">
                                                <img src={images.image8} alt="Crutely - Free Ingredients" width={"%"} />
                                            </div>
                                            <div className="service-content text-center">
                                                <span className="text-bold">Accredited<br /> Laboratory</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-12">
                                        <div className="d-flex flex-column align-items-center">
                                            <div className="cosmetic-img-div">
                                                <img src={images.image9} alt="Skin - Friendly and pure" width={"%"} />
                                            </div>
                                            <div className="service-content text-center">
                                                <span className="text-bold">Fast reporting with<br /> 99.9% data accuracy</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >

            {/* //popular tests card section */}
            <div className="services header-bottom-line">
                <h3 className="py-20 text-center">popular tests</h3>
                <div className="mt-70">
                    <div className="pxy-40 cosmetic-products popular-tests">
                        <div className="container">
                            <div className="row row-50 text-center popular-tests card-section-full-img">
                                <div className="col-lg-4 col-md-4 col-sm-12">
                                    <div className="card">
                                        <div>
                                            <div className="img-container-full">
                                                <img src={data.image1} alt="supply-chain1" width={"100%"} />
                                            </div>
                                            <div className="card-body d-flex flex-column justify-content-evenly gap-20">
                                                <h6 className="">TriGenome<sup>TM</sup> - Comprehensive</h6>
                                                <div>
                                                    <p>TriGenome<sup>TM</sup>-Comprehensive, Deltas' advanced DNA - based health and wellness test, reveals your genetic make - up, health risks, and medication response through |3| detailed reports.</p>
                                                </div>
                                                <div class="d-flex justify-content-center align-items-center gap-20 pdfclicksection">
                                                    <button class="btn" onClick={() => openPdfModal(popular_tests_pdfs.pdf1)}>READ MORE...
                                                    </button>
                                                    <FontAwesomeIcon icon={faFilePdf} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-12">
                                    <div>
                                        <div className="card">
                                            <div className="img-container-full">
                                                <img src={data.image2} alt="supply-chain2" width={"100%"} />
                                            </div>
                                            <div className="card-body d-flex flex-column justify-content-evenly gap-20">
                                                <h6 className="">TriGenome<sup>TM</sup> - Medimap</h6>
                                                <div>
                                                    <p>TriGenome<sup>TM</sup>-Medimap, Deltas' advanced saliva - based DNA test, personalizes your medication regimen. It provides insights into your genetic makeup, covering over |65 medications across various medical fields, with easy - to - understand reports and free genetic counseling.</p>
                                                </div>
                                                <div class="d-flex justify-content-center align-items-center gap-20 pdfclicksection">
                                                    <button class="btn" onClick={() => openPdfModal(popular_tests_pdfs.pdf2)}>READ MORE...</button>
                                                    <FontAwesomeIcon icon={faFilePdf} />

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-12 ">
                                    <div className="card">
                                        <div>
                                            <div className="img-container-full">
                                                <img src={data.image3} alt="supply-chain3" width={"100%"} />
                                            </div>
                                            <div className="card-body d-flex flex-column justify-content-evenly gap-20">
                                                <h6 className="">TriGenome<sup>TM</sup> - Kids</h6>
                                                <div>
                                                    <p>TriGenome<sup>TM</sup>-Kids, Deltas' innovative saliva - based DNA test, offers comprehensive insights into your child's genetic makeup. Covering over |5 conditions across |0 panels, each report includes free genetic counseling and personlized recommendations for diet, lifestyle, education, and career guidence.</p>
                                                </div>
                                                <div class="d-flex justify-content-center align-items-center gap-20 pdfclicksection">
                                                    <button class="btn" onClick={() => openPdfModal(popular_tests_pdfs.pdf3)}>READ MORE...</button>
                                                    <FontAwesomeIcon icon={faFilePdf} />

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* //extended test offerings section */}
            <div className="services header-bottom-line container">
                <div className="pxy-40 px-0">
                    <h3 className="py-20 text-center">Extended test offerings</h3>
                    <div className="mt-70">
                        <div className="row">
                            {testOfferings.map((obj, index) => (
                                <div className="col-lg-4 col-md-6 col-sm-12">
                                    <div className='card-carousel-main'>
                                        <div className='carousel-card-main services-carousel' key={index}>
                                            <div className='carousel-card-nav card cosmetic-product-card w-100'>
                                                <div className="img-containerr">
                                                    <img src={obj.Image} className='carousel-card-img' width={"100%"} />
                                                </div>
                                                <div className="carousel-content font-family-regular">
                                                    <p className='carousel-title text-center product-title-ht'>{obj.title}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div >
            </div >


            {/* //video card section */}
            <div className="services container">
                <div className="pxy-40 px-0 pt-0">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-sm-12 service-image-card cosmetic-video">
                            <h3 class="text-left">lab tour</h3>
                            <div className="video-container-div videobtn position-relative">
                                <video
                                    ref={videoRef1}
                                    className="embed-responsive-item"
                                    loop
                                    muted={true}
                                    playsInline
                                    width={"100%"}
                                    height={"100%"}
                                    onClick={() => {
                                        setIsPlaying1(!isPlaying1);
                                        if (!isPlaying1 && videoRef1.current) {
                                            videoRef1.current.play().catch(error => {
                                                console.error('Play failed:', error);
                                            });
                                        } else if (isPlaying1 && videoRef1.current) {
                                            videoRef1.current.pause();
                                        }
                                    }}
                                    onPlay={() => setIsPlaying1(true)}
                                    onPause={() => setIsPlaying1(false)}
                                    autoPlay
                                >
                                    <source src={Images.LabTourVideo} type="video/mp4" width={"100%"} />
                                    Your browser does not support the video tag.
                                </video>
                                <button onClick={handleTogglePlay} className={`btn position-absolute ${isPlaying1 ? 'hidden' : ''}`}>
                                    <FontAwesomeIcon icon={faPlay} />
                                </button>

                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 service-image-card cosmetic-video">
                            <h3 class="text-left">saliva collection method</h3>
                            <div className="video-container-div videobtn position-relative">
                                <video
                                    ref={videoRef2}
                                    className="embed-responsive-item"
                                    loop
                                    muted={true}
                                    playsInline
                                    width={"100%"}
                                    height={"100%"}
                                    onClick={() => {
                                        setIsPlaying2(!isPlaying2);
                                        if (!isPlaying2 && videoRef1.current) {
                                            videoRef2.current.play().catch(error => {
                                                console.error('Play failed:', error);
                                            });
                                        } else if (isPlaying2 && videoRef1.current) {
                                            videoRef2.current.pause();
                                        }
                                    }}
                                    onPlay={() => setIsPlaying2(true)}
                                    onPause={() => setIsPlaying2(false)}
                                    autoPlay
                                >
                                    <source src={Images.SampleTriGenome} type="video/mp4" width={"100%"} />
                                    Your browser does not support the video tag.
                                </video>
                                <button onClick={handleTogglePlay2} className={`btn position-absolute ${isPlaying2 ? 'hidden' : ''}`}>
                                    <FontAwesomeIcon icon={faPlay} />
                                </button>

                            </div>
                        </div>
                    </div>
                </div >
            </div >

            <DisplayModal
                show={showPdf}
                onHide={() => setShowPdf(false)}
                pdfSrc={selectedPdf}
            />
        </>
    )
}
export default GeneticsTestingComponent