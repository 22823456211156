import React, { useState, useEffect } from "react"
import Images from "../Images/Images";
import { Link, NavLink, useLocation } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faBars } from '@fortawesome/free-solid-svg-icons';
import { Navbar, Nav, Dropdown, DropdownToggle, DropdownItem, DropdownMenu } from 'react-bootstrap';

// const NavigationBar = () => {
const MyNavbar = ({ customclassname }) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const [showRndDropdown, setShowRndDropdown] = useState(false);
    const [showServiceDropdown, setShowServiceDropdown] = useState(false);
    const [showProductsDropdown, setShowProductsDropdown] = useState(false);
    const [showKnowledgeCenterDropdown, setShowKnowledgeCenterDropdown] = useState(false);
    const [showFeasibilityDropdown, setShowFeasibilityDropdown] = useState(false);
    const [activeItem, setActiveItem] = useState('');
    const [feasiItem, setFeasiItem] = useState('');
    const [manuItem, setManuitem] = useState('');
    const [companyItem, setCompanyitem] = useState('');
    const [privateLabelItem, setPrivateLabelitem] = useState('');

    const location = useLocation();

    useEffect(() => {
        const currentPath = location.pathname;

        const splitPath = currentPath.split('/');
        const result = [splitPath[1], '/' + splitPath.slice(2).join('/')];

        // console.log(result[0], 'feasibility');

        setManuitem(result[0] === "manufacturing" ? "manufacturing" : "");
        setActiveItem(result[0] === "service" ? "service" : "");
        setActiveItem(result[0] === "product" ? "product" : "");
        setFeasiItem(result[0] === "feasibility" ? "feasibility" : "");
        setCompanyitem(result[0] === "company" ? "company" : "");
        setPrivateLabelitem(result[0] === "private-label" ? "private-label" : "");


    }, [location.pathname]);

    const data = Images.LogoRed

    // console.log(feasiItem)

    return (
        <>
            <div className="nav-section">
                {/* //navigation bar section */}
                <div className="navigation-bar">
                    <div className="container">
                        <div className="scroll">
                            <Navbar collapseOnSelect expand="lg" fixed="top" className="navigation-bar justify-content-end pb-0">
                                {/* <FontAwesomeIcon icon={faBars} className="fa-nav-bars" /> */}
                                <Navbar.Toggle aria-controls="responsive-navbar-nav" >
                                    <FontAwesomeIcon icon={faBars} className="fa-nav-bars" />
                                </Navbar.Toggle>
                                {/* <Navbar.Brand href="/">Your Brand</Navbar.Brand> */}
                                <Navbar.Collapse id="responsive-navbar-nav">
                                    <Nav className="mx-auto">
                                        <Nav.Link className="nav-link" as={NavLink} to="/" exact>HOME</Nav.Link>
                                        <Dropdown
                                            onMouseEnter={() => setShowDropdown(true)}
                                            onMouseLeave={() => setShowDropdown(false)}
                                            show={showDropdown}
                                        // onClick={() => handleCompanyDropdown(!showDropdown)}
                                        >
                                            <Dropdown.Toggle as={Nav.Link}
                                                className={`nav-link ${companyItem === "company" ? "active" : ""}`}>COMPANY</Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item as={NavLink} to="/company/aboutus">ABOUT US</Dropdown.Item>
                                                <Dropdown.Item as={NavLink} to="/company/our-team">OUR TEAM</Dropdown.Item>
                                                <Dropdown
                                                    onMouseEnter={() => setShowRndDropdown(true)}
                                                    onMouseLeave={() => setShowRndDropdown(false)}
                                                    show={showRndDropdown}
                                                    // onClick={() => handleRndDropdown(!showRndDropdown)}
                                                    style={{ position: 'relative', marginLeft: "0px" }}
                                                >
                                                    <Dropdown.Toggle className="nav-link sub-sub-menu" as={NavLink} to="/company/rnd">R&D</Dropdown.Toggle>
                                                    <Dropdown.Menu style={{ position: 'absolute', left: '150px', top: '-12px' }}>
                                                        <Dropdown.Item as={NavLink} to="/company/formulation-experience">FORMULATION EXPERIENCE</Dropdown.Item>
                                                        <Dropdown.Item as={NavLink} to="/company/quality-management">QUALITY MANAGEMENT</Dropdown.Item>
                                                        <Dropdown.Item as={NavLink} to="/company/scientific-publications">SCIENTIFIC PUBLICATIONS</Dropdown.Item>
                                                        <Dropdown.Item as={NavLink} to="/company/patents-certifications">PATENTS & CERTIFICATION</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        <Dropdown
                                            onMouseEnter={() => setShowServiceDropdown(true)}
                                            onMouseLeave={() => setShowServiceDropdown(false)}
                                            show={showServiceDropdown}
                                        >
                                            <Dropdown.Toggle className={`nav-link ${activeItem === "service" ? "active" : ""}`} as={NavLink} to="/service">SERVICE</Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item as={NavLink} to="/service/research-projects">RESEARCH PROJECTS</Dropdown.Item>
                                                <Dropdown.Item as={NavLink} to="/service/clinical-trial-manufacturing">CLINICAL TRIAL MANUFACTURING</Dropdown.Item>
                                                <Dropdown.Item as={NavLink} to="/service/fulfillment-services">FULFILLMENT SERVICES</Dropdown.Item>
                                                <Dropdown.Item as={NavLink} to="/service/genetics-testing">GENETICS TESTING</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        <Dropdown
                                            onMouseEnter={() => setShowProductsDropdown(true)}
                                            onMouseLeave={() => setShowProductsDropdown(false)}
                                            show={showProductsDropdown}>
                                            <Dropdown.Toggle className={`nav-link  ${activeItem === "product" ? "active" : ""}`} as={NavLink} to="/product">PRODUCTS</Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item as={NavLink} to="/product/virtual-products">VIRTUAL PRODUCT REVIEW</Dropdown.Item>
                                                <Dropdown.Item as={NavLink} to="/product/stock-products">STOCK PRODUCTS</Dropdown.Item>
                                                <Dropdown.Item as={NavLink} to="/product/oral-supplements">ORAL SUPPLEMENTS</Dropdown.Item>
                                                <Dropdown.Item as={NavLink} to="/product/cosmetic-products">COSMETIC PRODUCTS</Dropdown.Item>
                                                <Dropdown.Item as={NavLink} to="/product/speciality-products" >SPECIALITY PRODUCTS</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        <Nav.Link className={`nav-link  ${privateLabelItem === "private-label" ? "active" : ""}`} as={NavLink} to="/private-label">PRIVATE LABEL</Nav.Link>
                                        <Dropdown
                                            onMouseEnter={() => setShowFeasibilityDropdown(true)}
                                            onMouseLeave={() => setShowFeasibilityDropdown(false)}
                                            show={showFeasibilityDropdown}
                                        >
                                            <Dropdown.Toggle className={`nav-link ${feasiItem === "feasibility" ? "active" : ""}`} as={Nav.Link}

                                            >FEASIBILITY</Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item as={NavLink} to="/feasibility/vendor-enquiry">VENDOR ENQUIRY</Dropdown.Item>
                                                <Dropdown.Item as={NavLink} to="/feasibility/RFQ">RFQ</Dropdown.Item>
                                                <Dropdown.Item as={NavLink} to="/feasibility/NDA">NDA</Dropdown.Item>
                                                <Dropdown.Item as={NavLink} to="/feasibility/Faq">FAQ</Dropdown.Item>
                                                <Dropdown.Item as={NavLink} to="/feasibility/sample-request">SAMPLE REQUEST</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        <Dropdown
                                            onMouseEnter={() => setShowKnowledgeCenterDropdown(true)}
                                            onMouseLeave={() => setShowKnowledgeCenterDropdown(false)}
                                            show={showKnowledgeCenterDropdown}
                                        >
                                            {/* <Dropdown.Toggle
                                            className={`nav-link ${activeItem === "manufacturing" ? "active" : ""}`}
                                            as={Nav.Link} to="/manufacturing"
                                        > */}
                                            <Dropdown.Toggle
                                                className={`nav-link ${manuItem === "manufacturing" ? "active" : ""}`}
                                                as={Nav.Link}
                                            >
                                                KNOWLEDGE CENTER</Dropdown.Toggle>
                                            <Dropdown.Menu >
                                                <Dropdown.Item as={NavLink} to="/manufacturing"
                                                >MANUFACTURING</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Nav>
                                </Navbar.Collapse>
                            </Navbar>
                        </div>
                    </div>
                </div >

                {/* //brand naem and search bar section */}
                < div className={`brand-search-bar ${customclassname}`} >
                    <div className="pxy-30 container">
                        <div className="d-flex justify-content-between">
                            <NavLink to="/">
                                <img src={data} alt="LogoRed" />
                            </NavLink>
                            {/* <div className="search-bar">
                            <form class="d-flex search-form" role="search">
                                <div className="search-input">
                                    <FontAwesomeIcon className="fa-search" icon={faSearch} />
                                    <label htmlFor="searchInput">Search</label>
                                </div>
                                <input class="form-control" type="search" placeholder="" aria-label="Search" />
                                <button class="btn search-button" type="submit">SEARCH</button>
                            </form>
                        </div> */}
                        </div>
                    </div>
                </div >
            </div >
        </>
    );
};

export default MyNavbar
