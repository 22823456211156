import React from "react"
import ImgWithDescription from "../../../commonComponents/ImgWithDescription/ImgWithDescription"
import Images from "../../../commonComponents/Images/Images"

const OrganicProducts = () => {

    const data = {
        image: Images.OrganicProducts,
        heading: "Organic products",
        description: <><p>Our Commitment to Quality and Authenticity:<br />
            Finest Organic Herbs: We are dedicated to sourcing only the best organic herbs, ensuring that every powder we produce is a testament to the healing power of nature. Compliance with International Standards: Our manufacturing processes are not only top-notch but also in strict alignment with international guidelines, guaranteeing organic integrity and quality. Purity and Natural Goodness: Our herbal powders are free from synthetic additives and pesticides, offering a pure, natural choice for your wellness needs. <br /> Ideal for formulating natural products, our organic herbal powders encapsulate the essence of nature's healing properties. Choose our products to enrich your offerings with the goodness of nature, ensuring your customers receive the best in organic wellness solutions.</p>
        </>,
        titleDescription: <>Embrace True Wellness with Our Premium Organic Herbal Powders. Experience the pinnacle of natural wellness with our exceptional collection of Organic Herbal Powders. Crafted with dedication and care, our powders are manufactured adhering to the rigorous standards set by NPOP wild & NPOP processing under Council regulation (ECNo. 834/2007 Category A & F) and the Swiss Organic Farming Ordinance. These certifications assure that our products are of the highest quality, originating from India and meeting global organic standards.</>,

    }

    return (
        <>
            <ImgWithDescription {...data} />
        </>
    )
}
export default OrganicProducts