import React, { useEffect, useState } from "react"
import Nav from 'react-bootstrap/Nav';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import NavigationBar from "../../../commonComponents/NavigationBar/NavigationBar"
import Banner from "../../../commonComponents/Banner/Banner"
import Footer from "../../../commonComponents/Footer/Footer"
import Images from "../../../commonComponents/Images/Images"
import axios from "axios"
import { useParams } from 'react-router-dom';
import parse, { domToReact } from 'html-react-parser';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Slider from "react-slick";


const SkinCareProdctDescription = () => {

    const [singleProduct, setSingleProduct] = useState({});
    const [single, setSingle] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { id } = useParams();

    const image = Images.SkinCareProduct

    const data = {
        image: Images.StockProductBanner,
        title: "stock product",
    }

    const customParser = (node, index) => {
        if (node.type === 'tag' && node.name === 'img') {
            // Custom rendering for images
            return <img key={index} src={node.attribs.src} alt={node.attribs.alt} />;
        } else if (node.type === 'tag' && node.name === 'li') {
            // Custom rendering for list items
            return <li key={index}>{domToReact(node.children, { index })}</li>;
        } else if (node.type === 'tag' && node.name === 'ul') {
            // Custom rendering for unordered lists with class name
            return <ul key={index} className="list-items">{domToReact(node.children, { index })}</ul>;
        }
        return domToReact([node], { index });
    };

    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,        // Enable autoplay
        autoplaySpeed: 1500,
        centerMode: false,
        responsive: [
            {
                breakpoint: 1030,
                settings: {
                    slidesToShow: 1,
                    prevArrow: <FontAwesomeIcon icon={faChevronLeft} />,
                    nextArrow: <FontAwesomeIcon icon={faChevronRight} />,
                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 1,
                    prevArrow: <FontAwesomeIcon icon={faChevronLeft} />,
                    nextArrow: <FontAwesomeIcon icon={faChevronRight} />,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    prevArrow: <FontAwesomeIcon icon={faChevronLeft} />,
                    nextArrow: <FontAwesomeIcon icon={faChevronRight} />,
                }
            },
        ],
    }


    // api integration of single product description
    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    const singleProductEndpoint = `/single_product/${id}`;
    const singleProductUrl = `${apiUrl}${singleProductEndpoint}`;


    const fetchSingleProduct = () => {

        axios.get(singleProductUrl).then((res) => {
            //     if (res.data.status === 0 || res.data.message === "No records found") {
            //         setSingleProduct({});
            //     } else {
            //         setSingleProduct(res.data.data);
            //     }
            //     setLoading(false);
            // })

            if (res.data.status === 1) {
                const singleProduct = res.data.data;
                if (singleProduct.master_type === "product") {
                    console.log(singleProduct)
                    setSingleProduct(singleProduct);
                    setSingle(singleProduct.images.filter(image => image.file_category === "product"))

                } else {
                    setSingleProduct({});
                }
            } else {
                setSingleProduct({});
            }
            setLoading(false);
        })
            .catch((error) => {
                setError("Error fetching single product data.");
                setLoading(false);
            })
            .finally(() => {
                setLoading(false);
            });
    };


    useEffect(() => {
        fetchSingleProduct();
    }, [id]);

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>{singleProduct.title}</title>
                    {singleProduct.meta_description && <meta name="description" content={singleProduct.meta_description} />}
                    {singleProduct.meta_keyword && <meta name="keywords" content={singleProduct.meta_keyword} />}
                </Helmet>
                <NavigationBar />
                <Banner bannerImg={data.image} title={data.title} content={data.content} customClassName="stockproduct-page-banner-css" />
                <div className="skincare-cosmeticproducts-section header-bottom-line-left">
                    <div className="skincare-cosmeticproducts">
                        <div className="pxy-40">
                            <div className="product-list">
                                <div className="container">
                                    {/* <div className="row align-items-center mb-25">
                                    <div className="col-lg-6 col-md-12 col-sm-12">
                                        <div className="search-bar mb-55">
                                            <label className="text-bold" htmlFor="searchInput">SEARCH</label>
                                            <form class="d-flex search-form mt-28" role="search">
                                                <input class="form-control" type="search" placeholder="Enter a Keyword..." aria-label="Search" />
                                                <button class="btn search-button" type="submit">
                                                    <FontAwesomeIcon className="fa-search" icon={faSearch} />
                                                </button>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12 col-sm-12">
                                        <Nav className="skincare-cosmetic-tabs" variant="pills" defaultActiveKey="/home">
                                            <Nav.Item>
                                                <Nav.Link className="active">ALL</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link>TABLET</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link>CAPSULE</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link>OTHER</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </div>
                                </div> */}
                                    <div>
                                        {loading ? (
                                            <p>Loading...</p>
                                        ) : error ? (
                                            <p>{error}</p>
                                        ) : Object.keys(singleProduct).length > 0 ? (
                                            <>
                                                <>
                                                    <div className="row">

                                                        <div className="col-lg-6 col-md-12 col-sm-12">
                                                            {/* {singleProduct.file_category === "product" && singleProduct.file_path && ( */}
                                                            <div className="card show-arrow">
                                                                <div className="card-body">
                                                                    <div className="border-radius">
                                                                        {single.length <= 1 ?
                                                                            <div className="mx-auto">
                                                                                <img src={single[0].file_path} alt={single[0].file_name} width={"100%"} />
                                                                            </div>
                                                                            :
                                                                            <Slider {...settings}>
                                                                                {single.map((image, index) => (
                                                                                    <div key={index}>
                                                                                        <img src={image.file_path} alt={singleProduct.name} width={"100%"} />
                                                                                    </div>
                                                                                ))}
                                                                            </Slider>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* )} */}
                                                        </div>

                                                        <div className="col-lg-6 col-md-12 col-sm-12">
                                                            <h3 className="text-left py-20">{singleProduct.name}
                                                                <br /><h6>{singleProduct.category_name}</h6>
                                                            </h3>
                                                            {/* <div className="my-20">
                                                            <h6 className="text-capitalize mb-2 product-pack-specification">product & pack specifications</h6>
                                                            <ul className="list-items">
                                                                <li>HPMC Capsule/Cellulose/Hard Gel</li>
                                                                <li>60 Capsules per pack</li>
                                                                <li>Primary Pack - HDPE Container with screw cap wad</li>
                                                                <li>Shelf Life 3 Years</li>
                                                                <li>4 Color Label with PP substrate</li>
                                                                <li>200 Container/Shipper with Honeycomb partition</li>
                                                            </ul>
                                                        </div> */}
                                                            <div className="my-20">
                                                                <h6 className="text-capitalize mb-2">Short Description</h6>
                                                                <p>{singleProduct.short_description}</p>
                                                            </div>
                                                            <div className="my-20">
                                                                <h6 className="text-capitalize mb-2">Description</h6>
                                                                {/* <p>Extract Type - Dry</p>
                                                            <p>Full Spectrum Extract - Yes</p>
                                                            <p className="mt-2">Each 550mg Leeucowin Capsule contains following ingredients:</p>
                                                            <p>Haritaki (Terminalia chebula) 25mg, Bibhitak (Terminalia belerica) 25mg, Amalaki (Emblica officinalis) 50mg, Shirish (Albizzia lebbeck) 50mg, Neem (Azadirachta indica) 25mg, Panchvalkal (Ficus glomerata, Ficus virens, Ficus religiosa, Ficus benghalensis, and Thespesia populnea) Each 50mg, Shuddha Gandhak (Purified sulphur 25mg.)</p> */}
                                                                {singleProduct.product_description && <p>{parse(singleProduct.product_description, { replace: customParser })}</p>}
                                                            </div>

                                                        </div>
                                                    </div>
                                                    {/* <div className="row">
                                                    <div className="col-lg-6">
                                                        <div className="my-20">
                                                            <h6 className="mb-2 product-pack-specification">usp</h6>
                                                            <ul className="list-items">
                                                                <li>Contains Full spectrum extract of potent herbs</li>
                                                                <li>Adjuvan in mixed intimate infections</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="my-20">
                                                            <h4 className="mb-2">directions of use</h4>
                                                            <p>1 capsule twice daily with water or as directed by the physician.</p>
                                                            <div className="directionof-use-capsule mt-3">
                                                                <button class="btn">MOQ : 1000</button>
                                                                <button class="btn">CO - BRANDING : YES</button>
                                                                <button class="btn">CATEGORY : AYUSH</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}
                                                </>
                                                {/* ))} */}
                                            </>
                                        ) : (
                                            <p>No single product available.</p>
                                        )}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div >
                </div >
                <Footer />
            </HelmetProvider>

        </>
    )
}
export default SkinCareProdctDescription