import React, { useState, useEffect } from "react"
import Images from "../Images/Images"
import { NavLink } from "react-router-dom"
import axios from 'axios'


const Footer = () => {

    const currentYear = new Date().getFullYear();

    const data = [
        Images.LogoWhite,
        Images.FooterCertificationLogo1,
        Images.FooterCertificationLogo2,
        Images.FooterCertificationLogo3,
        Images.FooterCertificationLogo4,
        Images.FooterCertificationLogo5,
        Images.FooterCertificationLogo6,
        Images.watsap,
        Images.fb,
        Images.insta,
        Images.twitter,
        Images.youtube,
        Images.events,
        Images.articles,
    ]

    const [recentArticles, setRecentArticles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    //api integration of recent articles
    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    const recentArticleEndPoint = '/recent_articles';
    const recentArticleUrl = `${apiUrl}${recentArticleEndPoint}`

    const fetchRecentArticles = () => {

        axios.get(recentArticleUrl)
            .then((res) => {
                if (res.data.data === 0) {
                    setRecentArticles([]);
                } else {
                    setRecentArticles(res.data.data);
                }
                setLoading(false);
            })
            .catch((error) => {
                setError("Error fetching recent articles data.");
                setLoading(false);
            })
    }

    useEffect(() => {
        fetchRecentArticles();
    }, []);

    return (
        <>
            {/* //footer section starts */}
            <div className="footer-section">
                <div className="pxy-40 container">
                    <div className="row row-50 justify-content-between">
                        <div className="col-lg-4 col-md-12 col-sm-12">
                            <NavLink to="/" className="mb-55">
                                <img src={Images.LogoWhite} alt="logo" />
                            </NavLink>
                            <p className="font-family-latolight mt-5 mb-30">DeltasPharma India Private Limited specializes in contract manufacturing of Ayurvedic, natural supplements, and personal care products.</p>
                            <div className="d-flex certification-logo-section py-20">
                                <img src={Images.FooterCertificationLogo1} alt="CertificationLogo" width={"50px"} />
                                <img src={Images.FooterCertificationLogo2} alt="CertificationLogo" width={"50px"} />
                                <img src={Images.FooterCertificationLogo3} alt="CertificationLogo" width={"50px"} />
                                <img src={Images.FooterCertificationLogo4} alt="CertificationLogo" width={"50px"} />
                                <img src={Images.FooterCertificationLogo5} alt="CertificationLogo" width={"50px"} />
                                <img src={Images.FooterCertificationLogo6} alt="CertificationLogo" width={"50px"} />

                            </div>
                            <span>© {currentYear}. Private Policy. All Rights Reserved </span>
                        </div>
                        {/* //contact us  */}
                        <div className="col-lg-4 col-md-12 col-sm-12">
                            <h4 className="mb-4">CONTACT US</h4>
                            <div className="mb-2">
                                <p className="font-family-latolight">CORPORATE OFFICE: </p>
                                <h6>DELTASPHARMA INDIA PRIVATE LIMITED</h6>
                                <span className="font-family-latolight">1 to 5, 2nd Floor, Shreeji Arcade, Opp Nitin Co., Panchpakhadi, Thane (W) - 400 602. (Mumbai).</span>
                                <ul>
                                    <li className="font-family-latolight">Customer Care: +91 97028 22222
                                    </li>
                                </ul>
                            </div>
                            <div className="">
                                <p className="font-family-latolight">FACTORY: </p>
                                <h6>DELTASPHARMA INDIA PRIVATE LIMITED</h6>
                                <span className="font-family-latolight">Plot No. 6A, Sector D, AkVN Industrial Area Mandideep, Distt. Raisen, (MP), 462046, India.</span>
                            </div>
                            {/* <div className="d-flex icon-section justify-content-between">
                                <img src={Images.watsap} alt="watsap" width={"25px"} />
                                <img src={Images.fb} alt="fb" width={"15px"} />
                                <img src={Images.insta} alt="insta" width={"25px"} />
                                <img src={Images.twitter} alt="twitter" width={"25px"} />
                                <img src={Images.youtube} alt="youtube" width={"25px"} />
                            </div> */}
                        </div>
                        {/* //events and articles  */}
                        <div className="col-lg-3 col-md-12 col-sm-12">
                            <div >
                                <h4 >EVENTS</h4>
                            </div>
                            <div className="d-flex articles-events my-20">
                                <div>
                                    <img src={Images.events} alt="" width={"100px"} />
                                </div>
                                <div>
                                    <img src={Images.events} alt="" width={"100px"} />
                                </div>
                            </div>
                            <div >
                                <h4>RECENT ARTICLES</h4>
                            </div>
                            <div className="d-flex articles-events my-20 ">
                                {loading ? (
                                    <p>Loading...</p>
                                ) : error ? (
                                    <p>{error}</p>
                                ) : recentArticles.length > 0 ? (
                                    recentArticles.slice(-2).map((article) => (
                                        <>
                                            <div key={article.id} className="article-img-div">
                                                <img src={article.image} alt="article cover" width={"100px"} />
                                            </div>
                                        </>
                                    ))
                                ) : (
                                    <p> No Recent articles available.  </p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Footer