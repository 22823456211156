import React, { useState, useEffect } from "react"
import Images from "../../commonComponents/Images/Images"
import Slider from "react-slick"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { NavLink } from "react-bootstrap"
import DisplayModal from "../../commonComponents/DisplayModal/DisplayModal"
import axios from "axios"


const Patents = () => {

    const [showPdf, setShowPdf] = useState(false);
    const [selectedPdf, setSelectedPdf] = useState(null);
    const [patents, setPatents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const openPdfModal = (pdfSrc) => {
        setSelectedPdf(pdfSrc);
        setShowPdf(true);
    };

    // const patentscarousel = [
    //     {
    //         id: 1,
    //         Image: Images.patentsCertificates,
    //         title: "Pancha Tulasi Patent",
    //         pdfSrc: Images.patent1,
    //     },
    //     {
    //         id: 2,
    //         Image: Images.patentsCertificates,
    //         title: "Joddaram Patent",
    //         pdfSrc: Images.patent2,

    //     },
    //     {
    //         id: 3,
    //         Image: Images.patentsCertificates,
    //         title: "Dia Now Patent",
    //         pdfSrc: Images.patent3,

    //     },
    //     {
    //         id: 4,
    //         Image: Images.patentsCertificates,
    //         title: "Psorawin Patent",
    //         pdfSrc: Images.patent4,

    //     },
    // ]

    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: false,        // Enable autoplay
        autoplaySpeed: 1500,
        centerMode: false,
        responsive: [
            {
                breakpoint: 2560,
                settings: {
                    slidesToShow: 4,
                    prevArrow: <FontAwesomeIcon icon={faChevronLeft} />,
                    nextArrow: <FontAwesomeIcon icon={faChevronRight} />,
                }
            },
            {
                breakpoint: 1030,
                settings: {
                    slidesToShow: 4,
                    prevArrow: <FontAwesomeIcon icon={faChevronLeft} />,
                    nextArrow: <FontAwesomeIcon icon={faChevronRight} />,
                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 2,
                    prevArrow: <FontAwesomeIcon icon={faChevronLeft} />,
                    nextArrow: <FontAwesomeIcon icon={faChevronRight} />,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    prevArrow: <FontAwesomeIcon icon={faChevronLeft} />,
                    nextArrow: <FontAwesomeIcon icon={faChevronRight} />,
                }
            },
        ],
    }

    // api integration of fetch patents
    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    const patentsEndPoint = '/certificate_type/1';
    const patentUrl = `${apiUrl}${patentsEndPoint}`;

    const fetchPatents = () => {
        axios.get(patentUrl).then((res) => {
            if (res.data.status === 0 || res.data.message === "No records found") {
                setPatents([]);
            } else {
                setPatents(res.data.data);
            }
            setLoading(false);
        })
            .catch((error) => {
                setError("Error fetching patents data.");
                setLoading(false);
            })
    }

    useEffect(() => {

        fetchPatents();
    }, []);


    return (
        <>
            {/* //patents */}
            <div className="patentsCertificates header-bottom-line">
                <div className="pxy-75 pb-0">
                    <h3 className="text-center py-20 ">patents</h3>
                    <div className="mt-40">
                        <div className='card-carousel-main'>
                            {loading ? (
                                <p>Loading...</p>
                            ) : error ? (
                                <p>{error}</p>
                            ) : (
                                <Slider {...settings}>
                                    {patents.map((obj) => (
                                        <NavLink onClick={() => openPdfModal(obj.pdf_path)}>
                                            <div className='carousel-card-main services-carousel' key={obj.id}>
                                                <div className='carousel-card-nav card'>
                                                    <h6 className="text-center mb-0">{obj.name}</h6>
                                                    <div className="img-container">
                                                        <img src={Images.patentsCertificates} className='carousel-card-img' width={"100%"} />
                                                    </div>
                                                </div>
                                            </div>
                                        </NavLink>
                                    ))
                                    }
                                </Slider>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <DisplayModal
                show={showPdf}
                onHide={() => setShowPdf(false)}
                pdfSrc={selectedPdf}
            />
        </>
    )
}
export default Patents