import React from "react"
import NavigationBar from "../../../commonComponents/NavigationBar/NavigationBar"
import Footer from "../../../commonComponents/Footer/Footer"
import AccordianSection from "../../../commonComponents/AccordianSection/AccordianSection";

const FAQ = () => {



    const accordionData = [

        {
            question: "Do you have current formulations available for private labeling?",
            answer: "No. We custom make all of our formulations. We do not believe in giving the same formulation to many different customers. That is why each formulation is made from scratch to your exact specifications. And, once we produce a formula for you we will never use that formulation for any other customer."
        },
        {
            question: "Do you do reverse engineering of products?",
            answer: "Yes. We can take any product you like and duplicate it for you."
        },
        {
            question: "How quickly can you provide samples?",
            answer: "In most cases we can have your sample ready in 2 to 3 weeks unless we need to order a special ingredient for your sample."
        },
        {
            question: "How much does it cost to have a sample produced?",
            answer: "It costs minimum of Rs 5000 per product. However, if you place orders for that product with us then we will credit that Rs 5000 back to your account."
        },
        {
            question: "What is your standard lead time for manufacturing and filling a product?",
            answer: "Obviously this depends on the size of your order. However we can usually complete most orders in a three (3) to four (4) week lead time."
        },
        {
            question: "I already have all my packaging components and bulk product, can you just fill my containers for me?",
            answer: "Yes, we can provide this service."
        },
        {
            question: "Can you supply product in Bulk?",
            answer: "Yes"
        },
        {
            question: "Do you provide the service of obtaining containers, caps, labels etc.. and silk screening the containers?",
            answer: "Yes. Although all these services may not be available in house, we are happy to provide these services to our customers."
        },
        {
            question: "Do you support for regulatory compliances?",
            answer: "Yes, we support for regulatory compliance on fee for service basis."
        },
        {
            question: "Should I go with a custom or stock formulation?",
            answer: "As industry professionals we recommend that you start with our carefully crafted stock formulations for several reasons. By private labelling one of our stock formulations, you can take advantage of the lower pricing that comes from purchasing trending products out of an exponentially larger production run with shorter lead times. With custom formulations, you are paying a higher price on an unproven formula at lower volumes with longer lead times."
        },
        {
            question: "What is your minimum order quantity?",
            answer: <>MOQ for Stock products 1,500 bottles<br />
                MOQ for custom capsules or tablets 150,000 pieces ex 60unit 2,500 bottles<br />
                MOQ for custom liquid 2,500-5,000 bottles (depending on complexity of formula)<br />
                MOQ for custom cream / gel  are 10,000 pieces ex 50g<br />
                MOQ for custom lozenges 500K-1 million pieces (depending on complexity of formula).<br />
                MOQ for custom flavored powder 1000kg or 2,500 bottles per flavor</>
        },
        {
            question: "What does it cost to launch a product?",
            answer: "There are many variables that influence the cost of launching a product. Stock product costs is product and volume dependent. When it comes to launching a custom product, it would be dependent on the cost of ingredients, if the product needs a flavor profile as well as any R&D. That said, typical custom formulation costs minimum 100K Rs. Please bear in mind that these figures include bottling, desiccant Pack for freshness, lid, heat induction seal under the cap, basic testing for GMP Compliance, and tamper proof neck band seal, label application, but do not include the cost of labels, shipping of finished products, marketing, etc."
        },
        {
            question: "Can you ship internationally?",
            answer: "DPIPL does not handle international logistics. We are experts in international compliance and can provide yourself and freight forwarder all necessary documentation for import/export. The freight forwarder would pick up the goods from our warehouse and deliver them anywhere in the world"
        },
        {
            question: "What types of packaging do you offer?",
            answer: "We primarily work with Blister, HDPE, PET Bottles, Laminated tubes, Laminated sachets, water based spary bottles, pump based dispensers in a variety of sizes and shapes. In addition, we have the capability to work with glass containers and gusset bags. Custom packaging is always an option, though custom packaging MOQ’s are higher and are set by the packaging supplier. We can also take customer supplied packaging; however, we would need to see a sample of it before to make sure it will fit on our bottling line. Click here to view our packaging options."
        },
        {
            question: "What are your lead times?",
            answer: "Our standard lead time for stock products for a new customer is 4-5 weeks from label reception for HDPE, PET based capsules, liquids, powders and tablets and laminates for a custom products it would be 6-8 weeks total based. We encourage our clients to order double the labels on the initial run to expedite reorders and receive a better label price. Packaging will not be scheduled until the labels are printed and in our hands."
        },
        {
            question: "What is your testing process?",
            answer: "From raw materials to completed products, DPIPL’s product testing and quality control processes are rigorous and formulatic. Materials are always checked and verified multiple times throughout the production and packing lifecycle. Our testing procedures meet GMP standards."
        },
        {
            question: "Can you assist with Marketing?",
            answer: "Yes. We can fulfill all your graphic design needs. Services ranging from graphic design, logo creation, marketing, and promotional literatures can be added to your order for a separate cost. Our team has a combined 50 years experience and has seen what the successful companies do right and what the losers do wrong. Let us guide you down the right path and ensure we are all successful together."
        },
        {
            question: "Do you offer drop shipping?",
            answer: "Not at this time however we offer our fulfillment services."
        },
        {
            question: "Do you offer fulfillment?",
            answer: "When your private label supplement is flying off the shelves, you will require an organized and reliable facility to store and ship it out. We have a large, state-of-the-art warehouse that is equipped to store your products. Large and small, it is from here that we pick, pack and ship your orders. We offer advanced software to accurately maintain inventory and to provide you with vital statistics that can aid you in several ways."
        },
        {
            question: "Can you help us with Amazon compliance?",
            answer: "DPIPL can provide all the documents that you need to set up your Amazon Seller’s account. We are experts in Amazon compliance, marketing, formulation and development. We are contracted with an ISO Certified lab for all our clients needs. Letter of Guarantees and COA requests are typically handled in 3-5 business days."
        },
        {
            question: "Can you send me samples?",
            answer: "Yes! You can order samples right through the SMP Nutra website. With the number of inquiries we receive, it is not possible for us to organize and send free samples. Each of our stock products gives you the option to place an order for the samples of your choice. Once you place a full product order and are a partner/customer of DPIPL you can ask your sales representative for samples of other products you are interested in."
        }
    ];


    return (
        <>
            <NavigationBar customclassname="feasibility-navsection" />
            <div className="faq header-bottom-line">
                <div className="container">
                    <div className="pxy-40">
                        <h3 className="text-center py-20 ">FREQUENTLY ASKED QUESTIONS</h3>
                        <div className="mt-70">
                            <div className="row">
                                <div className="col-lg-1">
                                </div>
                                <div className="col-lg-10 vendor-enquiry-form">
                                    <AccordianSection accordionData={accordionData} />
                                </div>
                                <div className="col-lg-1">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
}
export default FAQ