import React, { useState, useEffect } from "react"
import Slider from "react-slick"
import { NavLink } from "react-router-dom"
import axios from "axios"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import Images from "../../commonComponents/Images/Images"


const Services = () => {

    const [services, setServices] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // const services = [
    //     {
    //         id: 1,
    //         Image: Images.ServiceImg1,
    //         name: "Research Projects",
    //         landing_url: "/service/research-projects",
    //         news: "DISCOVER",
    //     },
    //     {
    //         id: 2,
    //         Image: Images.Serviceimg2,
    //         name: "Clinical Trial Manufacturing",
    //         landing_url: "/service/clinical-trial-manufacturing",
    //         news: "DISCOVER",
    //     },
    //     {
    //         id: 3,
    //         Image: Images.Serviceimg3,
    //         name: "Fulfilment services",
    //         landing_url: "/service/fulfillment-services",
    //         news: "DISCOVER",
    //     },
    //     {
    //         id: 4,
    //         Image: Images.Serviceimg4,
    //         name: "Top 10 New Products in the Pharmaceutical Industry",
    //         landing_url: "/service/clinical-trial-manufacturing",
    //         news: "DISCOVER",
    //     },
    // ]

    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: false,        // Enable autoplay
        autoplaySpeed: 1500,
        centerMode: false,

        responsive: [
            {
                breakpoint: 1030,
                settings: {
                    slidesToShow: 3,
                    prevArrow: <FontAwesomeIcon icon={faChevronLeft} />,
                    nextArrow: <FontAwesomeIcon icon={faChevronRight} />,
                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 2,
                    prevArrow: <FontAwesomeIcon icon={faChevronLeft} />,
                    nextArrow: <FontAwesomeIcon icon={faChevronRight} />,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    prevArrow: <FontAwesomeIcon icon={faChevronLeft} />,
                    nextArrow: <FontAwesomeIcon icon={faChevronRight} />,
                }
            },
        ],
    }


    //get services api integration from cms
    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    const servicesEndpoint = '/servicedata';
    const servicesUrl = `${apiUrl}${servicesEndpoint}`;


    const fetchServices = () => {

        axios.get(servicesUrl).then((res) => {
            if (res.data.status === 0 || res.data.message === "No records found") {
                setServices([]);
            } else {
                setServices(res.data.data);
            }
            setLoading(false);
        })
            .catch((error) => {
                setError("Error fetching service data.");
                setLoading(false);
            })
    };


    useEffect(() => {
        fetchServices();
    }, []);


    return (
        <>
            {/* //services homepage */}

            <div className="services header-bottom-line">
                <div className="pxy-40">
                    <div className="px-25">
                        <h3 className="text-center py-20 ">SERVICES</h3>
                        <div className="mt-40">
                            <div className='card-carousel-main'>
                                {loading ? (
                                    <p>Loading...</p>
                                ) : error ? (
                                    <p>{error}</p>
                                ) : services.length > 0 ? (
                                    <Slider {...settings}>
                                        {
                                            services.map((service) => (
                                                <div className='carousel-card-main services-carousel' key={services.id}>
                                                    <NavLink to={service.landing_url}>
                                                        <div className='carousel-card-nav card'>
                                                            <div className="img-container">
                                                                <img src={service.image} alt={service.name} className='carousel-card-img' width={"100%"} />
                                                            </div>
                                                            <div className="carousel-content font-family-regular">
                                                                <p className='carousel-title'>{service.name}</p>
                                                                <button className='btn'>DISCOVER</button>
                                                            </div>
                                                        </div>
                                                    </NavLink>
                                                </div>
                                            ))
                                        }
                                    </Slider>
                                ) : (
                                    <p>No services available.</p>
                                )}
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        </>
    )
}
export default Services