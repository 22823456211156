import React, { useState, useEffect, useRef } from "react"
import Images from "../../commonComponents/Images/Images"
import Slider from "react-slick"
import { NavLink } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'

const paragraphStyles = {
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    display: '-webkit-box',
}

const OurProcessComponent = () => {
    const [isOpen, setIsOpen] = useState(false)
    const [showReadMoreBtn, setShowReadMoreBtn] = useState(false)

    const ref = useRef(null)

    useEffect(() => {
        if (ref.current) {
            // console.log(ref.current.scrollHeight, ref.current.clientHeight)
            setShowReadMoreBtn(ref.current.scrollHeight !== ref.current.clientHeight)
        }
    }, [ref.current])


    const data = {
        image1: Images.meeting,
        image2: Images.labelling,
        image3: Images.branding,
        image4: Images.design,

    };

    const privatelablecarousel = [
        {
            id: 1,
            Image: Images.ResearchProject,
            title: "RESEARCH PROJECTS",
            link: "/service/research-projects",
            news: "DISCOVER",
        },
        {
            id: 2,
            Image: Images.ClinicalTrialManufacturing,
            title: "CLINICAL TRIAL MANUFACTURING",
            link: "/service/clinical-trial-manufacturing",
            news: "DISCOVER",
        },
        {
            id: 3,
            Image: Images.FulfillmentServices,
            title: "FULFILLMENT SERVICES",
            link: "/service/fulfillment-services",
            news: "DISCOVER",
        },

    ]

    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: false,        // Enable autoplay
        autoplaySpeed: 1500,
        centerMode: false,

        responsive: [
            {
                breakpoint: 1030,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 2,
                    prevArrow: <FontAwesomeIcon icon={faChevronLeft} />,
                    nextArrow: <FontAwesomeIcon icon={faChevronRight} />,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    prevArrow: <FontAwesomeIcon icon={faChevronLeft} />,
                    nextArrow: <FontAwesomeIcon icon={faChevronRight} />,
                }
            },
        ],
    };


    return (
        <>
            {/* //our process  */}
            <div className="pxy-40 our-process-branding header-bottom-line">
                <h3 className="text-center py-20 ">our process: simplifying your journey</h3>
                <div className="pt-40 container">
                    <div className="row row-25 text-center">
                        <div className="col-lg-4 col-md-12 col-sm-12 divisions">
                            <div><img src={data.image1} alt="exploring meeting" className="display-inline" /></div>
                            <h6 className="py-20 mt-20">initial exploring meeting</h6>
                            <p className="font-family-latolight text-center" ref={ref} style={isOpen ? null : paragraphStyles}>A no-obligation consultation to understand your business status, goals, vision, and product ideas, allowing us to tailor our services to your unique needs.
                                Choose Your Labelling Option:
                                <ul className="list-items">
                                    <li>Private-Label: With competitive formulation fees, our experts craft or reverse-engineer formulas tailored to your audience.</li>
                                    <li>White-Label: Select from our existing product range and rebrand them as your own, with a low minimum order quantity.</li>
                                </ul>
                            </p>
                            {<div class="mt-3 text-center">
                                <button class="btn ash-border" onClick={() => setIsOpen(!isOpen)}>{isOpen ? "LEARN LESS" : "LEARN MORE"}</button>
                            </div>}
                        </div>
                        {/* <div className="col-lg-3 col-md-12 col-sm-12 divisions">
                            <div><img src={data.image2} alt="" /></div>
                            <h6 className="py-20 mt-20">choose your labelling option</h6>
                            <p className="font-family-latolight text-center">Private-Label: With competitive formulation fees, our experts craft or reverse-engineer formulas tailored to your audience</p>
                            <div class="mt-3 text-center">
                                <button class="btn ash-border">LEARN MORE</button>
                            </div>
                        </div> */}
                        <div className="col-lg-4 col-md-12 col-sm-12 divisions">
                            <div><img src={data.image3} alt="branding & logo consultation" className="display-inline" /></div>
                            <h6 className="py-20 mt-20">branding & logo consultation</h6>
                            <p className="font-family-latolight text-center">Collaborate with our designers to bring your brand vision to life through impactful labelling and branding concepts.</p>
                            {/* <div class="mt-3 text-center">
                                <button class="btn ash-border">LEARN MORE</button>
                            </div> */}
                        </div>
                        <div className="col-lg-4 col-md-12 col-sm-12 divisions">
                            <div><img src={data.image4} alt="packaging & label design" className="display-inline" /></div>
                            <h6 className="py-20 mt-20">packaging & label design</h6>
                            <p className="font-family-latolight text-center">Choose from our pre-designed containers and labels or opt for a custom design, printed and shipped by our professional team.</p>
                            {/* <div class="mt-3 text-center">
                                <button class="btn ash-border">LEARN MORE</button>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>

            {/* //carousel */}
            <div className="services header-bottom-line">
                <div className="pxy-40 pt-0">
                    <div className="px-25">
                        <div className='card-carousel-main'>
                            <Slider className="private-label-carousel-shadow mt-40" {...settings}>
                                {
                                    privatelablecarousel.map((obj) => (
                                        <div className='carousel-card-main services-carousel' key={obj.id}>
                                            <NavLink to={obj.link}>
                                                <div className='carousel-card-nav card'>
                                                    <div className="img-container">
                                                        <img src={obj.Image} className='carousel-card-img' width={"100%"} />
                                                    </div>
                                                    <div className="carousel-content font-family-regular">
                                                        <p className='carousel-title text-left letter-spacing-1'>{obj.title}</p>
                                                        <button className='btn'>{obj.news}</button>
                                                    </div>

                                                </div>
                                            </NavLink>
                                        </div>
                                    ))
                                }
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default OurProcessComponent