import React from 'react'

function Banner({ bannerImg, title, content, customClassName }) {
    return (

        //banner section
        <div className='banner-main'>
            <div className='other-page-banner-relative'>
                <img src={bannerImg} style={{ width: '100%' }} />
                <div className='bannerText d-flex align-items-center'>
                    <div className={`home-banner-text-btn other-page-banner ${customClassName}`}>
                        <h1>{title}</h1>
                        <h4 className='header-section'>{content}</h4>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Banner