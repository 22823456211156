import React from "react"
import NavigationBar from "../../commonComponents/NavigationBar/NavigationBar"
import Footer from "../../commonComponents/Footer/Footer"
import Banner from "../../commonComponents/Banner/Banner"
import Images from "../../commonComponents/Images/Images"
import OralSupplements from "../../components/PrivateLabelProducts/OralSupplements"

const OralSupplementsPage = () => {
    const data = {
        image: Images.AboutUsBanner,
        title: "WELCOME TO DELTAS PHARMA",
        content: "Decoding the Natural Adjuvants",
    }


    return (
        <>
            <NavigationBar />
            <Banner bannerImg={data.image} title={data.title} content={data.content} />
            <OralSupplements />
            <Footer />
        </>
    )
}
export default OralSupplementsPage