import React, { useState, useEffect } from "react"
import Slider from "react-slick"
import { NavLink } from "react-router-dom"
import axios from "axios"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import Images from "../../commonComponents/Images/Images"


const PrivateLabel = () => {

    const [privateLables, setPrivateLables] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // const privateLables = [
    //     {
    //         id: 1,
    //         Image: Images.PrivateLabelImg1,
    //         name: "Oral Supplements",
    //         landing_url: "/product/oral-supplements",
    //         news: "DISCOVER",
    //     },
    //     {
    //         id: 2,
    //         Image: Images.PrivateLabelImg2,
    //         name: "Cosmetic Products",
    //         landing_url: "/product/cosmetic-products",
    //         news: "DISCOVER",
    //     },
    //     {
    //         id: 3,
    //         Image: Images.PrivateLabelImg3,
    //         name: "Oral Hygiene",
    //         landing_url: "/",
    //         news: "DISCOVER",
    //     },

    // ]

    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: false,        // Enable autoplay
        autoplaySpeed: 1500,
        centerMode: false,
        responsive: [
            {
                breakpoint: 1030,
                settings: {
                    slidesToShow: 3,
                    prevArrow: <FontAwesomeIcon icon={faChevronLeft} />,
                    nextArrow: <FontAwesomeIcon icon={faChevronRight} />,
                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 2,
                    prevArrow: <FontAwesomeIcon icon={faChevronLeft} />,
                    nextArrow: <FontAwesomeIcon icon={faChevronRight} />,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    prevArrow: <FontAwesomeIcon icon={faChevronLeft} />,
                    nextArrow: <FontAwesomeIcon icon={faChevronRight} />,
                }
            },
        ],
    };

    //get private labels from cms, api integration
    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    const privateLabelEndPoint = '/private_labledata';
    const privateLabelUrl = `${apiUrl}${privateLabelEndPoint}`;

    const fetchPrivateLabels = () => {

        axios
            .get(privateLabelUrl)
            .then((res) => {
                if (res.data.status === 0 || res.data.message === "No records found") {
                    setPrivateLables([]);
                } else {
                    setPrivateLables(res.data.data);
                }
                setLoading(false);
            })
            .catch((error) => {
                setError("Error fetching private labels data.");
                setLoading(false);
            })
    };

    useEffect(() => {
        fetchPrivateLabels();
    }, []);

    // }

    return (
        <>
            {/* //private label */}
            <div className="services header-bottom-line">
                <div className="pxy-40">
                    <div className="px-25">
                        <h3 className="text-center py-20 ">PRIVATE LABELS</h3>
                        <div className="mt-40">
                            <div className='card-carousel-main'>

                                <p className="mx-100 mx-275 text-center">Deltas offers top-quality, competitively priced private label supplements, manufactured to outperform current best-sellers based on market trends. Our commitment as your natural products partner is to streamline the creation of superior formulas both  swiftly and affordably.</p>
                                {loading ? (
                                    <p>Loading...</p>
                                ) : error ? (
                                    <p>{error}</p>
                                ) : privateLables.length > 0 ? (
                                    <Slider className="private-label-carousel-shadow mt-40" {...settings}>
                                        {
                                            privateLables.map((label) => (
                                                <div className='carousel-card-main services-carousel' key={label.id}>
                                                    <NavLink to={label.landing_url}>
                                                        <div className='carousel-card-nav card'>
                                                            <div className="img-container">
                                                                <img src={label.image} alt={label.name} className='carousel-card-img' width={"100%"} />
                                                            </div>
                                                            <div className="carousel-content font-family-regular">
                                                                <p className='carousel-title'>{label.name}</p>
                                                                <button className='btn'>DISCOVER</button>
                                                            </div>

                                                        </div>
                                                    </NavLink>
                                                </div>
                                            ))
                                        }
                                    </Slider>
                                ) : (
                                    <p>No private labels available.</p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default PrivateLabel