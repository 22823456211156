import React, { useState, useEffect, useRef } from "react"
import Form from 'react-bootstrap/Form'
import axios from "axios"
import { ToastContainer, toast } from 'react-toastify'


const getFormattedDate = (date) => {
    const day = date.getDate();
    const monthNames = [
        "January", "February", "March", "April", "May", "June", "July",
        "August", "September", "October", "November", "December"
    ];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();

    // Suffix for the day
    let daySuffix;
    if (day === 1 || day === 21 || day === 31) {
        daySuffix = 'st';
    } else if (day === 2 || day === 22) {
        daySuffix = 'nd';
    } else if (day === 3 || day === 23) {
        daySuffix = 'rd';
    } else {
        daySuffix = 'th';
    }

    return `${day}${daySuffix} ${month}`;
};

const NDAComponent = () => {
    const [isRadioChecked, setIsRadioChecked] = useState(false);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [errors, setErrors] = useState({});
    const [countries, setCountries] = useState([]);

    const [formData, setFormData] = useState({
        first_name: "",
        last_name: "",
        company: "",
        title: "",
        mail: "",
        address: "",
        website_url: "",
        city: "",
        country_id: "",
        // other: "",
        zip_code: "",
    });

    const refs = {
        first_name: useRef(null),
        last_name: useRef(null),
        company: useRef(null),
        title: useRef(null),
        mail: useRef(null),
        address: useRef(null),
        website_url: useRef(null),
        city: useRef(null),
        country_id: useRef(null),
        zip_code: useRef(null),
    };

    useEffect(() => {
        const fetchCountries = async () => {
            try {
                const apiUrl = process.env.REACT_APP_API_BASE_URL;
                const countryListEndPoint = '/countrys_list';
                const countryListUrl = `${apiUrl}${countryListEndPoint}`;

                const response = await axios.get(countryListUrl);
                if (response.data.status === 1 && response.data.code === 200) {
                    setCountries(response.data.data);
                } else {
                    console.error("Error fetching countries:", response.data.message);
                }
            } catch (error) {
                console.error("Error fetching countries:", error);
            }
        };

        fetchCountries();
    }, []);

    const [recitalFormData, setRecitalFormData] = useState({
        company: "",
        country_id: "",
    });


    // const handleInputChange = (event) => {
    //     const { name, value } = event.target;
    //     setFormData({
    //         ...formData,
    //         [name]: value
    //     });

    //     // Automatically fill other input fields if the input field is "company"
    //     if (name === 'companyname' || name === 'city') {
    //         setRecitalFormData({
    //             ...recitalFormData,
    //             [name]: value
    //         });
    //     }
    // };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });

        if (name === 'company') {
            setRecitalFormData({
                ...recitalFormData,
                company: value
            });
        }

        clearError(name);
    };

    const handleSelectChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });

        if (name === 'country_id') {
            const selectedCountry = countries.find(country => country.id === parseInt(value));
            if (selectedCountry) {
                setRecitalFormData({
                    ...recitalFormData,
                    country_id: selectedCountry.country_name
                });
            }
        }

        clearError(name);
    };

    const clearError = (name) => {
        const newErrors = { ...errors };
        delete newErrors[name];
        setErrors(newErrors);
    };

    const handleRadioChange = () => {
        setIsRadioChecked(!isRadioChecked);
    };

    // const handleSubmit = (event) => {
    //     event.preventDefault();
    //     // Perform form submission logic here
    //     setIsFormSubmitted(true);
    // };

    // handle submit of vendor enquiry form 
    const handleSubmit = (event) => {
        event.preventDefault();
        if (validateForm()) {
            setSubmitting(true);

            // Prepare data to be sent
            const requestData = {
                first_name: formData.first_name,
                last_name: formData.last_name,
                company: formData.company,
                title: formData.title,
                mail: formData.mail,
                address: formData.address,
                website_url: formData.website_url,
                city: formData.city,
                country_id: formData.country_id,
                // other: formData.other,
                zip_code: formData.zip_code,
                pdf_path: formData.pdf_path,

            };


            // API integration of vendor form submit
            const apiUrl = process.env.REACT_APP_API_BASE_URL;
            const NDAEndPoint = '/disclosure_agreement';
            const NDAUrl = `${apiUrl}${NDAEndPoint}`;

            axios.post(NDAUrl, requestData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then((res) => {
                    if (res.data.status === 1 && res.data.code === 200) {
                        resetForm();
                        toast.success('Form submitted successfully!');
                        setIsRadioChecked(false);
                        setSubmitting(false);
                    } else {
                        console.error("Error:", res.data.message);
                        toast.error('Form submission failed.');
                    }
                })
                .catch((error) => {
                    console.error("Error:", error);
                    toast.error('Form submission failed.');
                    setSubmitting(false);
                })

                .finally(() => {
                    setSubmitting(false);
                });
        } else {
            console.log('Test');
        }
    };

    useEffect(() => {
        if (Object.keys(errors).length > 0) {
            const firstErrorField = Object.keys(errors)[0];
            if (firstErrorField && refs[firstErrorField]) {
                refs[firstErrorField].current.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [errors, refs]);

    const validateForm = () => {
        let formIsValid = true;
        const newErrors = {};

        // Check if any field is empty
        Object.keys(formData).forEach((key) => {
            // console.log(formData.other)
            if (formData.other === "") {
                formIsValid = true;
            }
            if (!formData[key]) {
                newErrors[key] = "This field is required.";
                formIsValid = false;
            }
        });

        // Check email format
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(formData.mail)) {
            newErrors.mail = "Please enter a valid email address.";
            formIsValid = false;
        }
        // console.log(formIsValid)
        setErrors(newErrors);
        return formIsValid;
    };

    const resetForm = () => {
        setFormData({
            first_name: "",
            last_name: "",
            company: "",
            title: "",
            mail: "",
            address: "",
            website_url: "",
            city: "",
            country_id: "",
            // other: "",
            zip_code: "",
        });

        setRecitalFormData({
            company: "",
            country_id: "",
        });
    };

    const currentDate = new Date();

    const currentYear = currentDate.getFullYear();
    const formattedDate = getFormattedDate(currentDate);


    return (
        <>
            <div className="nda header-bottom-line">
                <div className="container">
                    <div className="pxy-40">
                        <h3 className="text-center py-20 ">non-disclosure agreement</h3>
                        <div className="">
                            <div className="row">
                                <div className="col-lg-1">
                                </div>
                                <div className="col-lg-10 vendor-enquiry-form">
                                    <p className="text-bold mt-70 font-size-28">
                                        Your Information
                                    </p>
                                    <form onSubmit={handleSubmit} className="mt-40">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-12 col-sm-12">
                                                <label>First Name*</label>
                                                <input
                                                    type="text"
                                                    name="first_name"
                                                    value={formData.first_name}
                                                    onChange={handleChange}
                                                    ref={refs.first_name}

                                                />
                                                {errors.first_name && <p className="error-message">{errors.first_name}</p>}
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12">
                                                <label>Last Name*</label>
                                                <input
                                                    type="text"
                                                    name="last_name"
                                                    value={formData.last_name}
                                                    onChange={handleChange}
                                                    ref={refs.last_name}

                                                />
                                                {errors.last_name && <p className="error-message">{errors.last_name}</p>}
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12">
                                                <label>Company Name*</label>
                                                <input
                                                    type="text"
                                                    name="company"
                                                    value={formData.company}
                                                    onChange={handleChange}
                                                    ref={refs.company}

                                                />
                                                {errors.company && <p className="error-message">{errors.company}</p>}
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12">
                                                <label>Title</label>
                                                <input
                                                    type="text"
                                                    name="title"
                                                    value={formData.title}
                                                    onChange={handleChange}
                                                    ref={refs.title}

                                                />
                                                {errors.title && <p className="error-message">{errors.title}</p>}
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12">
                                                <label>Email*</label>
                                                <input
                                                    type="email"
                                                    name="mail"
                                                    value={formData.mail}
                                                    onChange={handleChange}
                                                    ref={refs.mail}

                                                />
                                                {errors.mail && <p className="error-message">{errors.mail}</p>}
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12">
                                                <label>Street Address</label>
                                                <input
                                                    type="text"
                                                    name="address"
                                                    value={formData.address}
                                                    onChange={handleChange}
                                                    ref={refs.address}

                                                />
                                                {errors.address && <p className="error-message">{errors.address}</p>}
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12">
                                                <label>Website Url</label>
                                                <input
                                                    type="url"
                                                    name="website_url"
                                                    value={formData.website_url}
                                                    onChange={handleChange}
                                                    ref={refs.website_url}

                                                />
                                                {errors.website_url && <p className="error-message">{errors.website_url}</p>}
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12">
                                                <label>City</label>
                                                <input
                                                    type="text"
                                                    name="city"
                                                    value={formData.city}
                                                    onChange={handleChange}
                                                    ref={refs.city}

                                                />
                                                {errors.city && <p className="error-message">{errors.city}</p>}
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12">
                                                <label>Country / Region*</label>
                                                <Form.Select name="country_id"
                                                    aria-label="Default select example"
                                                    defaultValue="select"
                                                    onChange={handleSelectChange}
                                                    ref={refs.country_id}
                                                >
                                                    <option value="select">Please Select</option>
                                                    {countries.map(country => (
                                                        <option key={country.id} value={country.id}>{country.country_name}</option>
                                                    ))}
                                                </Form.Select>
                                                {errors.country_id && <p className="error-message">{errors.country_id}</p>}
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12">
                                                <label>Zip Code</label>
                                                <input
                                                    type="text"
                                                    name="zip_code"
                                                    value={formData.zip_code}
                                                    onChange={handleChange}
                                                    ref={refs.zip_code}

                                                />
                                                {errors.zip_code && <p className="error-message">{errors.zip_code}</p>}
                                            </div>

                                        </div>
                                        <p className="mt-3">THIS MUTUAL NON-DISCLOSURE AGREEMENT (this "Agreement") is made effective as of  {formattedDate}, by {currentYear} and between Deltaspharma India Private Limited (DPIPL) and
                                            <input
                                                className="nds-inputs"
                                                type="text"
                                                name="company"
                                                value={recitalFormData.company}
                                                readOnly
                                            // onChange={(event) => setRecitalFormData({ ...recitalFormData, company: event.target.value })}
                                            />,
                                            <input
                                                className="nds-inputs"
                                                type="text"
                                                name="country_id"
                                                value={recitalFormData.country_id}
                                                readOnly
                                            // onChange={(event) => setRecitalFormData({ ...recitalFormData, country_id: event.target.value })}
                                            />

                                            (Country).</p>
                                        <div>
                                            <p className="text-bold text-center py-18 font-size-22">
                                                RECITALS
                                            </p>
                                            <p>
                                                DPIPL and
                                                <input
                                                    className="nds-inputs"
                                                    type="text"
                                                    name="company"
                                                    value={recitalFormData.company}
                                                    readOnly
                                                // onChange={(event) => setRecitalFormData({ ...recitalFormData, company: event.target.value })}
                                                />are considering entering into a business relationship (the "sensitive information being shared between the Parties"). DPIPL and  <input
                                                    className="nds-inputs"
                                                    type="text"
                                                    name="company"
                                                    value={recitalFormData.company}
                                                    readOnly
                                                // onChange={(event) => setRecitalFormData({ ...recitalFormData, company: event.target.value })}
                                                />(Disclosing Party) (hereinafter referred to as "Party" or "Parties") acknowledge that in connection therewith,  <input
                                                    className="nds-inputs"
                                                    type="text"
                                                    name="company"
                                                    value={recitalFormData.company}
                                                    readOnly
                                                // onChange={(event) => setRecitalFormData({ ...recitalFormData, company: event.target.value })}
                                                />
                                                Party
                                                are considering entering into a business relationship (the "sensitive
                                                information being shared between the Parties."). DPIPL and (Disclosing Party) (hereinafter
                                                referred to as "Party" or "Parties") acknowledge that in connection therewith,
                                                ("Disclosing Party") and/or its agents or advisors (including, without limitation attorneys, accountants,
                                                consultants, bankers, financial advisors and any representatives of such advisors) (collectively
                                                "Representatives") has delivered or will deliver or relay to the DPIPL ("Receiving Party") and/or its
                                                Representatives certain "Confidential Information" (as defined below).</p>
                                            <p>
                                                Purpose. The Parties wish to explore a business opportunity of mutual interest (the "Opportunity"),  <input
                                                    className="nds-inputs"
                                                    type="text"
                                                    name="company"
                                                    value={recitalFormData.company}
                                                    readOnly
                                                // onChange={(event) => setRecitalFormData({ ...recitalFormData, company: event.target.value })}
                                                /> and
                                                in connection with the Opportunity,
                                                (Disclosing Party) has disclosed, and may
                                                further disclose, certain confidential technical and business information (in such capacity, a Party
                                                disclosing the information, the "Discloser") to the DPIPL (in such capacity, a Party receiving the
                                                information, the "Recipient") that Discloser desires Recipient to treat as confidential.
                                            </p>
                                            <p className="font-size-22 text-left text-bold">Confidential Information</p>
                                            <p>
                                                Definition."Confidential Information" means any information (including any and all combinations of
                                                individual items of information) disclosed by Discloser to Recipient, directly or indirectly, including any
                                                information disclosed prior to the Effective Date, including, without limitation, research, product plans,
                                                products, services, equipment, customers, markets, software, inventions, discoveries, ideas, processes,
                                                designs, drawings, hardware, formulations, specifications, product configuration information, marketing
                                                and finance documents, prototypes, samples, data sets, and equipment, whether disclosed in writing,
                                                orally or by inspection of tangible objects and whether or not designated as "confidential" at the time of
                                                disclosure. Information of a third party that is in the possession of Discloser and is disclosed to Recipient
                                                under this Agreement may also constitute Confidential Information.</p>
                                            <p>

                                                <span className="text-bold">Exceptions:</span> Confidential Information shall not, however, include any information that Recipient can
                                                establish: (i) was publicly known or made generally available without a duty of confidentiality prior to the
                                                time of disclosure to Recipient by Discloser; (ii) becomes publicly known or made generally available
                                                without a duty of confidentiality after disclosure to Recipient by Discloser through no improper action or
                                                inaction of Recipient; (iii) is in the rightful possession of Recipient without confidentiality obligations at
                                                the time of disclosure by Discloser to Recipient as shown by Recipient's then-contemporaneous written
                                                files and records kept in the ordinary course of business; (iv) is obtained by Recipient from a third party
                                                without an accompanying duty of confidentiality and without a breach of such third party's obligations
                                                of confidentiality; or (v) is independently developed by Recipient without use of or reference to
                                                Confidential Information of Discloser, as shown by written records and other competent evidence
                                                prepared contemporaneously with such independent development; provided that any combination of
                                                individual items of information shall not be deemed to be within any of the foregoing exceptions merely
                                                because one or more of the individual items are within such exception, unless the combination as a
                                                whole is within such exception
                                            </p>
                                            <p>
                                                <span className="text-bold">Compelled Disclosure:</span> If Recipient becomes legally compelled to disclose any Confidential Information,
                                                other than pursuant to a confidentiality agreement, Recipient will provide Discloser prompt written
                                                notice of such compelled disclosure and will use its best efforts to assist Discloser in seeking a protective
                                                order or another appropriate remedy. If Discloser waives Recipient's compliance with this Agreement or
                                                fails to obtain a protective order or other appropriate remedy, Recipient will furnish only that portion of
                                                the Confidential Information that is legally required to be disclosed; provided that any Confidential
                                                Information so disclosed shall maintain its confidentiality protection for all purposes other than such
                                                legally compelled disclosure.

                                            </p>
                                            <p>
                                                <span className="text-bold"> Non-use and Non-disclosure:</span> Recipient shall not use any Confidential Information of Discloser for any
                                                purpose except to evaluate and engage in discussions concerning the Opportunity. The receiving party
                                                will not copy, decompile, modify, reverse engineer, or create derivative works out of any Confidential
                                                Information. Recipient shall not disclose any Confidential Information of Discloser or permit any
                                                Confidential Information of Discloser to be disclosed, either directly or indirectly, to any third party
                                                without Discloser's prior written consent; provided, however, that Recipient may disclose Confidential
                                                Information of Discloser to Recipient's employees who are required to have such information in order to
                                                evaluate or engage in discussions concerning the Opportunity. Recipient shall not reverse engineer,
                                                disassemble, or decompile any prototypes, software, samples, or other tangible objects that embody
                                                Confidential Information of Discloser.
                                            </p>
                                            <p>

                                                <span className="text-bold">Maintenance of Confidentiality:</span> Recipient shall take reasonable measures to protect the secrecy of and avoid disclosure and unauthorized use of the Confidential Information of Discloser. Without limiting the foregoing, Recipient shall take at least those measures it employs to protect its own confidential information of a similar nature. Recipient shall reproduce Discloser's proprietary rights notices on any copies of Confidential Information of Discloser in the same manner in which such notices were set forth in or on the original. Recipient shall promptly notify Discloser of any unauthorized use or disclosure, or suspected unauthorized use or disclosure, of Confidential Information of Discloser of which Recipient becomes aware.

                                            </p>
                                            <p>
                                                <span className="text-bold"> No Obligation:</span> Nothing in this Agreement shall obligate either Party to proceed with the Opportunity, any business relationship, or any transaction between them, and each Party reserves the right, in its sole discretion, to terminate the discussions contemplated by this Agreement concerning the Opportunity. Nothing in this Agreement shall be construed to restrict either Party's use or disclosure of its own Confidential Information.
                                            </p>
                                            <p>

                                                <span className="text-bold">No Warranty:</span> ALL CONFIDENTIAL INFORMATION IS PROVIDED "AS IS." NEITHER PARTY MAKES ANY WARRANTIES, EXPRESS, IMPLIED OR OTHERWISE, REGARDING THE ACCURACY, COMPLETENESS OR PERFORMANCE OF ANY CONFIDENTIAL INFORMATION, OR WITH RESPECT TO NON-INFRINGEMENT OR OTHER VIOLATION OF ANY INTELLECTUAL PROPERTY RIGHTS OF A THIRD PARTY OR OF RECIPIENT. Return of Materials: All documents and other tangible objects containing or representing Confidential Information that have been disclosed by Discloser to Recipient, and all copies or extracts thereof or notes derived therefrom that are in the possession of Recipient, shall be and remain the property of Discloser and shall be promptly returned to Discloser or destroyed upon Discloser's written request. No License: Nothing in this Agreement is intended to grant any rights to Recipient under any patent, mask work, copyright or other intellectual property right of Discloser, nor shall this Agreement grant Recipient any rights in or to any Confidential Information of Discloser except as expressly set forth in this Agreement.
                                            </p>
                                            <p>

                                                <span className="text-bold">Term:</span> The obligations of Recipient under this Agreement shall survive, with respect to any particular Confidential Information of Discloser, until One (1) year from the Effective Date; provided, that, to the extent any Confidential Information of Discloser constitutes a trade secret under applicable law, notwithstanding the foregoing, the obligations of Recipient hereunder with respect to such Confidential Information shall continue until such Confidential Information becomes publicly known or made generally available through no action or inaction of Recipient.
                                                Remedies: Recipient agrees that any violation or threatened violation of this Agreement may cause irreparable injury to Discloser, entitling Discloser to seek injunctive relief in addition to all available legal remedies.

                                            </p>
                                            <p>
                                                <span className="text-bold">Feedback:</span> Any ideas, suggestions, guidance or other information disclosed by Recipient related to any Confidential Information of Discloser and any intellectual property rights relating to the foregoing shall be collectively deemed "Feedback." Recipient agrees to grant and hereby grants to Discloser a non- exclusive, perpetual, irrevocable, royalty free, worldwide license (with the right to grant and authorize sublicenses) to make, have made, use, import, offer for sale, sell, reproduce, distribute, modify, adapt, prepare derivative works of, display, perform and otherwise exploit such Feedback without restriction on payment of one time consultation charges by the Discloser to the Recipient party for such Feedback. The consultation chargers shall be dependent on the Feedback provided and shall be decided solely by the Recipient party.
                                            </p>
                                            <p>

                                                <span className="text-bold"> Miscellaneous:</span> This Agreement shall bind and insure to the benefit of the Parties and their respective successors and permitted assigns. Neither Party may assign or otherwise transfer this Agreement without the prior written consent of the other Party; provided, however, that a Party may assign this Agreement without consent in connection with a merger, reorganization, consolidation, change of control, or sale of all or substantially all of its assets; provided further that the assigning Party provides prompt written notice to the other Party of any such permitted assignment. Any assignment or transfer of this Agreement in violation of the foregoing shall be null and void.
                                            </p>
                                            <p>
                                                No provision of this Agreement may be amended or otherwise modified except by a writing signed by the Parties to this Agreement. No provision of this Agreement may be waived except by a writing executed by the Party against whom the waiver is to be effective. A Party's failure to enforce any provision of this Agreement shall neither be construed as a waiver of the provision nor prevent such Party from enforcing any other provision of this Agreement. Each Party hereby represents and warrants that the persons executing this Agreement on its behalf have express authority to do so, and, in so doing, to bind such Party thereto.
                                            </p>
                                            <p>
                                                This Agreement will be interpreted and construed in accordance with the Indian laws, without regard to conflict of law principles. All disputes arising out of this Agreement will be subject to the exclusive jurisdiction of courts in Mumbai,
                                            </p>
                                            <p>
                                                This Agreement contains the entire agreement between the Parties with respect to the Opportunity and supersedes all prior written and oral agreements between the Parties regarding the Opportunity. If a court or other body of competent jurisdiction finds any provision of this Agreement, or portion thereof, to be invalid or unenforceable, such provision will be enforced to the maximum extent permissible so as to effect the intent of the Parties, and the remainder of this Agreement will continue in full force and effect.
                                            </p>
                                            <p>

                                                The Parties may execute this Agreement in counterparts, each of which shall be deemed an original, but all of which together constitute one and the same agreement. This Agreement may be delivered by facsimile, electronic means or .pdf, and any facsimile or .pdf copies of executed signature pages shall be binding as originals.
                                            </p>
                                            <p>
                                                IN WITNESS WHEREOF, the Parties by their duly authorized representatives have executed this Mutual Non-Disclosure Agreement as of the Effective Date.
                                            </p>
                                            <div className="radio-button-division ">
                                                <div className="d-flex align-items-center">
                                                    <div className="form-check">
                                                        <input
                                                            className="form-check-input radio-btn"
                                                            type="checkbox"
                                                            name="flexRadioDefault"
                                                            id="flexRadioDefault1"
                                                            checked={isRadioChecked}
                                                            onChange={handleRadioChange}
                                                        />
                                                        <label className="form-check-label radio-label" htmlFor="flexRadioDefault1">
                                                            By checking this box, I hereby agree to the terms of this agreement.*
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-30 text-center">
                                            <button className="btn" type="submit" disabled={!isRadioChecked || submitting}>{submitting ? "Submitting..." : "SUBMIT"}</button>
                                        </div>
                                    </form>
                                </div>
                                <div className="col-lg-1">
                                </div>
                            </div>
                        </div>
                    </div>
                    <ToastContainer />
                </div>
            </div>
        </>
    )
}
export default NDAComponent