import React from "react"
import NavigationBar from "../../../commonComponents/NavigationBar/NavigationBar"
import Banner from "../../../commonComponents/Banner/Banner"
import Footer from "../../../commonComponents/Footer/Footer"
import Images from "../../../commonComponents/Images/Images"
import SpecialityProductTabs from "../../../components/ProductModuleComponents/SpecialityProductsComponent/SpecialityProductTabs"

const SpecialityProducts = () => {

    const data = {
        image: Images.SpecialityProductBanner,
        title: "speciality products",
    }

    return (
        <>
            <NavigationBar />
            <Banner bannerImg={data.image} title={data.title} content={data.content} customClassName="specialityproducts-page-banner-css" />
            <SpecialityProductTabs />
            <Footer />
        </>
    )
}
export default SpecialityProducts