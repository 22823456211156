import React from "react"
import NavigationBar from "../../../commonComponents/NavigationBar/NavigationBar"
import Footer from "../../../commonComponents/Footer/Footer"
import RFQComponent from "../../../components/RFQComponent/RFQComponent"

const RFQ = () => {
    return (
        <>
            <NavigationBar customclassname="feasibility-navsection" />
            <RFQComponent />
            <Footer />
        </>
    )
}
export default RFQ