import React, { useEffect, useState, useRef } from "react"
import NavigationBar from "../../commonComponents/NavigationBar/NavigationBar"
import Banner from "../../commonComponents/Banner/Banner"
import Footer from "../../commonComponents/Footer/Footer"
import Images from "../../commonComponents/Images/Images"
import { NavLink, useParams, Link, useLocation, useNavigate } from "react-router-dom"
import axios from "axios"
import parse, { domToReact } from 'html-react-parser'
import moment from "moment"
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { ToastContainer, toast } from 'react-toastify'



const ManufacturingBlogs = () => {

    const [blogs, setBlogs] = useState([]);
    // const [blogComment, setBlogComment] = useState([]);
    const [recentArticles, setRecentArticles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [submitting, setSubmitting] = useState(false);
    const [errors, setErrors] = useState({});
    const commentRef = useRef(null);
    const location = useLocation();
    const [singleBlogComment, setSingleBlogComment] = useState([]);

    const scrollToComment = () => {
        if (commentRef.current) {
            commentRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    useEffect(() => {
        console.log('Received state:', location.state);
        if (location.state && location.state.scrollId) {
            const element = document.querySelector(location.state.scrollId);
            console.log('ee', element)
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
            console.log('ere', element.scrollIntoView({ behavior: 'smooth' }))
        }
    }, [location.state]);


    const [formData, setFormData] = useState({
        first_name: "",
        last_name: "",
        mail: "",
        phone_number: "",
        blog_comment: "",
    })
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
        clearError(name);
    };
    const clearError = (name) => {
        const newErrors = { ...errors };
        delete newErrors[name];
        setErrors(newErrors);
    };


    const data = {
        image: Images.ManufacturingPageBanner,
        title: "unlocking discoveries:",
        content: "exploring the frontiers of science",
    };

    // const blogs = [

    //     {
    //         index: 1,
    //         title: "Fucoxanthin : A Marine Ally in the Battle against Obesity",
    //         postedBy: "by Umesh Dahiwalkar",
    //         postedOn: "Mar 26, 2024 at 5:46 pm",
    //         sections: [
    //             {
    //                 heading: "Introduction:",
    //                 content: "The increasing prevalence of obesity and associated lifestyle diseases has led to a search for effective interventions. Among them, fucoxanthin, a carotenoid from brown seaweeds, emerges as a promising candidate in the anti-obesity battle. This article delves deeper into fucoxanthin's potential and its development into functional foods and drugs."
    //             },
    //             {
    //                 image: Images.ManufacturingBlog,
    //                 heading: "Overview of Fucoxanthin:",
    //                 content: "Fucoxanthin, a xanthophyll cartenoid primarily found in brown seaweeds, is known for its diverse biological activities, including antioxidant, anti-inflammatory, anti-cancer effects. Its role in combating obesity, a particular through fat metabolism and thermogenesis, has drawn significant research interest."
    //             },
    //             {
    //                 heading: " Fucoxanthin's Role in Tackling Obesity: ",
    //                 content: "Research indicates that fucoxanthin targets key molecular pathways in fat metabolism. It induces the expression of uncoupling protein I (UCPI) in white adipose tissue (WAT), Promoting the oxidation of fatty acids and heat production. This process acids in reducing fat accumulation and supports weight loss. Additionally, fucoxanthin helps improve insulin resistance and lower blood glucose levels, crucial in managing obesity-related complications."
    //             },
    //             {
    //                 heading: "Nutrigenomic Insights:",
    //                 image: Images.ManufacturingBlog2,
    //                 content: "Nutrigenomic studies have hignighted fucoxanthin's unique structural elements, such as the allenic bond and hydroxyl groups in its polyene chromophore. These components are instrumental in modulating genes associated with fat metabolism, leading to a decrease in body fat and weight.",
    //             },
    //             {
    //                 heading: "Clinical Trial Evidence:",
    //                 content: "In a significant clinical trial in Japan, fucoxanthin supplementation showed notable benefits in reducing body weight, BMI, and fat mass among obese adults. The study's findings suggest fucoxanthin's safety and efficacy as a moderate anti-obesity intervention."
    //             },
    //             {
    //                 heading: "Development Challenges and Opportunities:  ",
    //                 content: "Transforming fucoxanthin into a functional food or drug involves overcoming its low bioavailability. Research is focused on enhancing its absorption and determining its safety and optimal dosage for use. Despite these challenges, fucoxanthin's diverse biological properties and anti-obesity effects make it a compelling candidate for future health products. "
    //             },
    //             {
    //                 heading: "Conclusion: ",
    //                 content: "Fucoxanthin stands out as a promising marine ingredient in the fight against obesity.With ongoing research into its mechanisms and optimization for human use, fucoxanthin holds great potential as a novel functional food ingredient and marine- derived pharmaceutical in addressing the obesity epidemic.Further studies are essential to fully establish its efficacy and safety for human consumption. "
    //             },
    //         ],
    //     },
    //     {
    //         index: 2,
    //         title: "Shilajeet, a swift restorative:",
    //         postedBy: "by Umesh Dahiwalkar",
    //         postedOn: "Mar 26, 2024 at 5:46 pm",
    //         description: "Shilajit, also known as 'the destroyer of weakness', is a potent substance found in the rocky mountains of the Himalayas, Altai, Caucasus, and other mountain ranges. This dark, resinous material has been treasured for centuries in traditional Ayurvedic and Tibetan medicine systems for its purported health benefits. In recent years, scientific research has begun to shed light on the potential therapeutic properties of Shilajit, leading to increased interest in its use for various health conditions.",
    //         sections: [
    //             {
    //                 heading: "Historical Significance:",
    //                 content: "Shilajit has a rich history dating back thousands of years, with mentions in ancient Ayurvedic texts such as the Charaka Samhita and Sushruta Samhita. It has been revered as a powerful rejuvenating substance and is often referred to as 'rasayana' or 'the elixir of life' in Ayurveda. Traditionally, Shilajit was used to enhance vitality, promote longevity, and treat a wide range of ailments, including diabetes, arthritis, infertility, and cognitive decline."
    //             },
    //             {
    //                 heading: "Composition and Source: ",
    //                 content: "Shilajit is a complex mixture of organic and inorganic compounds that forms over centuries from the decomposition of plant matter and minerals trapped within rocks. It contains a variety of bioactive components, including fulvic acid, humic acid, dibenzo-α-pyrones, trace minerals, and various organic compounds. The composition of Shilajit can vary depending on its geographical origin and the conditions under which it was formed."
    //             },
    //             {
    //                 heading: "Health Benefits:",
    //                 content: "Research on Shilajit has revealed a multitude of potential health benefits, many of which align with its traditional uses in Ayurvedic medicine. Some of the most notable health benefits associated with Shilajit include:",
    //                 listItems: <>
    //                     <ul>
    //                         <li>Anti-inflammatory Properties: Shilajit contains compounds with potent anti-inflammatory effects, which may help alleviate pain and inflammation associated with conditions like arthritis and inflammatory bowel disease.</li>
    //                         <li>Antioxidant Activity: Shilajit is rich in antioxidants, such as fulvic acid and dibenzo-α-pyrones, which help neutralize harmful free radicals in the body and protect against oxidative stress-related damage.
    //                         </li>
    //                         <li>Enhanced Cognitive Function: Several studies have suggested that Shilajit may improve cognitive function and memory, making it a potential therapeutic agent for neurodegenerative diseases like Alzheimer's and Parkinson's.</li>
    //                         <li>Increased Energy and Stamina: Shilajit has long been used as a natural energy booster and aphrodisiac, with anecdotal reports suggesting that it can enhance physical endurance, stamina, and libido.
    //                         </li>
    //                         <li>Regulation of Blood Sugar Levels: Preliminary research indicates that Shilajit may help regulate blood sugar levels and improve insulin sensitivity, making it a promising adjunctive therapy for diabetes management.
    //                         </li>
    //                         <li>Enhanced Nutrient Absorption: The fulvic acid present in Shilajit has been shown to enhance nutrient absorption in the gut, potentially improving overall nutrient status and supporting optimal health.
    //                         </li>
    //                         <li>Wound Healing: Some studies suggest that Shilajit may possess wound-healing properties, promoting faster wound closure and tissue regeneration.</li>
    //                     </ul></>
    //             },
    //             {
    //                 heading: "Scientific Evidence:",
    //                 content: "While much of the evidence supporting the health benefits of Shilajit is anecdotal or based on traditional use, a growing body of scientific research has begun to validate its therapeutic potential. Several studies have investigated the bioactive components of Shilajit and their effects on various physiological processes, including inflammation, oxidative stress, cognitive function, and metabolic health. For example, a study published in the Journal of Ethnopharmacology found that Shilajit extract exhibited significant anti-inflammatory and antioxidant effects in animal models of arthritis. Similarly, a review published in the Journal of Alzheimer's Disease reported that Shilajit supplementation improved cognitive function and reduced amyloid β levels in animal models of Alzheimer's disease."
    //             },
    //             {
    //                 heading: "Safety and Considerations: ",
    //                 content: "While Shilajit is generally considered safe for most people when used appropriately, it is essential to exercise caution and consult with a healthcare professional before starting any new supplement regimen, especially for individuals with underlying health conditions or those taking medications. Additionally, the quality and purity of Shilajit products can vary significantly, so it is crucial to source Shilajit from reputable suppliers to ensure its safety and efficacy. "
    //             },
    //             {
    //                 heading: "Conclusion  ",
    //                 content: "Shilajit is a fascinating natural substance with a long history of traditional use and emerging scientific evidence supporting its potential health benefits. While more research is needed to fully understand its mechanisms of action and therapeutic applications, the existing evidence suggests that Shilajit may offer promise as a natural remedy for a wide range of health conditions. As interest in natural and holistic approaches to health continues to grow, Shilajit stands out as a compelling option for those seeking to enhance their well-being and vitality through natural means.  "
    //             },
    //         ],
    //     },
    //     {
    //         index: 3,
    //         title: "Exploring the Therapeutic Potential of Apigenin for Skin and Hair Health",
    //         postedBy: "by Umesh Dahiwalkar",
    //         postedOn: "Mar 26, 2024 at 5:46 pm",
    //         sections: [
    //             {
    //                 heading: "Introduction:",
    //                 content: "Apigenin, a natural flavonoid found abundantly in fruits, vegetables, and herbs, has garnered significant attention in recent years for its potential therapeutic effects on skin and hair health.With a rich history in traditional medicine and growing scientific interest, apigenin presents a promising avenue for addressing various dermatological concerns.In this comprehensive guide, we delve into the diverse pharmacological properties of apigenin and its application in skincare and haircare, supported by evidence from scientific studies and clinical trials."
    //             },
    //             {
    //                 heading: "Understanding Apigenin:",
    //                 content: "Apigenin belongs to the flavone subclass of flavonoids and is characterized by its antioxidant, anti-inflammatory, and anti-cancer properties. It is commonly found in parsley, celery, chamomile, and other botanical sources, making it readily accessible for therapeutic use. Through its interactions with molecular targets and signaling pathways, apigenin exerts a myriad of effects on cellular function, making it a versatile compound for skin and hair health."
    //             },
    //             {
    //                 heading: "Apigenin for Skin Health:",
    //                 listItems: <>
    //                     <ul>
    //                         <li>Antioxidant Protection: Apigenin demonstrates potent antioxidant activity, scavenging free radicals and protecting skin cells from oxidative stress-induced damage. This property is crucial for maintaining skin integrity and preventing premature aging.</li>
    //                         <li>Anti-Inflammatory Effects: Inflammation plays a central role in various skin conditions, including acne, eczema, and psoriasis. Apigenin exhibits anti-inflammatory properties by inhibiting pro-inflammatory mediators and cytokines, thereby reducing redness, swelling, and discomfort.
    //                         </li>
    //                         <li>Wound Healing: Studies have shown that apigenin accelerates the wound healing process by promoting collagen synthesis, angiogenesis, and epithelialization. Topical application of apigenin-rich formulations can enhance tissue repair and minimize scar formation.</li>
    //                         <li>UV Protection: Exposure to ultraviolet (UV) radiation is a major risk factor for skin damage and skin cancer. Apigenin offers photoprotective effects by attenuating UV-induced DNA damage, inflammation, and oxidative stress, thus reducing the risk of sunburn and photoaging.
    //                         </li>
    //                         <li>Antimicrobial Activity: Apigenin exhibits antimicrobial activity against various pathogens, including bacteria, fungi, and viruses. This property makes it beneficial for addressing acne, dermatitis, and other infectious skin conditions.
    //                         </li>
    //                     </ul></>
    //             },
    //             {
    //                 heading: "Apigenin for Hair Health:",
    //                 listItems: <>
    //                     <ul>
    //                         <li>Hair Growth Stimulation: Apigenin has been shown to promote hair growth by stimulating the proliferation of dermal papilla cells, prolonging the anagen phase of the hair growth cycle, and inhibiting the activity of 5-alpha reductase, an enzyme implicated in hair loss.</li>
    //                         <li>Dermal Papilla Cell Protection: Dermal papilla cells play a crucial role in hair follicle formation and hair growth. Apigenin protects these cells from oxidative stress and inflammation, thereby supporting optimal hair follicle function and longevity.
    //                         </li>
    //                         <li>Anti-Dandruff Effects: Dandruff is a common scalp condition characterized by flaking, itching, and inflammation. Apigenin possesses anti-fungal properties that help combat dandruff-causing fungi, such as Malassezia spp., restoring scalp health and improving overall hair quality.</li>
    //                         <li>Scalp Irritation Relief: Irritation and inflammation of the scalp can contribute to hair thinning and hair loss. Apigenin's anti-inflammatory effects soothe the scalp, alleviate discomfort, and create a conducive environment for healthy hair growth.
    //                         </li>
    //                         <li>Sebum Regulation: Excessive sebum production can lead to oily scalp and clogged hair follicles, exacerbating hair loss and scalp conditions. Apigenin helps regulate sebum secretion by modulating the activity of sebaceous glands, promoting a balanced scalp environment.
    //                         </li>
    //                     </ul></>
    //             },
    //             {
    //                 heading: "Clinical Evidence and Future Directions: ",
    //                 content: "Several preclinical and clinical studies have provided compelling evidence supporting the efficacy and safety of apigenin for skin and hair health. However, further research is warranted to elucidate its mechanisms of action, optimize dosage regimens, and explore potential synergies with other bioactive compounds. Additionally, the development of innovative formulations and delivery systems can enhance the bioavailability and therapeutic efficacy of apigenin-based products, paving the way for novel skincare and haircare solutions. "
    //             },
    //             {
    //                 heading: "Conclusion  ",
    //                 content: "Apigenin emerges as a promising natural compound with diverse therapeutic effects on skin and hair health. Its antioxidant, anti-inflammatory, and hair growth-promoting properties make it a valuable asset in dermatology and cosmetology. By harnessing the power of apigenin through topical formulations and dietary supplements, individuals can nurture radiant skin and vibrant hair, fostering overall well-being and confidence. As scientific research continues to unfold, apigenin holds the potential to revolutionize the field of natural skincare and haircare, offering safe and effective solutions for various dermatological concerns."
    //             },
    //         ],

    //     },
    //     {
    //         index: 4,
    //         title: "Menaquinone: The Role of Vitamin K2 in Health and Disease",
    //         postedBy: "by Umesh Dahiwalkar",
    //         postedOn: "Mar 26, 2024 at 5:46 pm",
    //         description: "In recent years, there has been growing interest in the role of menaquinone, also known as Vitamin K2, in promoting health and preventing disease. Menaquinone, a fat-soluble vitamin, is essential for various physiological processes in the body, including blood clotting, bone metabolism, and cardiovascular health. This comprehensive blog explores the scientific evidence surrounding menaquinone and its impact on health and disease, supported by extensive citations from reputable sources.",

    //         sections: [
    //             {
    //                 heading: "Introduction to Menaquinone",
    //                 content: "Menaquinone belongs to the family of Vitamin K compounds, which play a crucial role in the activation of proteins involved in blood clotting and bone mineralization. Unlike Vitamin K1 (phylloquinone), which is primarily found in green leafy vegetables, menaquinone is synthesized by bacteria in the gut and is also obtained through dietary sources such as fermented foods, meat, and dairy products."
    //             },
    //             {
    //                 heading: "Bone Health and Menaquinone",
    //                 content: "One of the well-established roles of menaquinone is its involvement in bone metabolism. Research suggests that menaquinone contributes to bone health by promoting osteoblast activity and inhibiting osteoclast activity, thus enhancing bone mineral density and reducing the risk of osteoporosis. Studies have shown that individuals with higher dietary intake of menaquinone have lower rates of bone fractures and higher bone mineral density compared to those with lower intake."
    //             },
    //             {
    //                 heading: "Cardiovascular Health and Menaquinone",
    //                 content: "Emerging evidence indicates that menaquinone may also play a critical role in cardiovascular health. Menaquinone is involved in the activation of matrix Gla-protein (MGP), a protein that helps prevent the calcification of blood vessels. Adequate levels of menaquinone have been associated with a reduced risk of arterial calcification, which is a significant risk factor for cardiovascular diseases such as heart attacks and strokes. Furthermore, menaquinone has been shown to have anti-inflammatory and antioxidant properties, which can help protect against endothelial dysfunction and atherosclerosis. "
    //             },
    //             {
    //                 heading: "Cancer Prevention and Menaquinone",
    //                 content: "Preliminary studies suggest that menaquinone may have potential anticancer properties. Menaquinone has been shown to inhibit the growth and proliferation of cancer cells in laboratory studies, particularly in breast, prostate, and lung cancer. Additionally, menaquinone may exert its anticancer effects through its role in regulating cell signaling pathways involved in apoptosis (programmed cell death) and cell cycle progression. However, further research is needed to fully elucidate the anticancer mechanisms of menaquinone and its potential as a therapeutic agent in cancer treatment."
    //             },
    //             {
    //                 heading: "Other Health Benefits of Menaquinone",
    //                 content: "In addition to its roles in bone health, cardiovascular health, and cancer prevention, menaquinone has been implicated in various other physiological processes. Studies suggest that menaquinone may play a role in immune function, insulin sensitivity, and cognitive health. Furthermore, menaquinone has been associated with a reduced risk of type 2 diabetes mellitus and cognitive decline in older adults. "
    //             },
    //             {
    //                 heading: "Conclusion  ",
    //                 content: "In conclusion, menaquinone, or Vitamin K2, plays a crucial role in promoting health and preventing disease. Its involvement in bone metabolism, cardiovascular health, cancer prevention, and other physiological processes underscores its importance as a dietary nutrient. Incorporating menaquinone-rich foods such as fermented foods, meat, and dairy products into the diet may help maintain optimal levels of this essential vitamin and support overall health and well-being. However, further research is needed to fully understand the mechanisms of action of menaquinone and its potential therapeutic applications in disease management.  "
    //             },
    //         ],

    //     },
    //     {
    //         index: 5,
    //         title: "Exploring the Health Benefits of Equol: A Comprehensive Guide",
    //         postedBy: "by Umesh Dahiwalkar",
    //         postedOn: "Mar 26, 2024 at 5:46 pm",
    //         sections: [
    //             {
    //                 heading: "Introduction: ",
    //                 content: "Equol, a metabolite derived from the microbial metabolism of dietary soy isoflavones, has gained significant attention in recent years for its potential health benefits. This blog post aims to delve into the scientific research surrounding equol, exploring its role in promoting health and potentially preventing diseases."
    //             },
    //             {
    //                 heading: "What is Equol? ",
    //                 content: "Equol is a compound produced by the gut microbiota during the digestion of soy isoflavones, particularly daidzein. Not everyone has the ability to produce equol, as it depends on the composition of an individual's gut microbiota."
    //             },
    //             {
    //                 title: "Health Benefits of Equol:",
    //                 heading: "Cardiovascular Health:",
    //                 content: "Studies suggest that equol may have cardiovascular protective effects, including lowering cholesterol levels and reducing the risk of atherosclerosis. "
    //             },
    //             {
    //                 heading: "Bone Health:",
    //                 content: "Equol has been studied for its potential to improve bone mineral density and reduce the risk of osteoporosis, particularly in postmenopausal women. "
    //             },
    //             {
    //                 heading: "Menopausal Symptoms:",
    //                 content: "Some research suggests that equol may help alleviate menopausal symptoms such as hot flashes and night sweats, although more studies are needed to confirm these effects."
    //             },
    //             {
    //                 title: "Potential Role in Disease Prevention:",
    //                 heading: "Breast Cancer:",
    //                 content: "Equol has been investigated for its potential protective effects against breast cancer, with some studies suggesting a lower risk of developing the disease in equol producers. "
    //             },
    //             {
    //                 heading: "Prostate Cancer:",
    //                 content: "Preliminary research indicates that equol may have anti-cancer properties and could potentially reduce the risk of prostate cancer. "
    //             },
    //             {
    //                 heading: "Conclusion  ",
    //                 content: "Equol, a metabolite derived from soy isoflavones, holds promise for promoting health and potentially preventing diseases such as cardiovascular disease, osteoporosis, and certain cancers. While more research is needed to fully understand its mechanisms of action and therapeutic potential, the existing body of evidence suggests that equol could be a valuable addition to dietary interventions aimed at improving overall health and reducing disease risk.  "
    //             },
    //         ],
    //     },
    //     {
    //         index: 6,
    //         title: "Cordyceps: Unveiling the Potential of Nature's Pharmacy",
    //         postedBy: "by Umesh Dahiwalkar",
    //         postedOn: "Mar 26, 2024 at 5:46 pm",
    //         description: "Cordyceps, a genus of fungi with over 400 species, has been a cornerstone in traditional Chinese medicine for its myriad health benefits. Originating from the high-altitude regions of Tibet and China, this unique fungus has piqued the interest of researchers globally due to its intriguing properties and potential therapeutic applications. This article delves into the captivating world of cordyceps, exploring its impact on health and disease while illuminating the scientific evidence supporting its medicinal claims. Origins of Cordyceps:",
    //         sections: [
    //             {
    //                 sectionContent: [
    //                     {
    //                         content: "Cordyceps, also known as 'caterpillar fungus,' flourishes in alpine environments, particularly on the larvae of certain insects. The most renowned species, Cordyceps sinensis, has been harvested for centuries for its alleged medicinal properties. Traditionally used in Chinese medicine to enhance vitality, boost energy, and improve longevity, cordyceps has gained widespread recognition as a potent health tonic.Nutritional Composition and Bioactive Compounds:"

    //                     },
    //                     {

    //                         content: "Cordyceps is laden with bioactive compounds, including polysaccharides, nucleosides, sterols, and peptides, which contribute to its pharmacological effects. These compounds exhibit antioxidant, immunomodulatory, anti-inflammatory, and adaptogenic properties, positioning cordyceps as a versatile remedy for various health conditions. Health Benefits of Cordyceps:"
    //                     },
    //                     {

    //                         content: "A plethora of studies have underscored the potential health benefits of cordyceps across multiple organ systems. Here are some key areas where cordyceps may exert its therapeutic effects:"
    //                     },


    //                 ]
    //             },
    //             {
    //                 heading: "Enhanced Athletic Performance: ",
    //                 content: "Cordyceps is lauded for its ability to improve stamina, endurance, and exercise performance. Research suggests that cordyceps supplementation may enhance oxygen utilization, increase ATP production, and reduce fatigue, making it a favorite among athletes and fitness enthusiasts."
    //             },
    //             {
    //                 heading: "Immune Modulation:",
    //                 content: "Cordyceps exhibits potent immunomodulatory effects, stimulating the activity of immune cells such as macrophages, natural killer cells, and T lymphocytes. By bolstering immune function, cordyceps may aid the body in defending against infections, viruses, and other pathogens. "
    //             },
    //             {
    //                 heading: "Cardiovascular Support:  ",
    //                 content: "Studies indicate that cordyceps may benefit cardiovascular health by lowering blood pressure, improving lipid profiles, and reducing inflammation. These cardioprotective effects could potentially lower the risk of heart disease and stroke. "
    //             },
    //             {
    //                 heading: "Anti-inflammatory Properties:   ",
    //                 content: "Cordyceps contains bioactive compounds with anti-inflammatory properties, which may help alleviate chronic inflammation associated with conditions like arthritis, asthma, and inflammatory bowel disease. "
    //             },
    //             {
    //                 heading: "Antioxidant Activity:  ",
    //                 content: "Cordyceps is rich in antioxidants, which combat oxidative stress and protect cells from damage caused by free radicals. This antioxidant activity may confer neuroprotective effects, enhance cognitive function, and reduce the risk of age-related cognitive decline. "
    //             },
    //             {
    //                 heading: "Anticancer Potential:   ",
    //                 content: "Preliminary studies suggest that cordyceps extracts may possess anticancer properties, inhibiting tumor growth, metastasis, and angiogenesis. While more research is needed to elucidate its mechanisms and efficacy, cordyceps holds promise as a complementary therapy for cancer treatment. "
    //             },
    //             {
    //                 heading: "Clinical Evidence and Research Studies: ",
    //                 content: "Numerous clinical trials and preclinical studies have investigated the therapeutic potential of cordyceps in various health conditions. For instance, a randomized controlled trial demonstrated that cordyceps supplementation improved exercise performance and reduced markers of fatigue in elderly individuals. Another study found that cordyceps extract supplementation decreased blood glucose levels and improved insulin sensitivity in patients with type 2 diabetes. "
    //             },
    //             {
    //                 heading: "Safety and Considerations:",
    //                 content: "While cordyceps is generally considered safe for most people when consumed in moderate amounts, it may interact with certain medications or exacerbate existing health conditions. Individuals with autoimmune disorders, bleeding disorders, or undergoing immunosuppressive therapy should exercise caution when using cordyceps. Additionally, pregnant or breastfeeding women should consult their healthcare provider before taking cordyceps supplements."
    //             },
    //             {
    //                 heading: "Conclusion: ",
    //                 content: "In conclusion, cordyceps represents a fascinating example of nature's pharmacopeia, offering a myriad of potential health benefits backed by scientific research. From enhancing athletic performance and immune function to supporting cardiovascular health and combating inflammation, cordyceps has emerged as a promising therapeutic agent with diverse applications. While further research is needed to elucidate its mechanisms and optimal dosages, cordyceps holds immense promise as a natural remedy for promoting health and wellness. "
    //             },
    //         ],
    //     },
    //     {
    //         index: 7,
    //         title: "The Wonder Natural Ingredient for Longevity: Spermidine",
    //         postedBy: "by Umesh Dahiwalkar",
    //         postedOn: "Mar 26, 2024 at 5:46 pm",
    //         description: "Spermidine, a polyamine first characterized in sperm, is found in all eukaryotic cells. It plays a central role in the development of life, with bacteria, archaea, plants, and even viruses all relying on polyamines. Spermidine has been shown to have pleiotropic effects, favorably modulating all of the nine original hallmarks of aging plus the three new hallmarks, including dysregulation of macroautophagy, dysbiosis, and inflammation. ",
    //         sections: [
    //             {
    //                 heading: "A Polyamine with Notable Beneficial Activity",
    //                 sectionContent: [
    //                     {
    //                         content: "Spermidine's remarkable pleiotropic ability (i.e., its capacity to affect most, if not all, of the hallmarks of aging) is shared by its chemical cousins, collectively known as the polyamines. The additional polyamines include putrescine, its precursor, and spermine, its derivative. Polyamines are organic, aliphatic compounds consisting of two or more amino groups which are positively charged at physiological pH. This positive charge is one of the fundamental features that allow polyamines to play a significant role in many organisms, including humans, through the stabilization of negatively charged molecules such as DNA (genomic stability), RNA, nucleotide triphosphates (ATP), protein (proteostasis), and phospholipids (interacting with the negatively charged phosphate group). "

    //                     },
    //                     {

    //                         content: "Of the polyamines, spermidine is best known for its ability to induce autophagy, or the body's cellular cleaning mechanism. However, as a group, the polyamines are central to cell growth, proliferation, differentiation, and molecular stabilization, making them indispensable for healthy aging."
    //                     },
    //                     {
    //                         content: "With the onset of human clinical trials to assess spermidine supplementation for a wide variety of conditions, a need for a clinical approach emerges. This begins with a comprehensive understanding of the primary sources of polyamines: endogenous metabolism, microbial production, and food. Let's look more closely at each of these."
    //                     },
    //                 ]

    //             },

    //             {
    //                 heading: "Endogenous Metabolism: ",
    //                 content: "Are Polyamines Conditionally Essential Nutrients?It has been estimated that endogenous production is the primary source of polyamines. However, there is a significant reduction in the body's ability to produce polyamines with age. Research in animals has found that the thymus, spleen, ovary, liver, stomach, lung, kidney, heart, and muscle are the organs with the most age-induced polyamine decline. Interestingly, one small study found that super-agers (aged 90-106) had whole blood levels of spermidine and spermine that were more in line with a much younger (aged 31-56) cohort. That said, younger populations in this study had higher relative levels of putrescine when compared with the other polyamines, and we don't yet fully understand the clinical significance of the balance between polyamines during different stages of life."
    //             },
    //             {
    //                 heading: "The Polyamine Stress Response",
    //                 sectionContent: [
    //                     {
    //                         content: "Endogenous production (from the amino acids arginine and ornithine) is tightly controlled and regulated through biosynthesis (including antizyme inhibition), interconversion, degradation, and transport. Importantly, there are several related concepts with clinical implications. First, while the body can interconvert polyamines as needed, their breakdown creates toxic byproducts, including hydrogen peroxide and acrolein. Second, dysregulated endogenous metabolism is known to occur in several disease states, including the autoimmune diseases rheumatoid arthritis and lupus, some neurodegenerative disorders, and events like stroke, with elevated levels of spermidine and/or its catabolic byproducts. "

    //                     },
    //                     {

    //                         content: "The trigger of dysregulated polyamine metabolism in these conditions is yet to be fully understood, but may include infection, inflammation, and mitochondrial dysfunction. Some researchers have called this the 'Polyamine Stress Response,' suggesting that the upregulation of endogenous production is a beneficial, adaptive response that may become maladaptive with persistent stress (similar to acute vs. chronic inflammation). As endogenous polyamine metabolism also requires the use of decarboxylated SAMe as a cofactor, it is not surprising that dysregulated production may have negative downstream effects on methylation over time."
    //                     },
    //                     {
    //                         content: "While some have argued that spermidine has anti-cancer properties, active cancer may be a contraindication for polyamine supplementation as cancer cells require polyamines (similar to glucose) for growth. Human associational data has been mixed, with some studies suggesting that high dietary polyamine intake may be chemopreventive. As a result, cancer is an area where more polyamine research is needed to clarify clinical implications."
    //                     },
    //                     {
    //                         content: "Research into endogenous metabolism of polyamines is an area to follow regarding future clinical developments, as it may lead to new, early markers of disease and related treatment approaches."
    //                     },
    //                 ]
    //             },
    //             {
    //                 heading: "Microbial Production:   ",
    //                 sectionContent: [
    //                     {
    //                         content: "The second primary source of polyamines is from microbial production, making spermidine a post-biotic, or one of many important compounds produced by intestinal microbes. Bacterial production and secretion of polyamines exceeds that of our own cells. Further, according to a recent review, humans can more efficiently utilize post-biotic spermidine when compared with dietary spermidine. That said, we are far from fully understanding the delicate balance between microbial polyamine producers, polyamine degraders, and any related clinical implications. "

    //                     },
    //                     {

    //                         content: "Gut bacteria that have been found to produce spermidine include primarily lactobacillus, bifidobacterium, bacteroides, and other gram negative bacteria."
    //                     },

    //                 ]
    //             },

    //             {
    //                 heading: "Prebiotics may not be the only trigger to increase microbial polyamine production.",
    //                 sectionContent: [
    //                     {
    //                         content: "Microbial spermidine production has also been implicated in skin health, with one study finding increased levels of microbial spermidine in younger participants with improved skin elasticity and hydration. "

    //                     },
    //                     {

    //                         content: "While it’s a bit early to draw clinical conclusions from this research, it further highlights the importance of the microbiome for improved healthspan."
    //                     },

    //                 ]
    //             },
    //             {
    //                 heading: "The Role of Dietary Polyamines:",
    //                 content: "A multitude of epidemiological studies have shed light on the benefits of a diet rich in polyamines, with predominantly positive findings observed, where baseline consumption is typically lower. Enhanced dietary intake has been linked with improved cardiovascular health, increased cortical thickness and hippocampal volume, reduced risk for cognitive decline, and lower all-cause mortality in several studies. However, a recent large-scale, longitudinal study conducted in Japan did not find any associations between increased dietary polyamine intake and positive health outcomes. This higher baseline polyamine consumption in Japan may limit the benefit of additional polyamines."
    //             },
    //             {
    //                 heading: "Every whole food has a unique balance of polyamines.",
    //                 sectionContent: [
    //                     {
    //                         content: "The dietary sources with the highest concentrations of spermidine include wheat germ, soy, mushrooms, rice bran, green pepper, and broccoli. While spermidine is not found in high concentrations in muscle meat, organ meats contain relatively higher concentrations. Fermented foods, such as natto, teas (e.g., pu-erh and oolong), and some aged cheeses also contain significant amounts due to microbial polyamine production. More recently, a study on bee products as functional foods found that bee pollen, a vital food source for bee larvae and young bees, is also rich in spermidine. "

    //                     },
    //                     {

    //                         content: "Importantly, polyamine concentrations in colostrum and breast milk are the first exogenous source an infant is exposed to, and are involved in both seeding the microbiome and development of the infant immune system. Studies have found that a mother’s diet influences the polyamine content of her milk, and that obese mothers had lower levels of polyamines in breast milk compared with normal weight mothers. The clinical implications of this research are significant and can help guide postnatal nutrition recommendations. While human studies are lacking, preclinical research suggests that spermidine may play a role in fertility and inhibition of ovarian aging, making it an important area to follow with respect to preconception nutrition."
    //                     },

    //                 ]
    //             },
    //             {
    //                 heading: "Summary ",
    //                 content: "Polyamines are important players in health and longevity. In the right amounts, they can favorably alter all of the hallmarks of aging. We can get them from a healthy, whole foods diet; we synthesize them endogenously and our microbiome can produce them. Polyamines decline with age, although super-agers have polyamines akin to younger people. Supplementation with spermidine has its place, particularly with cognitive decline. A host of additional trials are coming, including in cardiovascular disease, which we will follow."
    //             },
    //         ],
    //     },
    //     {
    //         index: 8,
    //         title: "Exploring the Health Benefits of Urolithin A: A Comprehensive Scientific Analysis",
    //         postedBy: "by Umesh Dahiwalkar",
    //         postedOn: "Mar 26, 2024 at 5:46 pm",
    //         sections: [
    //             {
    //                 heading: "Introduction:",
    //                 content: "Urolithin A (UroA), derived from the gut microbiome through the metabolic transformation of ellagic acid and ellagitannins, has emerged as a fascinating subject of scientific inquiry due to its potential health benefits. Extensive research across various organisms, from worms and cells to mice and humans, has shed light on its diverse effects on health and disease management. This comprehensive scientific blog aims to delve into the intricate details of Urolithin A, exploring its role in promoting health and preventing diseases across multiple organ systems."
    //             },
    //             {
    //                 heading: "Urolithin A and Cellular Health:",
    //                 content: "At the core of its potential health benefits lies Urolithin A's ability to bolster cellular health. Through the facilitation of mitophagy, the process of selectively removing damaged mitochondria, UroA plays a pivotal role in maintaining mitochondrial function and cellular homeostasis. Furthermore, its potent anti-inflammatory properties have been shown to alleviate harmful inflammation across various tissues, contributing to overall cellular well-being."
    //             },
    //             {
    //                 heading: "Urolithin A and Cardiovascular Health:",
    //                 content: "Studies suggest that Urolithin A may hold promise in promoting cardiovascular health. By mitigating inflammation, particularly within cardiac tissues, UroA exhibits potential in delaying or preventing the onset of cardiovascular diseases. This anti-inflammatory effect underscores its significance in combating conditions such as heart disease and stroke, thus offering new avenues for cardiovascular health management."
    //             },
    //             {
    //                 heading: "Urolithin A and Neurological Health:",
    //                 content: "The neuroprotective effects of Urolithin A have garnered significant attention in scientific research. Through its ability to support nerve growth and cognitive function, UroA shows promise in combating neurodegenerative conditions like Alzheimer’s and Parkinson’s disease. By repairing damaged neurons, inhibiting apoptosis of hippocampal cells, and reducing levels of amyloid β, Urolithin A emerges as a potential therapeutic agent for preserving neurological health and cognitive function."
    //             },
    //             {
    //                 heading: "Urolithin A and Muscular Health: ",
    //                 content: "Clinical trials have provided compelling evidence of Urolithin A's ability to enhance muscular health, particularly in older adults. Supplementation with UroA has been shown to improve muscle strength and endurance, offering prospects for maintaining mobility and functionality with advancing age. These findings highlight the potential of Urolithin A in promoting musculoskeletal health and combating age-related muscle decline."
    //             },
    //             {
    //                 heading: "Urolithin A and Metabolic Health:",
    //                 content: "In the realm of metabolic health, Urolithin A has shown promise in attenuating inflammation in adipose tissue, suggesting a potential role in delaying or preventing the onset of type 2 diabetes mellitus. By targeting inflammatory pathways, UroA offers new possibilities for managing metabolic disorders and promoting metabolic well-being. "
    //             },
    //             {
    //                 heading: "Urolithin A and Renal Health:",
    //                 content: "Emerging evidence suggests that Urolithin A may also support kidney function, although further research is needed to fully elucidate its effects in this domain. By addressing renal health, UroA opens up new avenues for combating kidney-related disorders and promoting renal well-being."
    //             },
    //             {
    //                 heading: "Urolithin A and Disease Prevention:",
    //                 content: "UroA exhibits promise in the prevention of several diseases, including Alzheimer’s disease, type 2 diabetes mellitus, and non-alcoholic fatty liver disease. Through its multifaceted effects on various organ systems, Urolithin A holds potential in mitigating disease onset and progression, offering new strategies for disease prevention and health promotion. "
    //             },
    //             {
    //                 heading: "Conclusion:",
    //                 content: "Urolithin A emerges as a natural compound with significant potential in promoting health and preventing disease across multiple organ systems. Its diverse effects on cellular, cardiovascular, neurological, muscular, metabolic, and renal health underscore its importance as a subject for further investigation. While additional clinical trials are warranted to fully understand its therapeutic potential, the existing body of evidence suggests that Urolithin A could revolutionize approaches to health management and disease prevention in the future."
    //             }
    //         ],
    //     },

    // ]

    // const { index } = useParams();
    // console.log("Index from URL:", index);
    // const blog = blogs[index];

    // Convert index to a number
    // const blogIndex = parseInt(index);

    // Find the corresponding blog object
    // const blog = blogs.find(blog => blog.index === blogIndex);

    // console.log("Selected Blog:", blog);


    // const recentArticles = [

    //     {
    //         articleImg: Images.BlogArticles,
    //         postedOn: "Feb 27, 2019 at 5:47 pm",
    //     },
    //     {
    //         articleImg: Images.BlogArticles,
    //         postedOn: "Feb 27, 2019 at 5:47 pm",
    //     },
    //     {
    //         articleImg: Images.BlogArticles,
    //         postedOn: "Feb 27, 2019 at 5:47 pm",
    //     },
    //     {
    //         articleImg: Images.BlogArticles,
    //         postedOn: "Feb 27, 2019 at 5:47 pm",
    //     }

    // ]

    // const recentArticlesss = blogs.filter(item => item.index !== blogIndex).slice(0, 4);


    const customParser = (node, index) => {
        if (node.type === 'tag' && node.name === 'img') {
            // Custom rendering for images
            return <img key={index} src={node.attribs.src} alt={node.attribs.alt} />;
        } else if (node.type === 'tag' && node.name === 'li') {
            // Custom rendering for list items
            return <li key={index}>{domToReact(node.children, { index })}</li>;
        } else if (node.type === 'tag' && node.name === 'ul') {
            // Custom rendering for unordered lists with class name
            return <ul key={index} className="list-items">{domToReact(node.children, { index })}</ul>;
        }
        // You can add more custom parsing logic for other elements as needed
        return domToReact([node], { index });
    };


    // api integration 
    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    const { id } = useParams();
    // console.log("iddd", id)

    const singelBlogEndPoint = `/single_blog/${id}`;
    const singleBlogUrl = `${apiUrl}${singelBlogEndPoint}`
    // const blogCommentEndPoint = '/blog_approved_comment';
    // const blogCommentUrl = `${apiUrl}${blogCommentEndPoint}`
    const sendCommentEndPoint = '/knowledge_center';
    const sendCommentUrl = `${apiUrl}${sendCommentEndPoint}`
    const singleBlogCommentEndPoint = `/blog_comment/${id}`;
    const singleBlogCommentUrl = `${apiUrl}${singleBlogCommentEndPoint}`
    const recentArticleEndPoint = '/recent_articles';
    const recentArticleUrl = `${apiUrl}${recentArticleEndPoint}`


    //  api integration of single blog
    const fetchSingleBlog = () => {
        axios.get(singleBlogUrl)
            .then((res) => {
                // if (res.data.data === 0) {
                //     setBlogs({});
                // } else {
                //     setBlogs(res.data.data);
                // }
                // setLoading(false);

                if (res.data.status === 1) {
                    const singleBlog = res.data.data;
                    if (singleBlog.master_type === "blog") {
                        setBlogs(singleBlog);
                    } else {
                        setBlogs({});
                    }
                } else {
                    setBlogs({});
                }
                setLoading(false);
            })
            .catch((error) => {
                setError("Error fetching blog data.");
                setBlogs({});
                setLoading(false);
            })
    }

    useEffect(() => {
        fetchSingleBlog();
    }, []);


    // api integration of blog comments
    // const fetchBlogComments = () => {

    //     axios.get(blogCommentUrl)
    //         .then((res) => {
    //             if (res.data.data === 0) {
    //                 setBlogComment([]);
    //             } else {
    //                 setBlogComment(res.data.data);
    //             }
    //             setLoading(false);
    //         })
    //         .catch((error) => {
    //             setError("Error fetching blog comments.");
    //             setLoading(false);
    //         })
    // }

    // useEffect(() => {
    //     fetchBlogComments();
    // }, []);


    // handle submit of send comment 
    const handleSubmit = (event) => {
        event.preventDefault();
        if (validateForm()) {
            setSubmitting(true);

            // Prepare data to be sent
            const requestData = {
                first_name: formData.first_name,
                last_name: formData.last_name,
                mail: formData.mail,
                phone_number: formData.phone_number,
                blog_comment: formData.blog_comment,
                blog_id: id,
            };

            axios.post(sendCommentUrl, requestData)
                .then((res) => {
                    if (res.data.status === 1 && res.data.code === 200) {
                        setFormData({
                            first_name: "",
                            last_name: "",
                            mail: "",
                            phone_number: "",
                            blog_comment: "",
                        });
                        resetForm();
                        toast.success('Form submitted successfully!');

                    } else {
                        console.error("Error:", res.data.message);
                        toast.error('Form submission failed.');
                    }
                })
                .catch((error) => {
                    console.error("Error:", error);
                    toast.error('Form submission failed.');
                    setSubmitting(false);
                })

                .finally(() => {
                    setSubmitting(false);
                });
        }
    };

    const validateForm = () => {
        let formIsValid = true;
        const newErrors = {};

        // Check if any field is empty
        Object.keys(formData).forEach((key) => {
            if (!formData[key]) {
                newErrors[key] = "This field is required.";
                formIsValid = false;
            }
        });

        // Check phone number format
        const phoneRegex = /^\d{10}$/;
        if (!phoneRegex.test(formData.phone_number)) {
            newErrors.phone_number = "Please enter a valid phone number.";
            formIsValid = false;
        }

        // Check email format
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(formData.mail)) {
            newErrors.mail = "Please enter a valid email address.";
            formIsValid = false;
        }

        setErrors(newErrors);
        return formIsValid;
    };

    const resetForm = () => {
        setFormData({
            first_name: "",
            last_name: "",
            mail: "",
            phone_number: "",
            blog_comment: "",
        });
    };

    //api integration single blog comment
    const fetchSingleBlogComment = () => {

        axios.get(singleBlogCommentUrl)
            .then((res) => {
                if (res.data.data === 0) {
                    setSingleBlogComment([]);
                } else {
                    setSingleBlogComment(res.data.data);
                }
                setLoading(false);
            })
            .catch((error) => {
                setError("Error fetching recent articles data.");
                setLoading(false);
            })
    }

    useEffect(() => {
        fetchSingleBlogComment();
    }, []);

    // api integration of recent articles 
    const fetchRecentArticles = () => {

        axios.get(recentArticleUrl)
            .then((res) => {
                if (res.data.data === 0) {
                    setRecentArticles([]);
                } else {
                    setRecentArticles(res.data.data);
                }
                setLoading(false);
            })
            .catch((error) => {
                setError("Error fetching recent articles data.");
                setLoading(false);
            })
    }

    useEffect(() => {
        fetchRecentArticles();
    }, []);

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>{blogs.title}</title>
                    {blogs.meta_description && <meta name="description" content={blogs.meta_description} />}
                    {blogs.meta_keyword && <meta name="keywords" content={blogs.meta_keyword} />}
                </Helmet>
                <NavigationBar />
                <Banner bannerImg={data.image} title={data.title} content={data.content} customClassName="manufacturing-page-banner-css" />
                <div className="manufacturing-blogs-display">
                    <div className="container">
                        <div className="pxy-40">
                            <div className="row">
                                <div className="col-lg-2"></div>
                                <div className="col-lg-8 col-md-12 col-sm-12">
                                    {loading ? (
                                        <p>Loading...</p>
                                    ) : error ? (
                                        <p>{error}</p>
                                    ) : Object.keys(blogs).length > 0 ? (
                                        <div key={blogs.id}>
                                            <p className="font-size-28 text-left py-20">{blogs.name}</p>
                                            <div className="profile-timestamp">
                                                <span><img src={Images.ProfileLogo} alt="profile" className="display-inline px-2" /> {blogs.user_role_name} </span>
                                                <span><img src={Images.TimeStampLogo} alt="timestamp" className="display-inline px-2" />{moment(blogs.updated_ts).format('DD-MM-YYYY HH:mm')}</span>
                                            </div>
                                            <div className="mt-40">
                                                {blogs.short_description && <p>{blogs.short_description}
                                                </p>}
                                                {blogs.file_category === "feature" && blogs.file_path && (
                                                    <div className="my-20">
                                                        <img src={blogs.file_path} alt="" width={"100%"} />
                                                    </div>)}
                                                {/* {blogs.file_category === "thumbnail" && blogs.file_path && (
                                                <div className="my-20">
                                                    <img src={blogs.file_path} alt="Thumbnail" width={"100%"} />
                                                </div>
                                            )} */}
                                                {blogs.blog_description && <p>{parse(blogs.blog_description, { replace: customParser })}
                                                </p>}
                                            </div>
                                        </div>
                                    ) : (
                                        <p>No blog data available.</p>
                                    )}

                                    {/* //social media section */}
                                    <div className="d-flex social-media-section mt-40">
                                        <button className="btn social-media-btns">
                                            <span className="icon-bg-red">
                                                <img src={Images.FacebookImg} alt="facebook" className="display-inline" />
                                            </span>
                                            <span className="text-bg-orange">FACEBOOK</span>
                                        </button>
                                        <button className="btn social-media-btns">
                                            <span className="icon-bg-red">
                                                <img src={Images.TwitterImg} alt="twitter" className="display-inline" />
                                            </span>
                                            <span className="text-bg-orange">TWITTER</span>
                                        </button>
                                        <button className="btn social-media-btns">
                                            <span className="icon-bg-red">
                                                <img src={Images.GoogleImg} alt="google" className="display-inline" />
                                            </span>
                                            <span className="text-bg-orange">GOOGLE</span>
                                        </button>
                                    </div>

                                    {/* //comments section */}
                                    <div id="comments-section" className="mt-70">
                                        <p className="font-size-28 text-left py-20">Comments</p>
                                        {loading ? (
                                            <p>Loading...</p>
                                        ) : error ? (
                                            <p>{error}</p>
                                        ) : singleBlogComment && singleBlogComment.length > 0 ? (
                                            singleBlogComment.map((list) => (
                                                <div className="d-flex align-items-start comment-section row-25">
                                                    <div className="comment-avatar">
                                                        <img src={Images.DisplayPicture} alt="DisplayPicture" width={"100%"} height={"75px"} />
                                                    </div>
                                                    <div>
                                                        <span className="comment-header">{list.first_name}</span> <sapn className="italics">{moment(list.updated_ts).format('DD-MM-YYYY HH:mm')}</sapn>
                                                        <p className="my-20 comment-text">{list.blog_comment}</p>
                                                        <div class="comment-footer">
                                                            <Link to="#send-comment" className="d-flex align-items-center" onClick={scrollToComment}>
                                                                <div className="small-img-div">
                                                                    <img class="comment-link-reply px-2 display-inline" src={Images.Reply} width={"40px"} alt="reply" />
                                                                </div>
                                                                <span>Reply</span>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        ) : (
                                            <p>No blog comments available.</p>
                                        )}
                                    </div>

                                    {/* //sent a comment */}
                                    <div id="send-comment" className="mt-70 send-comment" ref={commentRef}>
                                        <p className="font-size-28 text-left py-20">Send a Comment</p>
                                        <form onSubmit={handleSubmit}>
                                            <div className="row">
                                                <div className="col-lg-6 col-md-12 col-sm-12">
                                                    <label>First Name</label>
                                                    <input
                                                        type="text"
                                                        name="first_name"
                                                        value={formData.first_name}
                                                        onChange={handleChange}
                                                    />
                                                    {errors.first_name && <p className="error-message">{errors.first_name}</p>}
                                                </div>
                                                <div className="col-lg-6 col-md-12 col-sm-12">
                                                    <label>Last Name</label>
                                                    <input
                                                        type="text"
                                                        name="last_name"
                                                        value={formData.last_name}
                                                        onChange={handleChange}
                                                    />
                                                    {errors.last_name && <p className="error-message">{errors.last_name}</p>}
                                                </div>
                                                <div className="col-lg-6 col-md-12 col-sm-12">
                                                    <label>E-mail</label>
                                                    <input
                                                        type="email"
                                                        name="mail"
                                                        value={formData.mail}
                                                        onChange={handleChange}
                                                    />
                                                    {errors.mail && <p className="error-message">{errors.mail}</p>}
                                                </div>
                                                <div className="col-lg-6 col-md-12 col-sm-12">
                                                    <label>Phone</label>
                                                    <input
                                                        type="tel"
                                                        name="phone_number"
                                                        value={formData.phone_number}
                                                        onChange={handleChange}
                                                    />
                                                    {errors.phone_number && <p className="error-message">{errors.phone_number}</p>}
                                                </div>
                                                <div className="col-lg-12 col-md-12 col-sm-12">
                                                    <label>Message</label>
                                                    <textarea
                                                        type="text"
                                                        name="blog_comment"
                                                        value={formData.blog_comment}
                                                        onChange={handleChange}
                                                    />
                                                    {errors.blog_comment && <p className="error-message">{errors.blog_comment}</p>}
                                                </div>
                                            </div>
                                            <div className="mt-30">
                                                <button className="btn" type="submit" disabled={submitting}>{submitting ? "Sending..." : "SEND COMMENT"}</button>
                                            </div>
                                        </form>
                                    </div>

                                    {/* //recent articles */}
                                    <div class="section-sm mt-70 recent-posts-section">
                                        <p className="font-size-28 text-left py-20">Recent Posts</p>
                                        <div class="row row-fix row-25 row-xl-50">
                                            {loading ? (
                                                <p>Loading...</p>
                                            ) : error ? (
                                                <p>{error}</p>
                                            ) : recentArticles.length > 0 ? (
                                                recentArticles.map((item, index) => (
                                                    <div class="col-lg-6 col-sm-12 col-md-12 post-light-wrap" key={index}>
                                                        <article class="post-light">
                                                            <div class="unit flex-row">
                                                                {/* <NavLink to={`/manufacturing/blogs/${item.id}`}> */}
                                                                <div class="unit-left">
                                                                    <div class="posts-container">
                                                                        <img src={item.image} alt="" width="120" height="80" />
                                                                        <div class="image-overlay"></div>
                                                                    </div>
                                                                </div>
                                                                {/* </NavLink> */}
                                                                <div class="unit-body">
                                                                    {/* <NavLink to={`/manufacturing/blogs/${item.id}`}> */}
                                                                    <span className="text-left post-titles">{item.name}</span>
                                                                    {/* </NavLink> */}
                                                                    <span><time class="post-light-time" datetime="2019">{moment(item.updated_ts).format('DD-MM-YYYY HH:mm')}</time></span>
                                                                </div>
                                                            </div>
                                                        </article>
                                                    </div>
                                                ))
                                            ) : (
                                                <p> No Recent articles available.  </p>
                                            )}

                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2"></div>
                            </div>
                        </div>
                    </div>
                </div >

                <Footer />
                <ToastContainer />
            </HelmetProvider>
        </>
    )
}
export default ManufacturingBlogs